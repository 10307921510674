<template>

  <div class="hide" style="font-size: 10px;">
    <p>id: {{ this.templateData.data.id }}</p>
  </div>

  <form 
    style="padding: 0;"
    :id="this.templateData.data.id"
    @submit="handleSubmit"
  >

  <div class="-hide">

    <DxForm 
      :id="this.templateData.data.id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >
    <DxSimpleItem 
      data-field="id"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="mastercontent"
      help-text=""
      template="mastercontent"
    >
    <DxLabel 
      :text="'mastercontent'"
      :visible="false"
      />
    </DxSimpleItem>

      <template #mastercontent>

        <div class="master-content">

        <!-- SUBMIT BUTTON  -->
        <div class="grid-x grid-margin-x align-middle title-area-content">
          <div class="auto cell">
            <h5>Kurs bearbeiten</h5>
          </div>
          <div class="shrink cell">
            <button :id="'submitform'+this.templateData.data.id" class="cx-button"><i class="fa-light fa-whistle"></i> {{currentUser.Translation.vueappCourseFormSubmit}}</button>
          </div>
        </div>
        <!-- /SUBMIT BUTTON  -->
        
          <div class="grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid">

            <!-- cell Betreff-->
            <div class="cell cell-margin-y large-3">

              <div class="dx-card no-paddings">

              <label class="ck-label hide">Betreff</label>

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>{{ currentUser.Translation.vueappTaskSubject }}</p>
                </div>
              </div>

              <ul :id="'tabsLang'+this.templateData.data.id" class="tabs hide" data-tabs>
                <li class="tabs-title" :class="currentUser.Language == 'de'  ? 'is-active' : ''"><a :href="'#panelSubject_de'+this.templateData.data.id" aria-selected="true"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/de.svg'"><span class="hide">de</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'en'  ? 'is-active' : ''"><a :href="'#panelSubject_en'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/gb.svg'"><span class="hide">en</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'fr'  ? 'is-active' : ''"><a :href="'#panelSubject_fr'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/fr.svg'"><span class="hide">fr</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'es'  ? 'is-active' : ''"><a :href="'#panelSubject_es'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/es.svg'"><span class="hide">es</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'it'  ? 'is-active' : ''"><a :href="'#panelSubject_it'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/it.svg'"><span class="hide">it</span></a></li>
              </ul>


              <div class="tabs-content" :data-tabs-content="'tabsLang'+this.templateData.data.id">

                <div class="tabs-panel" :class="currentUser.Language == 'de'  ? 'is-active' : ''" :id="'panelSubject_de'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_de'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_de"
                    :input-attr="{ 'aria-label': 'Subject_de' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'en'  ? 'is-active' : ''" :id="'panelSubject_en'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_en'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_en"
                    :input-attr="{ 'aria-label': 'Subject_en' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'fr'  ? 'is-active' : ''" :id="'panelSubject_fr'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_fr'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_fr"
                    :input-attr="{ 'aria-label': 'Subject_fr' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'es'  ? 'is-active' : ''" :id="'panelSubject_es'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_es'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_es"
                    :input-attr="{ 'aria-label': 'Subject_es' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'it'  ? 'is-active' : ''" :id="'panelSubject_it'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_it'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_it"
                    :input-attr="{ 'aria-label': 'Subject_it' }"
                  />
                </div>

                </div><!-- /tabs-content-->

                <!-- itemkey hide -->
                <div class="hide">

                <label class="ck-label hide">Itemkey</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>Itemkey</p>
                  </div>
                </div>
                <DxTextBox
                  :id="'Itemkey'+this.templateData.data.id"
                  :value="this.templateData.data.Itemkey"
                  :input-attr="{ 'aria-label': 'Itemkey' }"
                />
                </div>
                <!-- /itemkey hide-->
                
                <!-- description-->
                <label class="ck-label hide">Beschreibung</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskDescription }}</p>
                  </div>
                </div>
                <DxTextBox
                  :id="'Description'+this.templateData.data.id"
                  :value="this.templateData.data.Description"
                  :input-attr="{ 'aria-label': 'Description' }"
                />
                <!-- /description-->

              </div><!-- dx-card-->

            </div>
            <!-- /cell Betreff-->

          </div><!-- grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid -->

        </div><!-- master-form -->

      </template><!-- mastercontent -->
    
      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
        css-class="hide"
      />

    </DxForm>

  </div><!-- hide -->

  </form>

</template>
<script>

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  //DxGroupItem,
  //DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxPatternRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

//import { DxRadioGroup } from 'devextreme-vue/radio-group';


import notify from 'devextreme/ui/notify';
import DxTextBox from 'devextreme-vue/text-box';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
   if (!response.ok) {
       throw Error(response.statusText);
   }
   return response;
 }

export default {
 name: 'exerciseitem-template',

 props: {
  templateData: Object,
 },

 components: {
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxTextBox,
  //DxGroupItem,
  //DxRadioGroup,
  //DxFileUploader,
 },

 mounted() {

  //foundation
  this.tabsLang = new Foundation.Tabs($('#tabsLang'+this.templateData.data.id), {
    matchHeight: false
  })


},

 data() {
   return {
    apihost,

    currentUser,
    colCountByScreen,

    //save Button
    buttonOptions: {
      text: currentUser.Translation.vueappCourseFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },


   };

 },
 methods: {


  printTimestamp: function () {
    return Date.now();
  },

  onFilesUploaded() {

    setTimeout(() => {
      window.location.reload();
    }, 1000);

  },

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  handleSubmit(e) {
    e.preventDefault();

    const id = this.formInstance.getEditor('id').option('value'); 
    // const Status = this.formInstance.getEditor('Status').option('value');  
    // const Priority = this.formInstance.getEditor('Priority').option('value');  

    const Subject_de = document.getElementById("Subject_de"+id).getElementsByTagName("input")[0].value;
    const Subject_en = document.getElementById("Subject_en"+id).getElementsByTagName("input")[0].value;
    const Subject_fr = document.getElementById("Subject_fr"+id).getElementsByTagName("input")[0].value;
    const Subject_es = document.getElementById("Subject_es"+id).getElementsByTagName("input")[0].value;
    const Subject_it = document.getElementById("Subject_it"+id).getElementsByTagName("input")[0].value;

    const Description = document.getElementById("Description"+id).getElementsByTagName("input")[0].value;

    const Itemkey = document.getElementById("Itemkey"+id).getElementsByTagName("input")[0].value;
    
    let objString = '?id=' + id;

    if(Subject_de){
      objString = objString + '&Subject_de=' + Subject_de;
    } else {
      objString = objString + '&Subject_de=';
    }
    if(Subject_en){
      objString = objString + '&Subject_en=' + Subject_en;
    } else {
      objString = objString + '&Subject_en=';
    }
    if(Subject_fr){
      objString = objString + '&Subject_fr=' + Subject_fr;
    } else {
      objString = objString + '&Subject_fr=';
    }
    if(Subject_es){
      objString = objString + '&Subject_es=' + Subject_es;
    } else {
      objString = objString + '&Subject_es=';
    }
    if(Subject_it){
      objString = objString + '&Subject_it=' + Subject_it;
    } else {
      objString = objString + '&Subject_it=';
    }
    if(Description){
      objString = objString + '&Description=' + Description;
    } else {
      objString = objString + '&Description=';
    }
    if(Itemkey){
      objString = objString + '&Itemkey=' + Itemkey;
    } else {
      objString = objString + '&Itemkey=';
    }

    //console.log(objString);

    fetch(apihost+'/de/vue/course/getcourse/'+id+'/save'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      this.fetchUser = data;
    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappCourseFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

    //UPDATE LIST-TITLE
    var elemListTitle = document.getElementById('courseitem_'+id);
    if(elemListTitle){
      elemListTitle.innerHTML = Subject_de;
    }
    

  },    

  },

};

</script>

<style>

</style>
