<template>
<svg version="1.1" id="logo-beofit" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
y="0px" viewBox="0 0 490.6 72.7" style="enable-background:new 0 0 490.6 72.7;" xml:space="preserve">
<path class="st0" d="M106.6,56.2h20.6v2.6h-20.6c-0.7,0-1.4,0.1-2,0.3s-1.2,0.5-1.7,0.9s-0.9,0.9-1.3,1.5c-0.4,0.5-0.6,1.1-0.7,1.7
v0.3c0,0.2-0.1,0.3-0.1,0.4v0.5c0,0.7,0.1,1.4,0.4,2.1s0.7,1.4,1.3,1.9c0.5,0.5,1.1,0.9,1.8,1.2c0.8,0.3,1.6,0.5,2.4,0.5h20.7v2.5
h-20.8c-2.2,0-4.4-0.8-6.1-2.3c-0.7-0.8-1.3-1.7-1.8-2.6c-0.5-1-0.7-2.2-0.7-3.3c0-1.1,0.2-2.2,0.7-3.2s1.1-1.9,1.9-2.6
c0.8-0.7,1.7-1.3,2.7-1.7C104.3,56.5,105.5,56.2,106.6,56.2L106.6,56.2z"/>
<path class="st0" d="M143.2,72.7V56.2h2.7v16.5H143.2z"/>
<path class="st0" d="M161.9,58.8v-2.6h29.2v2.6h-13.3v13.9h-2.7V58.8H161.9z"/>
<path class="st0" d="M210.6,56.2l9.9,6.7l9.9-6.7h4.6l-13.2,8.9v7.6h-2.6v-7.6l-13.3-8.9C205.9,56.2,210.6,56.2,210.6,56.2z"/>
<path class="st0" d="M250.7,65.3V63h6.7v2.3H250.7z"/>
<path class="st0" d="M282.1,56.2h20.7v2.6h-20.7c-0.8,0-1.6,0.1-2.3,0.4c-0.7,0.3-1.3,0.8-1.9,1.3c-0.5,0.5-0.9,1.1-1.2,1.8
c-0.3,0.7-0.5,1.4-0.5,2.1c0,0.8,0.2,1.5,0.5,2.2s0.7,1.3,1.3,1.8c0.5,0.5,1.2,0.9,1.9,1.2c0.7,0.3,1.4,0.4,2.2,0.4h18v-4.2h-4.8
l-1.7-2.6h9.2v9.5h-20.7c-1.1,0-2.2-0.2-3.2-0.6c-1.1-0.4-2-1-2.8-1.8c-0.8-0.7-1.4-1.6-1.8-2.6c-0.5-1-0.7-2.1-0.7-3.2
s0.2-2.3,0.7-3.3c0.4-1,1-1.9,1.8-2.6C277.8,57.2,279.9,56.3,282.1,56.2z"/>
<path class="st0" d="M323.2,56.2l9.8,6.7l10-6.7h4.6l-13.2,8.9v7.6h-2.7v-7.6l-13.2-8.9C318.5,56.2,323.2,56.2,323.2,56.2z"/>
<path class="st0" d="M363.3,72.7V56.2l14.6,12.5l14.7-12.5v16.5h-2.7V62l-12,10.1L366,62v10.7H363.3z"/>
<linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="208.1606" y1="1036.0605" x2="137.5806" y2="1126.2505" gradientTransform="matrix(1 0 0 1 -63.3 -1161.2)">
<stop  offset="0" style="stop-color:#0BB9ED"/>
<stop  offset="0.54" style="stop-color:#63549F"/>
<stop  offset="1" style="stop-color:#C31F82"/>
</linearGradient>
<path class="st1" d="M0,0.1h53.6c2.1,0,4.1,0.5,5.9,1.5c1.7,1,3.1,2.4,4.1,4s1.6,3.4,1.7,5.3c0.1,2-0.4,3.9-1.4,5.6l-0.7,1.4
c1.6,0.8,2.9,2,4,3.4c0.9,1.4,1.5,2.9,1.8,4.5c0.2,1.5,0.2,3.1-0.2,4.6c-0.4,1.5-1.1,3-2.1,4.2c-1,1.3-2.4,2.4-3.9,3.1
c-1.7,0.8-3.5,1.3-5.4,1.2H0V16.5h53.6c0.7,0,1.4-0.1,2-0.3c1.3-0.5,2.3-1.5,2.9-2.8c0.3-0.7,0.5-1.4,0.5-2.1c0-1.4-0.5-2.7-1.5-3.6
c-1-1-2.4-1.6-3.9-1.6H0V0.1z M6.3,22.6v10.3h51.1c0.7,0,1.4-0.2,2.1-0.4c0.6-0.3,1.2-0.7,1.7-1.2s0.9-1,1.2-1.6s0.4-1.3,0.4-2
s-0.1-1.4-0.4-2c-0.3-0.6-0.7-1.2-1.2-1.6c-0.5-0.5-1-0.9-1.7-1.1c-0.7-0.3-1.4-0.4-2.1-0.4L6.3,22.6z"/>
<linearGradient id="SVGID_00000046298130064888409870000000964918248861279923_" gradientUnits="userSpaceOnUse" x1="269.763" y1="1084.2703" x2="199.1831" y2="1174.4602" gradientTransform="matrix(1 0 0 1 -63.3 -1161.2)">
<stop  offset="0" style="stop-color:#0BB9ED"/>
<stop  offset="0.54" style="stop-color:#63549F"/>
<stop  offset="1" style="stop-color:#C31F82"/>
</linearGradient>
<path style="fill:url(#SVGID_00000046298130064888409870000000964918248861279923_);" d="M165.6,0.1v6H96.4V0L165.6,0.1z
M162.3,16.5v6H96.4v-6H162.3z M96.4,32.9h69.2V39H96.4V32.9z"/>
<linearGradient id="SVGID_00000029030310221221781230000014758034259882378395_" gradientUnits="userSpaceOnUse" x1="330.6374" y1="1131.92" x2="260.0574" y2="1222.1101" gradientTransform="matrix(1 0 0 1 -63.3 -1161.2)">
<stop  offset="0" style="stop-color:#0BB9ED"/>
<stop  offset="0.54" style="stop-color:#63549F"/>
<stop  offset="1" style="stop-color:#C31F82"/>
</linearGradient>
<path style="fill:url(#SVGID_00000029030310221221781230000014758034259882378395_);" d="M205.4,0.1h47.4c1.4,0,2.9,0.3,4.2,0.8
c1.3,0.6,2.5,1.3,3.5,2.2c1,1,1.8,2.1,2.4,3.3c0.5,1.3,0.8,2.7,0.8,4.1v18.2c0,1.4-0.3,2.8-0.8,4.1c-0.6,1.2-1.4,2.3-2.4,3.3
c-1,0.9-2.2,1.6-3.5,2.2c-1.3,0.5-2.8,0.8-4.2,0.8h-47.4c-1.4,0-2.9-0.3-4.2-0.8c-1.3-0.6-2.5-1.3-3.5-2.2s-1.8-2.1-2.3-3.3
c-0.6-1.3-0.9-2.7-0.9-4.1V10.5c0-1.4,0.3-2.8,0.9-4.1c0.5-1.2,1.3-2.4,2.3-3.3s2.2-1.6,3.5-2.2C202.5,0.4,204,0.1,205.4,0.1z
M205.4,32.9h47.3c0.6,0,1.3-0.1,1.9-0.3c0.5-0.3,1-0.6,1.4-1c0.4-0.3,0.8-0.8,1-1.3c0.3-0.5,0.4-1.1,0.4-1.7V10.4
c0-0.6-0.1-1.1-0.4-1.6c-0.2-0.5-0.5-1-1-1.4c-0.4-0.4-0.9-0.7-1.4-0.9c-0.6-0.2-1.3-0.3-1.9-0.3h-47.3c-0.6,0-1.3,0.1-1.9,0.3
c-0.5,0.2-1,0.5-1.4,0.9c-0.5,0.4-0.8,0.9-1,1.4c-0.3,0.5-0.4,1-0.4,1.6v18.2c0,0.6,0.1,1.2,0.4,1.7c0.2,0.5,0.6,1,1,1.3
c0.4,0.4,0.9,0.7,1.4,1C204.1,32.8,204.8,32.9,205.4,32.9z"/>
<linearGradient id="SVGID_00000025425571074772153240000015928116389517793971_" gradientUnits="userSpaceOnUse" x1="386.2833" y1="1175.4604" x2="315.7033" y2="1265.6504" gradientTransform="matrix(1 0 0 1 -63.3 -1161.2)">
<stop  offset="0" style="stop-color:#0BB9ED"/>
<stop  offset="0.54" style="stop-color:#63549F"/>
<stop  offset="1" style="stop-color:#C31F82"/>
</linearGradient>
<path style="fill:url(#SVGID_00000025425571074772153240000015928116389517793971_);" d="M361.7,0v6.1h-69.1v-6L361.7,0z
M292.6,16.5H358v6h-59V39h-6.4V16.5z"/>
<linearGradient id="SVGID_00000059275487159453637140000007810520896237456527_" gradientUnits="userSpaceOnUse" x1="431.4055" y1="1210.7722" x2="360.8255" y2="1300.9622" gradientTransform="matrix(1 0 0 1 -63.3 -1161.2)">
<stop  offset="0" style="stop-color:#0BB9ED"/>
<stop  offset="0.54" style="stop-color:#63549F"/>
<stop  offset="1" style="stop-color:#C31F82"/>
</linearGradient>
<path style="fill:url(#SVGID_00000059275487159453637140000007810520896237456527_);" d="M388.4,39V0.1h6.4V39H388.4z"/>
<linearGradient id="SVGID_00000169527968834595836500000011500228565372481178_" gradientUnits="userSpaceOnUse" x1="463.3822" y1="1235.8038" x2="392.8021" y2="1325.9939" gradientTransform="matrix(1 0 0 1 -63.3 -1161.2)">
<stop  offset="0" style="stop-color:#0BB9ED"/>
<stop  offset="0.54" style="stop-color:#63549F"/>
<stop  offset="1" style="stop-color:#C31F82"/>
</linearGradient>
<path style="fill:url(#SVGID_00000169527968834595836500000011500228565372481178_);" d="M421.4,6.2V0.1h69.2v6.1h-31.5V39h-6.3V6.2
H421.4z"/>
</svg>
</template>

