<template>

  <!-- mainhide -->
  <div class="hide" style="font-size: 10px; padding: 10px;">

    <!-- subhide -->
    <div class="-hide" style="color: orange;">
      <p>this.templateData.data.id: {{ this.templateData.data.id }}</p>
      <p>this.templateData.data.Picthumb: {{ this.templateData.data.Picthumb }}</p>
    </div>
    <!-- /subhide -->

    <!-- subhide -->
    <div class="-hide" style="color: green;">
      <p>this.Picthumb: {{ this.Picthumb }}</p>
    </div>
    <!-- /subhide -->

  </div>
  <!-- /mainhide -->

  <form 
    style="padding: 0;"
    :id="this.templateData.data.id"
    @submit="handleSubmit"
  >

  <div class="-hide">

    <DxForm 
      :id="this.templateData.data.id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >
    <DxSimpleItem 
      data-field="id"
      help-text=""
      css-class="hide"
    >
    </DxSimpleItem>

    <DxSimpleItem 
      data-field="mastercontent"
      help-text=""
      template="mastercontent"
    >
    <DxLabel 
      :text="'mastercontent'"
      :visible="false"
      />
    </DxSimpleItem>

      <template #mastercontent>

        <div class="master-content">

        <!-- SUBMIT BUTTON  -->
        <div class="grid-x grid-margin-x align-middle title-area-content">
          <div class="auto cell">
            <h5>Artikel bearbeiten</h5>
          </div>
          <div class="shrink cell">
            <button :id="'submitform'+this.templateData.data.id" class="cx-button"><i class="fa-light fa-tag"></i> {{currentUser.Translation.vueappShopFormSubmit}}</button>
          </div>
        </div>
        <!-- /SUBMIT BUTTON  -->

        <div class="grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid">

            <!-- cell Betreff-->
            <div class="cell cell-margin-y large-3">

              <div class="dx-card no-paddings">

              <label class="ck-label hide">Betreff</label>

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>{{ currentUser.Translation.vueappTaskSubject }}</p>
                </div>
              </div>

              <ul :id="'tabsLang'+this.templateData.data.id" class="tabs hide" data-tabs>
                <li class="tabs-title" :class="currentUser.Language == 'de'  ? 'is-active' : ''"><a :href="'#panelSubject_de'+this.templateData.data.id" aria-selected="true"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/de.svg'"><span class="hide">de</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'en'  ? 'is-active' : ''"><a :href="'#panelSubject_en'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/gb.svg'"><span class="hide">en</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'fr'  ? 'is-active' : ''"><a :href="'#panelSubject_fr'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/fr.svg'"><span class="hide">fr</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'es'  ? 'is-active' : ''"><a :href="'#panelSubject_es'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/es.svg'"><span class="hide">es</span></a></li>
                <li class="tabs-title" :class="currentUser.Language == 'it'  ? 'is-active' : ''"><a :href="'#panelSubject_it'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/it.svg'"><span class="hide">it</span></a></li>
              </ul>


              <div class="tabs-content" :data-tabs-content="'tabsLang'+this.templateData.data.id">

                <div class="tabs-panel" :class="currentUser.Language == 'de'  ? 'is-active' : ''" :id="'panelSubject_de'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_de'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_de"
                    :input-attr="{ 'aria-label': 'Subject_de' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'en'  ? 'is-active' : ''" :id="'panelSubject_en'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_en'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_en"
                    :input-attr="{ 'aria-label': 'Subject_en' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'fr'  ? 'is-active' : ''" :id="'panelSubject_fr'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_fr'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_fr"
                    :input-attr="{ 'aria-label': 'Subject_fr' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'es'  ? 'is-active' : ''" :id="'panelSubject_es'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_es'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_es"
                    :input-attr="{ 'aria-label': 'Subject_es' }"
                  />
                </div>
                <div class="tabs-panel" :class="currentUser.Language == 'it'  ? 'is-active' : ''" :id="'panelSubject_it'+this.templateData.data.id">
                  <DxTextBox
                    :id="'Subject_it'+this.templateData.data.id"
                    :value="this.templateData.data.Subject_it"
                    :input-attr="{ 'aria-label': 'Subject_it' }"
                  />
                </div>

                </div><!-- /tabs-content-->

                <!-- description-->
                <label class="ck-label hide">Beschreibung</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskDescription }}</p>
                  </div>
                </div>

                <!-- <DxTextBox
                  :id="'Description'+this.templateData.data.id"
                  :value="this.templateData.data.Description"
                  :input-attr="{ 'aria-label': 'Description' }"
                /> -->

                <DxTextArea
                  :height="225"
                  :id="'Description'+this.templateData.data.id"
                  :value="this.templateData.data.Description"
                  :input-attr="{ 'aria-label': 'Description' }"
                />
                
                <!-- /description-->

              </div><!-- dx-card-->

            </div>
            <!-- /cell Betreff-->

            <!-- cell Data-->

            <div class="cell cell-margin-y large-3">

              <div class="dx-card no-paddings">

              <label class="ck-label hide">Data</label>

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>Preis CHF</p>
                </div>
              </div>

              <DxNumberBox
                :id="'Price'+this.templateData.data.id"
                :value="this.templateData.data.Price"
                :input-attr="{ 'aria-label': 'Price' }"
              />

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>Artikelnummer</p>
                </div>
              </div>

              <DxTextBox
                :id="'Sku'+this.templateData.data.id"
                :value="this.templateData.data.Sku"
                :input-attr="{ 'aria-label': 'Sku' }"
              />

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>EAN</p>
                </div>
              </div>

              <DxNumberBox
                :id="'Barcode'+this.templateData.data.id"
                :value="this.templateData.data.Barcode"
                :input-attr="{ 'aria-label': 'Barcode' }"
              />

              <div :class="{ hide: !this.templateData.data.Barcode }">

                <Vue3Barcode 
                  :value="this.templateData.data.Barcode" 
                  :format="'ean13'" 
                  :height="50" 
                />

              </div>

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>MwSt Satz</p>
                </div>
              </div>

              <DxSelectBox
                  :id="'Vat'+this.templateData.data.id"
                  v-model:data-source="vatList"
                  :input-attr="{ 'aria-label': 'vatlist' }"
                  :value="this.templateData.data.Vat"
                  value-expr="id"
                  display-expr="display"
              />
  
              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>Gruppe</p>
                </div>
              </div>

              <DxSelectBox
                  :id="'Gruppe'+this.templateData.data.id"
                  :data-source="groups"
                  :value="this.templateData.data.groupId"
                  value-expr="id"
                  display-expr="name"
              />

              <div class="dx-card-label grid-x align-middle">
                <div class="auto cell">
                  <p>Verwendung</p>
                </div>
              </div>

              <div class="_checkbox-wrap" style="padding: 5px 12px;">

                <div class="checkbox-container">
                  <input 
                  :name="'checkboxFrontdesk'+this.templateData.data.id"
                  :id="'checkboxFrontdesk'+this.templateData.data.id"
                  type="checkbox" 
                  :value="'Frontdesk'"
                  v-model="isCheckedFrontdesk"
                  >
                  <label :for="'checkboxFrontdesk'+this.templateData.data.id">Frontdesk</label>
                  <span class="checkmark"></span>
                </div>
                
                <div class="checkbox-container">
                  <input 
                  :name="'checkboxWebshop'+this.templateData.data.id"
                  :id="'checkboxWebshop'+this.templateData.data.id"
                  type="checkbox" 
                  :value="'Webshop'"
                  v-model="isCheckedWebshop"
                  >
                  <label :for="'checkboxWebshop'+this.templateData.data.id">Webshop</label>
                  <span class="checkmark"></span>
                </div>

              </div><!-- checkbox-wrap-->
              </div><!-- dx-card-->

            </div>
            <!-- /cell Data-->

            <!-- cell Image-->
            <div class="cell cell-margin-y large-3">
              <div class="dx-card no-paddings">

                <label class="ck-label hide">Bild</label>

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>Bild</p>
                  </div>
                </div>

                <ul :id="'tabsImage'+this.templateData.data.id" class="tabs hide" data-tabs>
                  <li class="tabs-title -is-active"><a :href="'#panelImage_1'+this.templateData.data.id" aria-selected="true"><i class="tabnav-icon no-margin fa-light fa-image"></i><span class="hide">Image</span></a></li>
                  <li class="tabs-title is-active"><a :href="'#panelImage_2'+this.templateData.data.id"><i class="tabnav-icon no-margin fa-light fa-cloud-arrow-up"></i><span class="hide">Upload</span></a></li>
                </ul>

                <div class="tabs-content" :data-tabs-content="'tabsImage'+this.templateData.data.id">
                  <div class="tabs-panel -is-active" :id="'panelImage_1'+this.templateData.data.id">
                    <div class="padding-wrap">
                      <img :src="this.Picthumb" alt="" />
                    </div><!-- /padding-wrap -->

                  </div>                  
                  <div class="tabs-panel is-active" :id="'panelImage_2'+this.templateData.data.id">

                    <div class="padding-wrap">

                      <!-- upload Picture -->
                      <div v-if="this.Picthumb">
                        <img :src="this.Picthumb" :alt="this.templateData.data.Subject" />
                      </div>
                      <div v-else style="font-size: 12px;">
                        <span style="color: #ec5f67;">
                          <i class="fa-light fa-circle-exclamation"></i>
                          Bild jpg, png (5 MB)
                        </span>
                      </div>
                      
                      <DxFileUploader
                        id="upfile"
                        name="upfile"
                        class="uploadbutton"
                        :upload-url="apihost+'/uploadshop.php?id=' + this.templateData.data.id"
                        :accept="accept"
                        :multiple="false"
                        :upload-mode="uploadMode"
                        :allowed-file-extensions="['.jpg', '.jpeg', '.png']"
                        :min-file-size="1024"
                        :max-file-size="10240*1024"
                        :on-files-uploaded="onFilesUploaded"
                        label-text=""
                        :select-button-text="currentUser.Translation.vueappShopUploadPicButton"
                        @value-changed="e => files = e.value"
                      />

                      <!-- /upload Picture -->

                    </div><!-- /padding-wrap -->

                  </div>                  
                  <!-- /tabs-panel -->
                </div>                  
                <!-- /tabs-content -->

              </div><!-- dx-card-->

            </div>
            <!-- /cell Image-->

            <!-- cell Itemkey hide-->
            <div class="cell cell-margin-y hide">
              <div class="dx-card no-paddings">
              
              <label class="ck-label">Itemkey</label>
              <div class="ck-input-container">
                <input type="text" class="dx-texteditor-input ck-input" autocomplete="off" spellcheck="false" name="Itemkey" :id="'Itemkey'+this.templateData.data.id" :value="this.templateData.data.Itemkey" >
              </div>

              </div><!-- dx-card-->

            </div>
            <!-- /cell Itemkey hide-->

          </div><!-- grid-x grid-margin-x medium-up-2 xlarge-up-2 xxlarge-up-3 master-grid -->

        </div><!-- master-form -->

      </template><!-- mastercontent -->
    
      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
        css-class="hide"
      />

    </DxForm>

  </div><!-- hide -->

  </form>

</template>
<script>

import { DxFileUploader } from 'devextreme-vue/file-uploader';

import DxTextArea from 'devextreme-vue/text-area';
import DxNumberBox from 'devextreme-vue/number-box';
import ArrayStore from 'devextreme/data/array_store';

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  //DxGroupItem,
  //DxRequiredRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxPatternRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

//import { DxRadioGroup } from 'devextreme-vue/radio-group';


import { ref } from 'vue';
import notify from 'devextreme/ui/notify';
import DxTextBox from 'devextreme-vue/text-box';
import DxSelectBox from 'devextreme-vue/select-box';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import Vue3Barcode from 'vue3-barcode'

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;

let Picthumb;

let vatList = ref(undefined);

// Initial state checkboxes
let isCheckedFrontdesk = false;  
let isCheckedWebshop = false;
let isCheckedSubscription = false;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
   if (!response.ok) {
       throw Error(response.statusText);
   }
   return response;
 }

export default {
 name: 'exerciseitem-template',

 props: {
  templateData: Object,
  reload: Function,
  groups: Object,
 },

 components: {
  DxFileUploader,
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxTextBox,
  DxTextArea,
  DxNumberBox,
  DxSelectBox,
  //DxGroupItem,
  //DxRadioGroup,
  //DxFileUploader,

  Vue3Barcode,

 },

 async mounted() {

  this.Picthumb = this.templateData.data.Picthumb;

  //foundation
  this.tabsLang = new Foundation.Tabs($('#tabsLang'+this.templateData.data.id), {
    matchHeight: false
  })
  this.tabsImage = new Foundation.Tabs($('#tabsImage'+this.templateData.data.id), {
    matchHeight: false
  })

  const response = await fetch(apihost + "/vue/vat/list/");
  const vatListObject = await response.json();
  vatListObject.forEach((vat) => vat.display = `${vat.name} - ${vat.valueTax}% - ${vat.nameDetail}`);
  vatList.value = new ArrayStore({
      data: vatListObject,
      key: 'id',
  });

  this.isCheckedFrontdesk = this.templateData.data.isFrontdesk;
  this.isCheckedWebshop = this.templateData.data.isWebshop;


},

 data() {
   return {
    apihost,

    currentUser,
    colCountByScreen,

    Picthumb,

    //save Button
    buttonOptions: {
      text: currentUser.Translation.vueappShopFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },

    //uploader
    formRefName: 'tree-view',
    multiple: false,
    accept: '*',
    uploadMode: 'instantly',
    fileTypesSource: [
      { name: 'All types', value: '*' },
      { name: 'Images', value: 'image/*' },
      { name: 'Images', value: 'image/*' },
    ],
    files: [],
    //end uploader    
    vatList,
    isCheckedFrontdesk,
    isCheckedWebshop,
    isCheckedSubscription,
   };

 },
 methods: {

  printTimestamp: function () {
    return Date.now();
  },

  onFilesUploaded() {

    const id = this.formInstance.getEditor('id').option('value'); 

    let objString = '?id=' + id;
    //console.log(objString);

    fetch(apihost+'/de/vue/shop/getshop/'+id+'/view'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);

      //update Frontend
      this.Picthumb = data.Picthumb;

    })
    .catch(() => { throw 'Network error' });
  
    notify({
      message: currentUser.Translation.vueappShopFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);
    

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);

  },

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  handleSubmit(e) {
    e.preventDefault();

    let updateprod = {};
    const id = this.formInstance.getEditor('id').option('value'); 
    updateprod.id = id;
    updateprod.Price = document.getElementById("Price"+id).getElementsByTagName("input")[0].value;
    updateprod.Sku = document.getElementById("Sku"+id).getElementsByTagName("input")[0].value;
    updateprod.Barcode = document.getElementById("Barcode"+id).getElementsByTagName("input")[0].value;

    updateprod.Subject_de = document.getElementById("Subject_de"+id).getElementsByTagName("input")[0].value;
    updateprod.Subject_en = document.getElementById("Subject_en"+id).getElementsByTagName("input")[0].value;
    updateprod.Subject_fr = document.getElementById("Subject_fr"+id).getElementsByTagName("input")[0].value;
    updateprod.Subject_es = document.getElementById("Subject_es"+id).getElementsByTagName("input")[0].value;
    updateprod.Subject_it = document.getElementById("Subject_it"+id).getElementsByTagName("input")[0].value;

    updateprod.Description = document.getElementById("Description"+id).getElementsByTagName("textarea")[0].value;

    updateprod.Vat = document.getElementById("Vat"+id).getElementsByTagName("input")[0].value;
    updateprod.groupId = document.getElementById("Gruppe"+id).getElementsByTagName("input")[0].value;
    const checkboxfrontdesk = document.getElementById("checkboxFrontdesk"+id);
    const checkboxwebshop = document.getElementById("checkboxWebshop"+id);
    
    let objString = '?id=' + id;

    if(updateprod.Price){
      objString = objString + '&Price=' + updateprod.Price;
    } else {
      objString = objString + '&Price=';
    }
    if(updateprod.Sku){
      objString = objString + '&Sku=' + updateprod.Sku;
    } else {
      objString = objString + '&Sku=';
    }
    if(updateprod.Barcode){
      objString = objString + '&Barcode=' + updateprod.Barcode;
    } else {
      objString = objString + '&Barcode=';
    }
    if(updateprod.Subject_de){
      objString = objString + '&Subject_de=' + updateprod.Subject_de;
    } else {
      objString = objString + '&Subject_de=';
    }
    if(updateprod.Subject_en){
      objString = objString + '&Subject_en=' + updateprod.Subject_en;
    } else {
      objString = objString + '&Subject_en=';
    }
    if(updateprod.Subject_fr){
      objString = objString + '&Subject_fr=' + updateprod.Subject_fr;
    } else {
      objString = objString + '&Subject_fr=';
    }
    if(updateprod.Subject_es){
      objString = objString + '&Subject_es=' + updateprod.Subject_es;
    } else {
      objString = objString + '&Subject_es=';
    }
    if(updateprod.Subject_it){
      objString = objString + '&Subject_it=' + updateprod.Subject_it;
    } else {
      objString = objString + '&Subject_it=';
    }
    if(updateprod.Description){
      objString = objString + '&Description=' + updateprod.Description;
    } else {
      objString = objString + '&Description=';
    }
    if(updateprod.Vat !== 0){
      objString = objString + `&Vat=${updateprod.Vat}`;
    }
    if(updateprod.groupId){
      objString += `&groupId=${updateprod.groupId}`;
    }
    if (checkboxfrontdesk.checked) {
      objString = objString + '&Isfrontdesk=true';
      updateprod.Isfrontdesk = true;
    } else {
      objString = objString + '&Isfrontdesk=false';
      updateprod.Isfrontdesk = false;
    }

    if (checkboxwebshop.checked) {
      objString = objString + '&Iswebshop=true';
      updateprod.Iswebshop = true;
    } else {
      objString = objString + '&Iswebshop=false';
      updateprod.Iswebshop = false;
    }

    // fetch(apihost+'/de/vue/shop/getshop/'+id+'/save'+objString)
    // .then(handleErrors)
    // .then(response => response.text())
    // .then(result => {
    //   const data = JSON.parse(result);
    // })
    // .catch((e) => { throw e });
  
    this.$emit('group-change', updateprod); // pass the new data element to the parent for updating.

    notify({
      message: currentUser.Translation.vueappShopFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

    //UPDATE LIST-TITLE
    var elemListTitle = document.getElementById('shopitemsubject_'+id);
    if(elemListTitle){
      elemListTitle.innerHTML = updateprod.Subject_de;
    }

    

  },    

  },

};

</script>

<style>

</style>
