<template>
<svg version="1.1" id="logo-gymperformance" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 980 166" style="enable-background:new 0 0 980 166;" xml:space="preserve">
<path class="st0" d="M646.5,103.9c-1.9,2.1-3.8,3.9-5.5,5.9c-4.8,5.2-9.6,10.4-14.4,15.6c-1,1.1-1,1.1-1.5-0.2
c-2.6-6.7-5.1-13.4-7.7-20.2c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.2-0.5-0.5-0.5c-0.3,0-0.3,0.3-0.4,0.6
c-1.8,6.9-3.7,13.7-5.5,20.6c-0.8,3-1.6,6-2.3,9.1c-0.2,1-0.7,1.3-1.7,1.3c-6.2,0-12.4,0-18.6,0c-1,0-1.4-0.1-1-1.3
c1.9-6.7,3.6-13.4,5.4-20.1c3.2-11.9,6.4-23.8,9.5-35.7c0.7-2.5,1.4-5,2-7.5c0.2-0.6,0.4-0.7,1-0.7c6.6,0,13.2,0,19.9,0
c0.6,0,0.8,0.2,1,0.8c2.3,6.9,4.7,13.9,7,20.8c0.4,1.2,0.8,1.3,1.7,0.3c3.8-4.3,7.5-8.6,11.2-12.9c2.4-2.8,4.9-5.6,7.3-8.4
c0.5-0.6,1.2-0.6,1.8-0.6c7.1,0,14.2,0,21.2,0c1.1,0,1.2,0.4,0.9,1.3c-2.4,8.7-4.7,17.4-7,26.1c-3.2,11.8-6.3,23.6-9.5,35.4
c-0.1,0.2-0.1,0.5-0.2,0.7c-0.1,1.3-0.8,1.7-2.1,1.7c-6.3-0.1-12.6,0-18.9,0c-0.9,0-1.1-0.2-0.8-1.2c2.6-9.6,5.1-19.3,7.7-28.9
C646.4,105,646.6,104.6,646.5,103.9z"/>
<path class="st0" d="M792.4,101.2c1.2-2.7,1.7-5.4,2.3-8c1.9-7.3,3.9-14.6,5.9-21.9c0.2-0.9,0.6-1.1,1.5-1.1c6.3,0,12.7,0,19,0
c1.1,0,1.3,0.3,1,1.3c-1.4,4.9-2.7,9.9-4,14.8c-4.2,15.8-8.5,31.6-12.6,47.5c-0.3,1.3-0.8,1.8-2.2,1.7c-5.5-0.1-10.9-0.1-16.4,0
c-0.9,0-1.4-0.3-1.8-1.1c-4.7-9.9-9.4-19.8-14.1-29.6c-0.2-0.4-0.3-0.9-0.9-1.2c-0.3,1.2-0.7,2.3-1,3.4c-2.5,9.1-5,18.3-7.4,27.5
c-0.2,0.8-0.5,1.1-1.3,1.1c-6.3,0-12.6,0-18.9,0c-1.6,0-1.6,0-1.2-1.5c2.6-9.9,5.3-19.8,7.9-29.7c2.9-11,5.9-21.9,8.8-32.9
c0.2-0.9,0.5-1.2,1.6-1.2c5.9,0.1,11.7,0.1,17.6,0c1.1,0,1.6,0.4,2.1,1.3c4.5,9.5,9,19,13.5,28.5
C791.9,100.4,792.1,100.7,792.4,101.2z"/>
<path class="st0" d="M195.8,122.6c1.1-2.7,2.3-5.4,3.4-8c2.8-6.8,5.7-13.6,8.2-20.5c1.5-4.2,2.4-8.6,3.5-12.9
c1.6-5.9,3.2-11.7,4.8-17.6c0.1-0.3,0.3-0.7-0.1-0.9c-0.4-0.2-0.6,0.2-0.8,0.5c-3.4,3.6-6.7,7.3-10.1,10.9c-3.1,3.3-6.2,6.7-9.2,10
c-0.6,0.7-0.9,0.8-1.3-0.3c-2.3-6.3-4.7-12.5-7.1-18.8c-0.2-0.6-0.4-1.3-0.6-1.9c-0.1-0.3-0.1-0.7-0.4-0.7c-0.4,0-0.5,0.4-0.6,0.7
c-0.2,0.7-0.5,1.5-0.7,2.3c-2.4,9.2-4.9,18.5-7.3,27.8c-0.2,0.7-0.5,0.9-1.2,0.9c-6.5,0-12.9,0-19.4,0c-0.9,0-1-0.2-0.8-1.1
c5-18.5,9.9-37,14.9-55.4c0.7-2.7,1.6-5.2,2.2-7.9c0.2-0.9,0.8-0.8,1.3-0.8c4.2,0,8.4-0.1,12.6-0.1c2.2,0,4.4,0,6.6,0
c0.9,0,1.3,0.3,1.6,1.3c0.9,3.1,2,6.1,3.1,9.1c1.2,3.5,2.4,7.1,3.6,10.6c0.5,1.6,0.8,1.6,1.9,0.4c3.7-4.2,7.5-8.5,11.2-12.8
c2.3-2.7,4.7-5.3,7-8c0.4-0.5,0.9-0.7,1.5-0.7c7.2,0,14.4-0.1,21.6,0.1c0.9,0,0.9,0.3,0.5,0.9c-6.5,12-12.8,24.1-19.3,36.1
c-8.7,16.1-17.2,32.4-26.1,48.5c-1.3,2.4-2.6,5-3.8,7.4C196.4,122.2,196.3,122.5,195.8,122.6z"/>
<path class="st0" d="M352.3,70.3c5.9-0.1,13.2-0.1,20.5,0.8c5.5,0.7,10.9,2.2,14.9,6.5c4.2,4.5,5.5,10,4.9,15.9
c-1.1,9.6-6.5,15.7-15.2,19.3c-0.9,0.4-2.3,0.4-2.6,1.2c-0.3,0.6,0.6,1.7,0.9,2.5c2.5,5.9,4.9,11.8,7.4,17.7
c0.4,0.9,0.3,1.2-0.8,1.2c-7,0-13.9,0-20.9,0c-1,0-1.4-0.3-1.8-1.3c-2-5.5-4.2-10.9-6.3-16.3c-0.3-0.8-0.8-1.2-1.7-1.1
c-1,0.1-2,0-3,0c-0.9,0-1.3,0.2-1.6,1.2c-1.4,5.5-3,10.9-4.4,16.4c-0.2,0.8-0.5,1.1-1.3,1.1c-6.5,0-12.9,0-19.4,0
c-1.2,0-1.3-0.2-1-1.4c1.7-6,3.2-12.1,4.8-18.2c3.6-13.5,7.3-27.1,11-40.6c0.4-1.4,0.8-2.8,1.1-4.2c0.2-0.7,0.5-0.9,1.2-0.9
C343.1,70.3,347,70.3,352.3,70.3z M357.1,101.3c1.3,0,2.6,0,3.9,0c2.6-0.1,5-0.8,7.1-2.4c1.9-1.5,2.9-3.5,2.3-6
c-0.6-2.5-2.5-3.5-4.8-3.8c-3.2-0.5-6.5-0.1-9.7-0.2c-0.5,0-0.8,0.2-1,0.7c-0.9,3.6-1.9,7.2-2.9,10.8c-0.2,0.9,0.1,1,0.8,1
C354.2,101.2,355.6,101.3,357.1,101.3z"/>
<path class="st0" d="M552.1,70.3c5-0.1,11.5,0,18,0.5c3.9,0.3,7.7,1.2,11.3,2.8c8.2,3.6,11.3,10.7,10.9,18.6
c-0.5,10.4-6.1,17.1-15.6,20.8c-2.4,1-2.5,0.9-1.5,3.3c2.5,6,4.9,11.9,7.4,17.9c0.5,1.3,0.5,1.3-0.8,1.3c-6.7,0-13.5,0-20.2,0.1
c-1.3,0-1.9-0.5-2.4-1.6c-2-5.3-4.1-10.5-6.1-15.8c-0.3-0.9-0.8-1.2-1.7-1.2c-1.4,0.1-3.1-0.4-4,0.2c-1,0.6-0.9,2.5-1.2,3.8
c-1.2,4.5-2.4,9.1-3.6,13.6c-0.2,0.7-0.5,0.9-1.2,0.9c-6.6,0-13.2,0-19.7,0c-1.2,0-0.9-0.6-0.7-1.3c2.3-8.6,4.6-17.3,7-25.9
c3.3-12.4,6.7-24.8,10-37.2c0.2-0.7,0.5-0.9,1.2-0.9C542.9,70.3,546.7,70.3,552.1,70.3z M556.7,101.3c1.4,0,2.7,0,4.1,0
c2.4-0.1,4.7-0.8,6.6-2.2c2.2-1.5,3.2-3.9,2.7-6.1c-0.6-2.6-2.6-3.6-4.9-3.9c-3.3-0.5-6.6-0.2-9.8-0.2c-0.4,0-0.7,0.2-0.8,0.6
c-1,3.7-2,7.5-3,11.2c-0.2,0.6,0,0.7,0.6,0.7C553.6,101.2,555.2,101.3,556.7,101.3z"/>
<path class="st0" d="M907.6,135.5c-8.8,0-17.7,0-26.5,0c-1.5,0-1.5,0-1.1-1.5c4.3-16.3,8.7-32.5,13-48.8c1.2-4.6,2.5-9.2,3.8-13.8
c0.2-0.8,0.5-1.1,1.3-1.1c17.7,0,35.5,0,53.2,0c0.7,0,1.2-0.1,0.9,1c-1.5,5.4-3,10.9-4.5,16.4c-0.2,0.8-0.7,0.7-1.2,0.7
c-9,0-18,0-27,0c-1.7,0-3.4,0-5.1,0c-0.7,0-1.1,0.3-1.3,1c-0.3,1.4-0.7,2.7-1.1,4.1c-0.4,1.3-0.4,1.4,1.1,1.4c2,0,3.9,0,5.9,0
c7.7,0,15.4,0,23.1,0c1.4,0,1.4,0.1,1.1,1.4c-1.2,4.5-2.5,9.1-3.7,13.6c-0.2,0.8-0.7,0.9-1.4,0.9c-9.4,0-18.7,0-28.1,0
c-2.6,0-2.6,0-3.2,2.4c-0.3,1-0.6,2.1-0.9,3.1c-0.4,1-0.1,1.4,1,1.4c3-0.1,5.9-0.1,8.9,0c7.7,0.1,15.4-0.1,23.1,0.1
c1,0,1.2,0.2,0.9,1.2c-1.4,5.1-2.8,10.3-4.1,15.4c-0.2,1-0.6,1.2-1.6,1.2C925.4,135.5,916.5,135.5,907.6,135.5
C907.6,135.5,907.6,135.5,907.6,135.5z"/>
<path class="st0" d="M286.3,135.5c-8.9,0-17.8,0-26.7,0c-0.9,0-1.2-0.2-0.9-1.1c2.9-10.8,5.7-21.6,8.6-32.4
c2.6-9.6,5.2-19.2,7.8-28.8c0.8-2.9,0.7-2.9,3.7-2.9c17,0,34,0,51,0c1.6,0,1.6,0,1.2,1.5c-1.4,5.2-2.8,10.4-4.2,15.5
c-0.2,0.8-0.6,1-1.4,1c-10.3,0-20.7,0-31,0c-2.1,0-2.1,0-2.6,2.1c-0.3,1.1-0.6,2.3-1,3.3c-0.3,0.9-0.1,1.1,0.8,1.1c2.5,0,5,0,7.5,0
c7.3,0,14.6,0,21.9,0c1.2,0,1.4,0.3,1.1,1.4c-1.3,4.4-2.5,8.8-3.5,13.3c-0.2,0.9-0.7,1.2-1.5,1.2c-2.4,0-4.8,0-7.2,0
c-7.4,0-14.7,0-22.1,0c-1,0-1.6,0.1-1.7,1.3c-0.2,1.4-0.7,2.8-1.1,4.2c-0.3,1.1-0.1,1.4,1,1.4c2.5-0.1,4.9,0,7.4,0
c8.2,0.1,16.3-0.1,24.5,0.1c1.1,0,1.3,0.3,1,1.4c-1.4,5.1-2.8,10.2-4.1,15.3c-0.2,0.9-0.6,1.2-1.5,1.2
C304.1,135.5,295.2,135.5,286.3,135.5z"/>
<path class="st0" d="M487.3,136.9c-10.1-0.1-17.6-2.4-24-7.9c-8-6.9-10.8-15.9-9.5-26.2c1.5-11.8,7.4-21.2,17.3-27.5
c12.9-8.2,26.5-8.9,39.7-0.8c10,6.2,14.2,15.8,13,27.4c-1.5,14.3-9,24.6-21.7,31.1C497.8,135.3,491.6,137.1,487.3,136.9z
M487.1,117.5c0.6,0,1.2,0,1.9,0c0.2,0,0.5-0.1,0.7-0.1c5.7-1.4,9.2-5.1,11.3-10.3c1.4-3.6,1.8-7.4,0.4-11.1c-3.3-9-14-9.2-19.2-5
c-4.2,3.4-6.5,7.8-6.9,13.2C474.7,112.2,479.4,117.5,487.1,117.5z"/>
<path class="st0" d="M72.2,27.6c7.5,0,14.6,1.8,21.1,5.6c2.2,1.3,4.1,2.9,5.9,4.7c0.4,0.4,0.4,0.7,0,1.1
c-4.1,4.4-8.4,8.6-12.5,12.9c-0.6,0.6-0.9,0.1-1.2-0.2c-2.6-2.2-5.5-3.6-8.8-4.3c-8-1.8-17-0.4-22.1,7.2c-2.8,4.2-3.8,9-2.8,14
c1,5,4.4,7.7,9.3,8.7c2.5,0.5,5.1,0.3,7.6-0.3c0.6-0.1,0.9-0.4,1.1-1c0.5-1.8,1.1-3.6,1.7-5.3c0.2-0.7-0.1-0.8-0.6-0.8
c-1.5,0-3-0.1-4.5-0.1c-1.4,0-2.8,0-4.2,0c-0.7,0-0.9-0.2-0.7-1c1-4,2.1-8,3-12c0.2-0.9,0.6-1.1,1.5-1.1c9.7,0.1,19.4-0.1,29.1,0.1
c0.8,0,1,0.1,0.7,1c-2.8,9.9-5.6,19.8-8.3,29.8c-0.5,1.7-1.3,2.8-2.9,3.6c-7.8,3.9-16.1,5.8-24.8,5.5c-7.4-0.3-14.4-2.3-20.3-7
c-6.8-5.5-9.8-12.8-9.8-21.4c0-14.2,6.1-25.2,18-32.8c6.2-4,13.1-6,20.5-6.5C69.5,27.7,70.8,27.6,72.2,27.6z"/>
<path class="st0" d="M726.7,135.5c-3.4,0-6.8,0-10.2,0c-1,0-1.3-0.3-1.4-1.2c-0.2-2.1-0.6-4.1-0.8-6.2c-0.1-1.1-0.6-1.3-1.5-1.3
c-4,0-7.9,0-11.9,0c-2.8,0-5.7,0-8.5-0.1c-0.8,0-1.3,0.2-1.7,0.9c-1.5,2.4-3,4.7-4.5,7c-0.4,0.6-0.9,0.8-1.6,0.8
c-7.1,0-14.2,0-21.4,0c-0.3,0-0.7,0.1-0.9-0.2c-0.2-0.3,0.1-0.4,0.3-0.6c5.4-7.8,10.7-15.6,16.1-23.3c8.8-12.7,17.5-25.5,26.2-38.2
c0.5-0.8,1.1-1.6,1.6-2.4c0.4-0.6,0.9-0.7,1.5-0.7c6.6,0,13.2,0,19.7,0c0.6,0,0.8,0.2,0.9,0.9c1.3,9.3,2.9,18.6,4.2,27.9
c1,7.3,2.2,14.5,3.3,21.7c0.7,4.6,1.4,9.3,2.1,14c0.1,0.9-0.2,1.1-1,1.1C733.7,135.5,730.2,135.5,726.7,135.5
C726.7,135.5,726.7,135.5,726.7,135.5z M711.2,96c-0.3,0.3-0.5,0.5-0.6,0.6c-3.4,4.4-6,9.4-9.2,13.9c-0.6,0.9,0.2,0.7,0.6,0.7
c3.2,0,6.4,0,9.6,0c1.1,0,1.1-0.4,1-1.3C712.2,105.4,711.5,100.9,711.2,96z"/>
<path class="st0" d="M859.4,69c5.6,0,10.9,0.8,15.9,3.6c5.5,3.1,9.2,7.7,11.2,13.7c0.6,1.7,0.5,1.7-1.2,2.4
c-5.5,2.3-11,4.7-16.5,7.1c-1,0.4-1.3,0.4-1.8-0.8c-1.4-3.6-4.1-6-8.1-6.4c-7.8-0.8-14.8,4.3-16.8,12.2c-0.8,2.9-1.1,5.9-0.3,8.9
c1.2,4.8,5.2,7.8,10.2,7.6c4.7-0.2,8.3-2.5,11.4-5.7c0.8-0.8,1.2-0.8,2-0.1c4.3,3.5,8.6,6.9,13,10.3c0.8,0.6,0.7,0.9,0.2,1.7
c-7,8.6-16.2,12.7-27.2,13.3c-7.6,0.4-14.9-1.2-21.3-5.5c-6.8-4.6-10.6-11.3-11.2-19.4c-1.2-15.7,5.2-27.8,18.1-36.5
c5.7-3.8,12.1-5.8,19-6.2C857.1,69.2,858.2,69.1,859.4,69z"/>
<path class="st0" d="M431.7,70.3c8.6,0,17.2,0,25.9,0c1.1,0,1.5,0.2,1.1,1.4c-1.5,5.4-3,10.9-4.4,16.4c-0.2,0.7-0.6,0.9-1.2,1
c-1.7,0-3.3,0.1-5,0.1c-8.6,0-17.2,0-25.7,0c-0.9,0-1.3,0.2-1.5,1.2c-0.4,2.1-1,4.2-1.5,6.3c-0.2,0.8,0.1,0.8,0.7,0.8
c2.8,0,5.7,0,8.5,0c6.5,0,13.1,0,19.6,0c0.9,0,1.3,0.1,1,1.2c-1.4,5-2.7,10-4,15.1c-0.2,0.9-0.8,0.8-1.4,0.8c-9.2,0-18.3,0-27.5,0
c-1.3,0-1.9,0.3-2.3,1.7c-1.5,6.1-3.2,12.1-4.8,18.2c-0.3,1-0.7,1.4-1.8,1.4c-6.3-0.1-12.7-0.1-19,0c-1.1,0-1.3-0.2-1-1.3
c2.7-10.8,5.7-21.5,8.6-32.2c2.6-9.9,5.3-19.7,8-29.6c0.6-2.1,0.6-2.1,2.8-2.1C415.1,70.3,423.4,70.3,431.7,70.3z"/>
<path class="st0" d="M172.4,29c-1.8,2.1-3.3,3.8-4.8,5.6C157.4,46.4,147.2,58.2,137,70c-1.3,1.5-2,3.1-2.5,4.9
c-1.6,6.1-3.2,12.1-4.8,18.2c-0.2,0.9-0.6,1.1-1.5,1.1c-6.4,0-12.8,0-19.2,0c-1,0-1.3-0.1-1-1.2c2-7.5,4-14.9,6-22.4
c0.2-0.9,0.3-1.8,0-2.7c-3.8-12.1-7.6-24.3-11.5-36.4c-0.1-0.3-0.2-0.6-0.3-0.8c-0.6-1.7-0.6-1.7,1.1-1.7c6.6,0,13.2,0,19.9,0
c1,0,1.3,0.3,1.6,1.2c1.5,6.4,3.1,12.8,4.6,19.2c0.1,0.3,0.1,0.6,0.4,0.7c0.4,0.1,0.6-0.3,0.8-0.5c2.6-2.9,4.7-6.1,7.1-9.2
c2.8-3.6,5.6-7.1,8.3-10.8c0.5-0.7,1.2-0.7,1.9-0.7c6.1,0,12.2,0,18.2,0C168.1,29,170.1,29,172.4,29z"/>
<path class="st0" d="M248.9,39.2c-1.3,3.1-2.7,6.2-4,9.4c-2.7,6.9-5.5,13.8-8.4,20.7c-0.4,1-0.2,1.2,0.8,1.2
c5.2,0,10.4,0.4,15.3,2.5c6.2,2.6,10.7,6.8,11.9,13.7c1.8,10.5-2.3,21.1-12.8,26.7c-5.9,3.2-12.3,4.4-18.9,4.5c-3,0.1-6,0-9,0
c-0.7,0-1.1,0.2-1.3,1c-1.3,5.2-2.8,10.4-4.1,15.5c-0.2,0.8-0.5,1.1-1.4,1.1c-6.3,0-12.6,0-18.9,0c-0.9,0-1.2-0.1-0.7-1.1
c7.3-13.5,14.5-27,21.7-40.5c6.4-12,12.9-24.1,19.3-36.1c3.2-6,6.6-11.9,9.6-18c0.1-0.3,0.3-0.5,0.5-0.7
C248.7,39.1,248.8,39.2,248.9,39.2z M230.4,102.3C230.4,102.3,230.4,102.3,230.4,102.3c0.6,0,1.3,0,1.9,0c3.4-0.2,6.5-0.9,8.9-3.6
c3.7-4.1,2.1-9.3-3.3-10.5c-2.1-0.5-4.3-0.1-6.5-0.3c-0.7,0-0.7,0.5-0.8,0.9c-1.1,4.2-2.2,8.5-3.3,12.7c-0.2,0.6,0,0.7,0.5,0.7
C228.7,102.2,229.6,102.3,230.4,102.3z"/>
</svg>
</template>

