<template>

  <div class="hide" style="font-size: 10px; margin-bottom: 16px;">

    <p>id: {{ this.templateData.data.id }}</p>
    <p>CustomerId: {{ this.templateData.data.CustomerId }}</p>
    <p>ExercisesDoing: {{ this.templateData.data.ExercisesDoing }}</p>
    <p>ExercisesPlanned: {{ this.templateData.data.ExercisesPlanned }}</p>
    <p>MembersDoing: {{ this.templateData.data.MembersDoing }}</p>
    <p>MembersPlanned: {{ this.templateData.data.MembersPlanned }}</p>
    
    <p>data: {{ this.templateData.data }}</p>
  </div>

  <form 
    style="padding: 0;"
    :id="this.templateData.data.id"
    @submit="handleSubmit"
  >

    <DxForm 
      :id="this.templateData.data.id"
      :form-data="this.templateData.data"
      :read-only="false"
      @initialized="saveFormInstance"
      :show-colon-after-label="true"
      :show-validation-summary="true"
      validation-group="currentUserData"
    >

      <DxSimpleItem 
        data-field="id"
        help-text=""
        css-class="hide"
      >
      </DxSimpleItem>

      <DxSimpleItem
        data-field="DateStart"
        editor-type="dxDateBox"
        :editor-options="calendarOptions"
        displayFormat="dd.MM.yyyy"  
        help-text=""
        css-class="hide"
      >
        <DxLabel :text="'Startdatum'"/>
      </DxSimpleItem>

      <DxSimpleItem
        data-field="DateStop"
        editor-type="dxDateBox"
        :editor-options="calendarOptions"
        displayFormat="dd.MM.yyyy"  
        help-text=""
        css-class="hide"
      >
        <DxLabel :text="'Enddatum'"/>
      </DxSimpleItem>

      <DxSimpleItem 
        data-field="mastercontent"
        help-text=""
        template="mastercontent"
      >
      <DxLabel 
        :text="'mastercontent'"
        :visible="false"
      />
      </DxSimpleItem>

      <template #mastercontent>

        <div :id="'planContainer'+this.templateData.data.id" class="master-content" style="position: relative;">

          <!-- SUBMIT BUTTON  -->
          <div class="grid-x grid-margin-x align-middle title-area-content">
            <div class="auto cell">
              <h5>
                <i class="dx-icon fa-light fa-light fa-dumbbell"></i>
                &nbsp;Einstellungen
              </h5>
            </div>
            <div class="shrink cell">
              <button :id="'submitform'+this.templateData.data.id" class="cx-button"><i class="fa-light fa-dumbbell"></i> {{currentUser.Translation.vueappExerciseplanFormSubmit}}</button>
            </div>
          </div>
          <!-- /SUBMIT BUTTON  -->

          <!-- NEW LAYOUT  -->

          <!-- row 1  -->
          <div class="grid-x grid-margin-x -align-middle">
            <div class="auto cell">
              <div class="dx-card no-paddings">
                <!-- row 1 cell 1 -->

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskSubject }}</p>
                  </div>
                </div>

                <ul :id="'tabsLang'+this.templateData.data.id" class="tabs hide" data-tabs>
                  <li class="tabs-title" :class="currentUser.Language == 'de'  ? 'is-active' : ''"><a :href="'#panelSubject_de'+this.templateData.data.id" aria-selected="true"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/de.svg'"><span class="hide">de</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'en'  ? 'is-active' : ''"><a :href="'#panelSubject_en'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/gb.svg'"><span class="hide">en</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'fr'  ? 'is-active' : ''"><a :href="'#panelSubject_fr'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/fr.svg'"><span class="hide">fr</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'es'  ? 'is-active' : ''"><a :href="'#panelSubject_es'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/es.svg'"><span class="hide">es</span></a></li>
                  <li class="tabs-title" :class="currentUser.Language == 'it'  ? 'is-active' : ''"><a :href="'#panelSubject_it'+this.templateData.data.id"><img style="width: 20px;" :src="apihost+'/bundles/pimcoreadmin/img/flags/countries/it.svg'"><span class="hide">it</span></a></li>
                </ul>

                <div class="tabs-content" :data-tabs-content="'tabsLang'+this.templateData.data.id">

                  <div class="tabs-panel" :class="currentUser.Language == 'de'  ? 'is-active' : ''" :id="'panelSubject_de'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_de'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_de"
                      :input-attr="{ 'aria-label': 'Subject_de' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'en'  ? 'is-active' : ''" :id="'panelSubject_en'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_en'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_en"
                      :input-attr="{ 'aria-label': 'Subject_en' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'fr'  ? 'is-active' : ''" :id="'panelSubject_fr'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_fr'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_fr"
                      :input-attr="{ 'aria-label': 'Subject_fr' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'es'  ? 'is-active' : ''" :id="'panelSubject_es'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_es'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_es"
                      :input-attr="{ 'aria-label': 'Subject_es' }"
                    />
                  </div>
                  <div class="tabs-panel" :class="currentUser.Language == 'it'  ? 'is-active' : ''" :id="'panelSubject_it'+this.templateData.data.id">
                    <DxTextBox
                      :id="'Subject_it'+this.templateData.data.id"
                      :value="this.templateData.data.Subject_it"
                      :input-attr="{ 'aria-label': 'Subject_it' }"
                    />
                  </div>

                </div><!-- /tabs-content-->

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappTaskDescription }}</p>
                  </div>
                </div>

                <DxTextBox
                  :id="'Description'+this.templateData.data.id"
                  :value="this.templateData.data.Description"
                  :input-attr="{ 'aria-label': 'Description' }"
                />


              </div><!-- /dx-card-->
            </div><!-- /auto cell-->

            <div class="auto cell">
              <div class="dx-card no-paddings">
                <!-- row 1 cell 2 -->

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappGlobalStartdate }}</p>
                  </div>
                </div>

                <DxDateBox
                  :id="'DateStart'+this.templateData.data.id"
                  :value="this.templateData.data.DateStart"
                  :input-attr="{ 'aria-label': 'Date' }"
                  displayFormat="dd.MM.yyyy"  
                  type="date"
                />

              </div><!-- /dx-card-->
            </div><!-- /auto cell-->

            <div class="auto cell">
              <div class="dx-card no-paddings">
                <!-- row 1 cell 3 -->

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ currentUser.Translation.vueappGlobalStopDate }}</p>
                  </div>
                </div>

                <DxDateBox
                  :id="'DateStop'+this.templateData.data.id"
                  :value="this.templateData.data.DateStop"
                  :input-attr="{ 'aria-label': 'Date' }"
                  displayFormat="dd.MM.yyyy"  
                  type="date"
                />
                
              </div><!-- /dx-card-->
            </div><!-- /auto cell-->
          </div><!-- /grid-x-->
          <!-- /row 1  -->

          <!-- row 2  -->

          <!-- SUBMIT BUTTON  -->
          <div class="grid-x grid-margin-x align-middle title-area-content">
            <div class="auto cell">
              <h5>
                <i class="dx-icon fa-light fa-circle-play"></i>
                &nbsp;Übungen
              </h5>
            </div>
            <div class="shrink cell">
              <button :id="'submitform'+this.templateData.data.id" class="cx-button"><i class="fa-light fa-dumbbell"></i> {{currentUser.Translation.vueappExerciseplanFormSubmit}}</button>
            </div>
          </div>
          <!-- /SUBMIT BUTTON  -->
          

          <div class="grid-x grid-margin-x -align-middle">
            <div class="auto cell">
              <div class="dx-card no-paddings" style="overflow-y: scroll; height: 400px;">
                <!-- row 2 cell 1 -->

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ doingExercises.length }} verfügbare Übungen</p>
                  </div>
                </div>

                <DxList
                  :data-source="doingExercises"
                  key-expr="id"
                  :search-enabled="true"
                  :search-expr="['text','text_en','Option1string','Option2string']"
                  item-template="doing-exercises">
                >

                  <DxSearchEditorOptions
                    placeholder="Übungen suchen..."
                  />

                  <template #doing-exercises="{ data }">
                    <div>
                      
                      <div class="grid-x align-middle ck-itemrow">
                        <div class="shrink cell">
                          <div class="video-thumb" v-if="data.image"
                            style="margin: 0 10px 0 0;"
                            v-bind:style="{ 'background-image': 'url(' + data.image + '?v=' + timestamp + ')' }" >
                          </div>
                        </div>
                        <div class="auto cell grid-x">

                          <div class="shrink cell">
                            <div class="hide">
                              <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                            </div>
                          </div>

                          <div class="auto cell">
                            <p class="ck-overflow-ellipsis strong">
                              {{ data.text }}
                            </p>
                            <p class="ck-overflow-ellipsis small hide">
                            {{ data.Option1string }} <span v-if="data.Option2string">|</span> {{ data.Option2string }}
                            </p>

                          </div>

                        </div>
                      </div>

                    </div>
                  </template>

                  <DxItemDragging
                    data="doingExercises"
                    :allow-reordering="true"
                    :on-drag-start="onDragStartExercises"
                    :on-add="onAddExercises"
                    :on-remove="onRemoveExercises"
                    :on-reorder="onReorderExercises"
                    group="tasks"
                  />
                </DxList>


              </div><!-- /dx-card-->
            </div><!-- /auto cell-->

            <div class="auto cell">
              <div class="dx-card no-paddings" style="overflow-y: scroll; height: 400px;">
                <!-- row 2 cell 2 -->

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ plannedExercises.length }} zugewiesene Übungen</p>
                  </div>
                </div>

                <DxList
                  :data-source="plannedExercises"
                  key-expr="id"
                  :search-enabled="true"
                  :search-expr="['text','text_en','Option1string','Option2string']"
                  item-template="planned-exercises">
                >

                <DxSearchEditorOptions
                  placeholder="Übungen suchen..."
                />

                <template #planned-exercises="{ data }">

                  <div style="position: relative;">

                    <!-- button Detail-->
                    <div style="position: absolute; top: 0; left: 0; width: 100%; height: 48px;"
                      @click="editexercise" class="exbutton" :id="'exbutton'+data.id+this.templateData.data.id" :data-exerciseid="data.id" :data-exerciseoption1="data.Option1stringitemkey">
                    </div>
                    <!-- /button Detail-->

                    <div class="grid-x align-middle ck-itemrow">

                      <div class="shrink cell">
                        <div class="video-thumb" v-if="data.image"
                          style="margin: 0 10px 0 0;"
                          v-bind:style="{ 'background-image': 'url(' + data.image + '?v=' + timestamp + ')' }" >
                        </div>
                      </div>

                      <div class="auto cell grid-x">

                        <div class="shrink cell">
                          <div class="hide">
                            <i class="fa-light fa-square-check" style="color: green;"></i>&nbsp;
                          </div>
                        </div>

                        <div class="auto cell">
                          <p class="ck-overflow-ellipsis strong">
                            {{ data.text }}
                          </p>
                          <p class="ck-overflow-ellipsis small hide">
                            {{ data.Option1string }} <span v-if="data.Option2string">|</span> {{ data.Option2string }}
                          </p>
                        </div>


                      </div>

                    </div>

                  </div>
                </template>

                <DxItemDragging
                  data="plannedExercises"
                  :allow-reordering="true"
                  :on-drag-start="onDragStartExercises"
                  :on-add="onAddExercisessave"
                  :on-remove="onRemoveExercisessave"
                  :on-reorder="onReorderExercisessave"
                  group="tasks"
                />
              </DxList>


              </div>
            </div>
            <div class="auto cell">
              <div class="dx-card no-paddings">
                <!-- row 2 cell 3 -->

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>Einstellungen Übungen</p>
                    </div>
                  </div>

                  <div class="-padding-wrap" style="overflow-y: scroll; height: 360px;">

                    <!-- Show Details on Click Left -->
                    <div v-for="exercises in plannedExercises" :key="exercises.id" :id="'excontent'+exercises.id+this.templateData.data.id" class="excontent hide">
                    
                    <div class="grid-x align-middle" style="border-bottom: 1px solid #dedede;">

                      <div class="shrink cell">
                        <div class="video-thumb" v-if="exercises.poster"
                          style="margin: 0 10px 0 0;"
                          v-bind:style="{ 'background-image': 'url(' + exercises.poster + '?v=' + timestamp + ')' }" >
                        </div>
                      </div>

                      <div class="auto cell">
                        <p class="ck-overflow-ellipsis strong" style="margin-bottom: 5px;">{{ exercises.text }}</p>
                      </div>

                    </div>

                    <div class="hide">
                    <!-- cell Video-->
                    <div v-if="exercises.video">
                      <div class="responsive-embed widescreen" style="padding-bottom: 56.25%; margin: 0;">
                        <video width="1920" height="1080" :poster="exercises.poster" controls muted preload="none" >
                          <source :src="exercises.video" type="video/mp4">
                          Your browser does not support the video tag.
                        </video>
                      </div>
                    </div>
                    <div v-else>
                      <div class="responsive-embed widescreen" style="padding-bottom: 56.25%; background-color: #efefef;">
                        <img style="width: 100%;" :src="exercises.poster">
                      </div>
                    </div>
                    <!-- /cell Video-->
                    </div><!-- /Video hide -->

                    <!-- settingtype-->

                    <div class="dx-card-label grid-x align-middle">
                      <div class="auto cell">
                        <p>Typ</p>
                      </div>
                    </div>

                    <div class="-ck-overflow-container -hide" style="height: 30px; padding: 5px 5px; background-color: #f3f3f3;">
                      <div class="radio-container" v-for="settingentity in settingEntities" :key="settingentity.id" style="margin: 0 15px 0 0; font-size: 14px;">
                        <div class="radio-item" v-if="exercises.Type">
                          <input type="radio"
                            :name="'settingtype'+exercises.id+this.templateData.data.id+'[]'" 
                            :value="settingentity.value" 
                            :id="settingentity.id+'_'+exercises.id+this.templateData.data.id" 
                            :checked="settingentity.value == exercises.Type"
                            @click="changetype"
                            required
                          >
                          <label :for="settingentity.id+'_'+exercises.id+this.templateData.data.id" style="font-size: 13px;">
                            &nbsp;
                            <i class="fa-light fa-repeat" :class="settingentity.value == 'time'  ? 'hide' : ''"></i>
                            <i class="fa-light fa-stopwatch" :class="settingentity.value == 'repeat'  ? 'hide' : ''"></i>
                            {{ settingentity.text }}
                          </label>
                          <span class="checkmark"></span>
                        </div>
                      </div>
                    </div>
                    <!-- /settingtype-->

                    <!-- position-->
                    <template v-if="exercises.Position.length">

                      <div class="dx-card-label grid-x align-middle">
                        <div class="auto cell">
                          <p>Positionen</p>
                        </div>
                      </div>

                      <div class="-position-wrap" style="margin: 5px 10px;">
                        <div class="grid-x align-middle">
                          <div class="shrink cell" style="width: 15px;">
                            <p><small>&nbsp;</small></p>
                          </div>
                          <div class="auto cell">
                            <p>
                              <small>Position</small>
                            </p>
                          </div>
                          <div class="auto cell">
                            <p>
                              <small>Wert</small>
                            </p>
                          </div>
                        </div>


                        <div class="grid-x align-middle" v-for="item in exercises.Position" :key="item.id">
                          <div class="shrink cell" style="width: 15px;">
                            <p><small>{{ item.id }}</small></p>
                          </div>
                          <div class="auto cell hide">
                            <input :name="'positionoid'+exercises.id+this.templateData.data.id+'[]'" :value="item.oid" type="number" autocomplete="off" spellcheck="false" class="cx-input-small">
                          </div>

                          <div class="auto cell">
                            <!-- <p>{{ item.text }}</p>-->
                            <input :name="'positiontext'+exercises.id+this.templateData.data.id+'[]'" :value="item.text" type="text" autocomplete="off" spellcheck="false" class="cx-input-small" disabled>
                          </div>
                          <div class="auto cell">
                            <input :name="'positionvalue'+exercises.id+this.templateData.data.id+'[]'" :value="item.value" type="number" autocomplete="off" spellcheck="false" class="cx-input-small">
                          </div>
                        </div>

                      </div> <!-- /position-wrap-->
                    </template>
                    <!-- /position-->

                    <!-- repetition-->
                    <div class="dx-card-label grid-x align-middle">
                      <div class="auto cell">
                        <p>Sätze</p>
                      </div>
                    </div>

                    <div class="-repetition-wrap" style="margin: 5px 10px;">

                      <div class="grid-x align-middle">
                        <div class="shrink cell" style="width: 15px;">
                          <p><small>&nbsp;</small></p>
                        </div>
                        <div class="auto cell" :class="'0_'+exercises.id+this.templateData.data.id+' all_'+exercises.id+this.templateData.data.id">
                          <p>
                            <i class="fa-light fa-repeat"></i>&nbsp;
                            <small>Wdhl. (x)</small>
                          </p>
                        </div>
                        <div class="auto cell" :class="'1_'+exercises.id+this.templateData.data.id+' all_'+exercises.id+this.templateData.data.id">
                          <p>
                            <i class="fa-light fa-stopwatch"></i>&nbsp;
                            <small>Zeit (sek)</small>
                          </p>
                        </div>
                        <div class="auto cell">
                          <p>
                            <i class="fa-light fa-weight-hanging"></i>&nbsp;
                            <small>Gewicht (kg)</small>
                          </p>
                        </div>
                      </div>

                      <div class="grid-x align-middle" v-for="item in exercises.Repetition" :key="item.id">
                        <div class="shrink cell" style="width: 15px;">
                          <p><small>{{ item.id }}</small></p>
                        </div>
                        <div class="auto cell" :class="'0_'+exercises.id+this.templateData.data.id+' all_'+exercises.id+this.templateData.data.id">
                        <input :name="'repetitionrep'+exercises.id+this.templateData.data.id+'[]'" :value="item.rep" type="number" autocomplete="off" spellcheck="false" class="cx-input-small">
                        </div>
                        <div class="auto cell" :class="'1_'+exercises.id+this.templateData.data.id+' all_'+exercises.id+this.templateData.data.id">
                          <input :name="'repetitiontime'+exercises.id+this.templateData.data.id+'[]'" :value="item.time" type="number" autocomplete="off" spellcheck="false" class="cx-input-small">
                        </div>
                        <div class="auto cell">
                          <input :name="'repetitionweight'+exercises.id+this.templateData.data.id+'[]'" :value="item.weight" type="number" autocomplete="off" spellcheck="false" class="cx-input-small">
                        </div>
                      </div>

                    </div> <!-- /repetition-wrap-->

                    <!-- /repetition-->

                    <!-- musclegroup-->
                    <div class="hide">

                    <div :id="'musclegroupContainer'+exercises.id+this.templateData.data.id">

                      <label class="ck-label hide">Muskelgruppen</label>

                      <div class="dx-card-label grid-x align-middle" style="margin-bottom: 10px; border-top: none;" >
                        <div class="auto cell">
                          <p>Muskelgruppen</p>
                        </div>
                      </div>

                      <div class="musclegroup-container">
                        <div class="grid-x">
                          <div class="auto cell">
                            <ManFront />
                          </div>
                          <div class="auto cell">
                            <ManBack />
                          </div>
                          <div class="auto cell">
                            <WomanFront />
                          </div>
                          <div class="auto cell">
                            <WomanBack />
                          </div>
                        </div>
                      </div><!-- musclegroup-container-->

                      </div><!-- ID musclegroupContainer-->

                      <p class="ck-overflow-ellipsis small" style="font-size: 10px; margin-top: 5px;">
                        {{ exercises.Option1string }}
                      </p>

                    </div>
                    <!-- /musclegroup-->
                
                  </div><!-- /Musclegroup hide -->


                  </div>
                  <!-- /padding-wrap -->

                </div><!-- /dx-card-->
              </div><!-- /auto cell-->
            </div><!-- /grid-x-->

          <!-- /row 2  -->

          <!-- row 3  -->

          <div :class="this.templateData.data.CustomerId  ? 'hide' : ''">
              
          <!-- SUBMIT BUTTON  -->
          <div class="grid-x grid-margin-x align-middle title-area-content">
            <div class="auto cell">
              <h5>
              <i class="dx-icon fa-light fa-users"></i>
                &nbsp;Mitglieder
              </h5>
            </div>
            <div class="shrink cell">
              <button :id="'submitform'+this.templateData.data.id" class="cx-button"><i class="fa-light fa-dumbbell"></i> {{currentUser.Translation.vueappExerciseplanFormSubmit}}</button>
            </div>
          </div>
          <!-- /SUBMIT BUTTON  -->
          
            <div class="grid-x grid-margin-x -align-middle">
              <div class="auto cell">
                <div class="dx-card no-paddings" style="overflow-y: scroll; height: 400px;">
                  <!-- row 3 cell 1 -->

                  <div class="dx-card-label grid-x align-middle">
                    <div class="auto cell">
                      <p>{{ doingMembers.length }} verfügbare Mitglieder</p>
                    </div>
                  </div>
                  
                  <DxList
                    :data-source="doingMembers"
                    key-expr="id"
                    :search-enabled="true"
                    :search-expr="['text','text_en','Option1string','Option2string']"
                    item-template="doing-members">
                  >

                  <DxSearchEditorOptions
                    placeholder="Mitglieder suchen..."
                  />

                  <template #doing-members="{ data }">
                    <div>
                      
                      <div class="grid-x align-middle ck-itemrow">
                        <div class="shrink cell">
                          <div class="user-thumb" v-if="data.image"
                            style="margin: 0 10px 0 5px;"
                            v-bind:style="{ 'background-image': 'url(' + data.image + '?v=' + timestamp + ')' }" >
                          </div>
                        </div>
                        <div class="auto cell grid-x">

                          <div class="shrink cell">
                            <div class="hide">
                              <i class="fa-light fa-square-dashed-circle-plus"></i>&nbsp;
                            </div>
                          </div>

                          <div class="auto cell">
                            <p class="ck-overflow-ellipsis strong">
                              {{ data.text }}
                            </p>
                            <p class="ck-overflow-ellipsis small hide">
                              user info
                            </p>
                          </div>

                        </div>
                      </div>

                    </div>
                  </template>

                  <DxItemDragging
                    data="doingMembers"
                    :allow-reordering="true"
                    :on-drag-start="onDragStartMembers"
                    :on-add="onAddMembers"
                    :on-remove="onRemoveMembers"
                    :on-reorder="onReorderMembers"
                    group="tasks"
                  />
                </DxList>

              </div><!-- /dx-card-->
            </div><!-- /auto cell-->


            <div class="auto cell">
              <div class="dx-card no-paddings" style="overflow-y: scroll; height: 400px;">
                <!-- row 3 cell 2 -->

                <div class="dx-card-label grid-x align-middle">
                  <div class="auto cell">
                    <p>{{ plannedMembers.length }} zugewiesene Mitglieder</p>
                  </div>
                </div>

                <DxList
                  :data-source="plannedMembers"
                  key-expr="id"
                  :search-enabled="true"
                  :search-expr="['text','text_en','Option1string','Option2string']"
                  item-template="planned-members">
                >

                <DxSearchEditorOptions
                  placeholder="Mitglieder suchen..."
                />

                <template #planned-members="{ data }">
                  <div>
                      
                    <div class="grid-x align-middle ck-itemrow">
                      <div class="shrink cell">
                        <div class="user-thumb" v-if="data.image"
                          style="margin: 0 10px 0 5px;"
                          v-bind:style="{ 'background-image': 'url(' + data.image + '?v=' + timestamp + ')' }" >
                        </div>
                      </div>

                      <div class="auto cell grid-x">

                        <div class="shrink cell">
                          <div class="hide">
                            <i class="fa-light fa-square-check" style="color: green;"></i>&nbsp;
                          </div>
                        </div>
                      
                        <div class="auto cell">
                          <p class="ck-overflow-ellipsis strong">
                            {{ data.text }}
                          </p>
                          <p class="ck-overflow-ellipsis small hide">
                            user info
                          </p>
                        </div>

                      </div>

                    </div>

                  </div>
                </template>

                <DxItemDragging
                  data="plannedMembers"
                  :allow-reordering="true"
                  :on-drag-start="onDragStartMembers"
                  :on-add="onAddMemberssave"
                  :on-remove="onRemoveMemberssave"
                  :on-reorder="onReorderMemberssave"
                  group="tasks"
                />
                </DxList>

              </div><!-- /dx-card-->
            </div><!-- /auto cell-->


            <div class="auto cell">
              <div class="dx-card no-paddings hide">
                row 3 cell 3
              </div><!-- /dx-card-->
            </div><!-- /auto cell-->

          </div><!-- /grid-x-->


          </div><!-- /hide-x-->

           <!-- /row 3  -->

          <!-- /NEW LAYOUT  -->

        </div><!-- master-content  -->

          
      </template>

      <DxButtonItem
        horizontal-alignment="left"
        :button-options="buttonOptions"
        css-class="hide"
      />
      
    </DxForm>

  </form>


</template>
<script>

import DxForm, {
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  //DxGroupItem,
  //DxRequiredRule,
  //DxPatternRule,
  //DxCompareRule,
  //DxRangeRule,
  //DxStringLengthRule,
  //DxEmailRule,
  //DxAsyncRule,
} from 'devextreme-vue/form';

import DxList, { DxItemDragging, DxSearchEditorOptions } from 'devextreme-vue/list';
import notify from 'devextreme/ui/notify';
import DxDateBox from 'devextreme-vue/date-box';
import DxTextBox from 'devextreme-vue/text-box';

import $ from 'jquery';
import Foundation from 'foundation-sites';

import ManFront from '../svg/man_front.vue';
import ManBack from '../svg/man_back.vue';
import WomanFront from '../svg/woman_front.vue';
import WomanBack from '../svg/woman_back.vue';

import auth from "../auth";

import { 
  apihost, 
} from "../api";
//console.log(apihost);

let doingExercises = [];
let plannedExercises = [];

let doingMembers = [];
let plannedMembers = [];

let saveExercisesPlan = [];
let saveMembersPlan = [];


//let doingExercises;

const colCountByScreen = {
  xs: 1,
  sm: 1,
  md: 3,
  lg: 4
}

let currentUser;
let timestamp;

auth.getUser().then((e) => {
  if(e.data){
    currentUser = e.data;
  } // e.data
}); //auth

function handleErrors(response) {
  if (!response.ok) {
      throw Error(response.statusText);
  }
  return response;
}

export default {
 name: 'exerciseplan-template',

 props: {
  templateData: Object,
 },

 components: {
  DxList,
  DxItemDragging,
  DxSearchEditorOptions,

  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxDateBox,
  DxTextBox,
  //DxGroupItem,

  ManFront,
  ManBack,
  WomanFront,
  WomanBack,

 },

 mounted() {

  //foundation
  this.tabsLang = new Foundation.Tabs($('#tabsLang'+this.templateData.data.id), {
    matchHeight: false
  })

  // this.tabsPlan = new Foundation.Tabs($('#tabsPlan'+this.templateData.data.id), {
  //   matchHeight: false
  // })

  this.doingExercises = this.templateData.data.ExercisesDoing;
  this.plannedExercises = this.templateData.data.ExercisesPlanned;

  this.doingMembers = this.templateData.data.MembersDoing;
  this.plannedMembers = this.templateData.data.MembersPlanned;

  this.timestamp = this.printTimestamp();

 },


 data() {
   return {
    apihost,
    
    timestamp,

    currentUser,
    colCountByScreen,

    doingExercises,
    plannedExercises,

    doingMembers,
    plannedMembers,
  
    saveExercisesPlan,
    saveMembersPlan,

    calendarOptions: { 
      displayFormat: "dd.MM.yyyy"
    },
    
    buttonOptions: {
      text: currentUser.Translation.vueappExerciseplanFormSubmit,
      type: 'success',
      useSubmitBehavior: true,
    },
    dateBoxOptions: {
      invalidDateMessage:
        'The date must have the following format: MM/dd/yyyy',
    },
    checkBoxOptions: {
      text: 'I agree to the Terms and Conditions',
      value: false,
    },
    phoneEditorOptions: {
      mask: '+1 (X00) 000-0000',
      maskRules: {
        X: /[02-9]/,
      },
      maskInvalidMessage: 'The phone must have a correct USA phone format',
    },

    statusPattern: /^[^0-9]+$/,
    priorityPattern: /^[^0-9]+$/,
    exerciseplanPattern: /^[^0-9]+$/,

    // phonePattern: /^[02-9]\d{9}$/,
    // maxDate: new Date().setFullYear(new Date().getFullYear() - 21),
    
    validationRules: {

      status: [
        { type: 'required', message: currentUser.Translation.vueappTaskStatusMessage },
      ],
      priority: [
        { type: 'required', message: currentUser.Translation.vueappTaskPriorityMessage },
      ],
    },
    statusEditorOptions: { items: currentUser.Constant.statuses, searchEnabled: true },
    priorityEditorOptions: { items: currentUser.Constant.priorities, searchEnabled: true },

    settingEntities: [
      { id: 0, value: 'repeat', text: 'Wdhl.' },
      { id: 1, value: 'time', text: 'Zeit' },
    ],

  };

 },
 methods: {

  onDragStartExercises(e) {
    //console.log('onDragStartExercises');
    //e.itemData = this[e.fromData][e.fromIndex];
    let items = e.component.getDataSource().items();
    e.itemData = items[e.fromIndex];
  },
  onAddExercisessave(e) {
    //console.log('onAddExercisessave');
    // const data = [...this[e.toData]];
    // data.splice(e.toIndex, 0, e.itemData);
    // this[e.toData] = data;
    const data = [...this[e.toData]];
    data.splice(e.toIndex, 0, e.itemData);
    this[e.toData] = data;
    e.component.getDataSource().reload();
    this.saveExercisesPlan = data;

    //AUTOSAVE
    // console.log('save add');
    // let submitbutton = document.getElementById('submitform'+this.templateData.data.id);
    // submitbutton.click();

  },
  onRemoveExercisessave(e) {
    //console.log('onRemoveExercisessave');
    // const data = [...this[e.fromData]];
    // data.splice(e.fromIndex, 1);
    // this[e.fromData] = data;
    const data = [...this[e.fromData]];
    const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
    data.splice(itemIndex, 1);
    this[e.fromData] = data;
    e.component.getDataSource().reload();
    this.saveExercisesPlan = data;

    //AUTOSAVE
    // console.log('save remove');
    // let submitbutton = document.getElementById('submitform'+this.templateData.data.id);
    // submitbutton.click();

  },
  onReorderExercisessave(e) {
    //console.log('onReorderExercisessave');
    this.onRemoveExercisessave(e);
    this.onAddExercisessave(e);
  },
  onAddExercises(e) {
    //console.log('onAddExercises');
    // const data = [...this[e.toData]];
    // data.splice(e.toIndex, 0, e.itemData);
    // this[e.toData] = data;
    const data = [...this[e.toData]];
    data.splice(e.toIndex, 0, e.itemData);
    this[e.toData] = data;
    e.component.getDataSource().reload();
  },
  onRemoveExercises(e) {
    //console.log('onRemoveExercises');
    // const data = [...this[e.fromData]];
    // data.splice(e.fromIndex, 1);
    // this[e.fromData] = data;
    const data = [...this[e.fromData]];
    const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
    data.splice(itemIndex, 1);
    this[e.fromData] = data;
    e.component.getDataSource().reload();
  },
  onReorderExercises(e) {
    //console.log('onReorderExercises');
    this.onRemoveExercises(e);
    this.onAddExercises(e);
  },

  onDragStartMembers(e) {
    //console.log('onDragStartMembers');
    // e.itemData = this[e.fromData][e.fromIndex];
    let items = e.component.getDataSource().items();
    e.itemData = items[e.fromIndex];
  },
  onAddMemberssave(e) {
    //console.log('onAddMemberssave');
    // const data = [...this[e.toData]];
    // data.splice(e.toIndex, 0, e.itemData);
    // this[e.toData] = data;
    const data = [...this[e.toData]];
    data.splice(e.toIndex, 0, e.itemData);
    this[e.toData] = data;
    e.component.getDataSource().reload();
    this.saveMembersPlan = data;
  },
  onRemoveMemberssave(e) {
    //console.log('onRemoveMemberssave');
    // const data = [...this[e.fromData]];
    // data.splice(e.fromIndex, 1);
    // this[e.fromData] = data;
    const data = [...this[e.fromData]];
    const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
    data.splice(itemIndex, 1);
    this[e.fromData] = data;
    e.component.getDataSource().reload();
    this.saveMembersPlan = data;
  },
  onReorderMemberssave(e) {
    //console.log('onReorderMemberssave');
    this.onRemoveMemberssave(e);
    this.onAddMemberssave(e);
  },
  onAddMembers(e) {
    //console.log('onAddMembers');
    // const data = [...this[e.toData]];
    // data.splice(e.toIndex, 0, e.itemData);
    // this[e.toData] = data;
    const data = [...this[e.toData]];
    data.splice(e.toIndex, 0, e.itemData);
    this[e.toData] = data;
    e.component.getDataSource().reload();
  },
  onRemoveMembers(e) {
    //console.log('onRemoveMembers');
    // const data = [...this[e.fromData]];
    // data.splice(e.fromIndex, 1);
    // this[e.fromData] = data;
    const data = [...this[e.fromData]];
    const itemIndex = data.findIndex((i) => i.id === e.itemData.id);
    data.splice(itemIndex, 1);
    this[e.fromData] = data;
    e.component.getDataSource().reload();
  },
  onReorderMembers(e) {
    //console.log('onReorderMembers');
    this.onRemoveMembers(e);
    this.onAddMembers(e);
  },

  changetype(e) {
    //console.log('changetype');
    // console.log(e.target.id);
    // console.log(e.target.value);

    //Parent Element
    const planContainer = document.getElementById("planContainer"+this.templateData.data.id);

    const allColumns = planContainer.getElementsByClassName("all_"+e.target.id.replace('0_','').replace('1_',''));
    //iterate Nodes All (add class hide for each columns) 
    for (let i = 0; i < allColumns.length; i++) {
      allColumns[i].classList.add("hide");
    }

    const repColumns = planContainer.getElementsByClassName("0_"+e.target.id.replace('0_',''));
    //iterate Nodes Rep (add class hide for each columns) 
    if(e.target.value == 'repeat'){
      for (let i = 0; i < repColumns.length; i++) {
        repColumns[i].classList.remove("hide");
      }
    }

    const timeColumns = planContainer.getElementsByClassName("1_"+e.target.id.replace('1_',''));
    //iterate Nodes Time (add class hide for each columns) 
    if(e.target.value == 'time'){
      for (let i = 0; i < timeColumns.length; i++) {
      timeColumns[i].classList.remove("hide");
      }
    }

  },

  editexercise(e) {
    //console.log('editexercise');
    // `e` is the native DOM event
    if (e) {
      // console.log(e);
      // console.log(e.target.id);

      //Parent Element
      const planContainer = document.getElementById("planContainer"+this.templateData.data.id);

      //Nodes
      const buttonnodes = planContainer.getElementsByClassName("exbutton");
      const contentnodes = planContainer.getElementsByClassName("excontent");

      //iterate Nodes (add or remove classes) 
      for (let i = 0; i < buttonnodes.length; i++) {
        buttonnodes[i].classList.remove("is-active");
      }
      for (let i = 0; i < contentnodes.length; i++) {
        contentnodes[i].classList.add("hide");
      }

      // this target (add or remove classes)
      const buttonnode = document.getElementById(e.target.id);
      const contentnode = document.getElementById(e.target.id.replace('exbutton','excontent'));
      const exerciseid = buttonnode.dataset.exerciseid;
      const stringoption1 = buttonnode.dataset.exerciseoption1;

      buttonnode.classList.add("is-active");
      contentnode.classList.remove("hide");

      //Musclegroup
      const musclegroup = document.getElementById("musclegroupContainer"+exerciseid+this.templateData.data.id);
      const svgnodes = musclegroup.getElementsByTagName("g");

      //remove all checked  
      for (let i = 0; i < svgnodes.length; i++) {
        if(svgnodes[i].id && svgnodes[i].id != 'Kontur'){
          let text = svgnodes[i].id;
          let svg = document.getElementById("musclegroupContainer"+exerciseid+this.templateData.data.id).querySelector('#'+text);
          if(svg){
            svg.classList.remove("checked");
          }
        }
      }  

      let musclegroupArray = [];

      if(stringoption1){
        musclegroupArray = stringoption1.split(", ");
      }
      
      //add checked for selected
      for (let i = 0; i < musclegroupArray.length; i++) {
        let text = musclegroupArray[i].replace(' ','_').replace('Ä','Ae').replace('ä','ae').replace('Ü','Ue').replace('ü','ue').replace('Ö','Oe').replace('ö','oe');

        let svgmanfront = document.getElementById("musclegroupContainer"+exerciseid+this.templateData.data.id).querySelector('#'+text+'_MF');
        let svgmanback = document.getElementById("musclegroupContainer"+exerciseid+this.templateData.data.id).querySelector('#'+text+'_MB');
        let svgwomanfront = document.getElementById("musclegroupContainer"+exerciseid+this.templateData.data.id).querySelector('#'+text+'_WF');
        let svgwomanback = document.getElementById("musclegroupContainer"+exerciseid+this.templateData.data.id).querySelector('#'+text+'_WB');

        if(svgmanfront){
          svgmanfront.classList.add("checked");
        }
        if(svgmanback){
          svgmanback.classList.add("checked");
        }
        if(svgwomanfront){
          svgwomanfront.classList.add("checked");
        }
        if(svgwomanback){
          svgwomanback.classList.add("checked");
        }

      } 
      // end Musclegroup


      //POSITION HERE
      console.log('POSITION HERE');

      //FETCH NEW?
      //return fetch(apihost+'/'+currentUser.Language+'/vue/exerciseplan/list/?Customerid='+customerId)
      console.log('FETCH NEW');


      //this.doingExercises = this.templateData.data.ExercisesDoing;
      //this.plannedExercises = this.templateData.data.ExercisesPlanned;

      //END POSITION HERE
      
      //HIDE REPETITION COLUMNS ONLOAD
      const typeradios = document.getElementsByName(e.target.id.replace('exbutton','settingtype')+'[]');
      //console.log(radios);
      let valuetype = ""
      for (var i = 0, length = typeradios.length; i < length; i++) {
        if (typeradios[i].checked) {
          valuetype = typeradios[i].value;
            break;
        }
      }
      //console.log(valuetype);

      const allColumns = planContainer.getElementsByClassName("all_"+e.target.id.replace('exbutton',''));
      //console.log(allColumns);
      //iterate Nodes All (add class hide for each columns) 
      for (let i = 0; i < allColumns.length; i++) {
        //console.log(allColumns[i]);
        allColumns[i].classList.add("hide");
      }

      const repColumns = planContainer.getElementsByClassName("0_"+e.target.id.replace('exbutton',''));
      //iterate Nodes Rep (add class hide for each columns) 
      if(valuetype == 'repeat'){
        for (let i = 0; i < repColumns.length; i++) {
          //console.log(repColumns[i]);
          repColumns[i].classList.remove("hide");
        }
      }

      const timeColumns = planContainer.getElementsByClassName("1_"+e.target.id.replace('exbutton',''));
      //iterate Nodes Time (add class hide for each columns) 
      if(valuetype == 'time'){
        for (let i = 0; i < timeColumns.length; i++) {
          //console.log(timeColumns[i]);
          timeColumns[i].classList.remove("hide");
        }
      }

      //end HIDE REPETITION COLUMNS ONLOAD

    }
    //end editexercise

  },

  printTimestamp: function () {
    return Date.now();
  },

  saveFormInstance(e) {
    this.formInstance = e.component;
  },

  handleSubmit(e) {
    e.preventDefault();

    const id = this.formInstance.getEditor('id').option('value'); 
    // const Status = this.formInstance.getEditor('Status').option('value');  
    // const Priority = this.formInstance.getEditor('Priority').option('value');  

    const Subject_de = document.getElementById("Subject_de"+id).getElementsByTagName("input")[0].value;
    const Subject_en = document.getElementById("Subject_en"+id).getElementsByTagName("input")[0].value;
    const Subject_fr = document.getElementById("Subject_fr"+id).getElementsByTagName("input")[0].value;
    const Subject_es = document.getElementById("Subject_es"+id).getElementsByTagName("input")[0].value;
    const Subject_it = document.getElementById("Subject_it"+id).getElementsByTagName("input")[0].value;

    const Description = document.getElementById("Description"+id).getElementsByTagName("input")[0].value;

    const DateStart = document.getElementById("DateStart"+id).getElementsByTagName("input")[0].value;
    const DateStop = document.getElementById("DateStop"+id).getElementsByTagName("input")[0].value;

    //https://stackoverflow.com/questions/10830357/javascript-toisostring-ignores-timezone-offset

    let getdateStart;
    let isoDateStart;
    if(DateStart){
      getdateStart = new Date(DateStart);
      isoDateStart = new Date(getdateStart.getTime() - (getdateStart.getTimezoneOffset() * 60000)).toISOString();
    }
    let getdateStop;
    let isoDateStop;
    if(DateStop){
      getdateStop = new Date(DateStop);
      isoDateStop = new Date(getdateStop.getTime() - (getdateStop.getTimezoneOffset() * 60000)).toISOString();
    }

    let ToSaveExercises;
    if(this.saveExercisesPlan.length > 0){
      ToSaveExercises = this.saveExercisesPlan;
    } else {
      ToSaveExercises = this.templateData.data.ExercisesPlanned;
    }
    let arrayExerciseitems = [];

    for (let i = 0; i < ToSaveExercises.length; i++) {

      //Settingtype
      const radiosettingtype = document.getElementsByName("settingtype"+ToSaveExercises[i].id+this.templateData.data.id+"[]");
      let Settingtype;
      if(radiosettingtype){
        const selectedSettingtype = Array.prototype.slice.call(radiosettingtype).filter(ch => ch.checked==true);
        //console.log(selectedSettingtype);
        if(selectedSettingtype.length > 0){
        Settingtype = selectedSettingtype[0].value;
        }

      }

      //Position
      const positionOid = document.getElementsByName("positionoid"+ToSaveExercises[i].id+this.templateData.data.id+"[]");
      const positionText = document.getElementsByName("positiontext"+ToSaveExercises[i].id+this.templateData.data.id+"[]");
      const positionValue = document.getElementsByName("positionvalue"+ToSaveExercises[i].id+this.templateData.data.id+"[]");
      let positionselectedOid = [];
      let positionselectedText = [];
      let positionselectedValue = [];
      for (let i = 0; i < positionOid.length; i++) {
        positionselectedOid.push(positionOid[i].value);
      }
      for (let i = 0; i < positionText.length; i++) {
        positionselectedText.push(positionText[i].value);
      }
      for (let i = 0; i < positionValue.length; i++) {
        positionselectedValue.push(positionValue[i].value);
      }
      const Positionoid = JSON.stringify(positionselectedOid);
      const Positiontext = JSON.stringify(positionselectedText);
      const Positionvalue = JSON.stringify(positionselectedValue);

      //Repetition
      const repetitionRep = document.getElementsByName("repetitionrep"+ToSaveExercises[i].id+this.templateData.data.id+"[]");
      const repetitionTime = document.getElementsByName("repetitiontime"+ToSaveExercises[i].id+this.templateData.data.id+"[]");
      const repetitionWeight = document.getElementsByName("repetitionweight"+ToSaveExercises[i].id+this.templateData.data.id+"[]");
      let repetitionselectedRep = [];
      let repetitionselectedTime = [];
      let repetitionselectedWeight = [];
      for (let i = 0; i < repetitionRep.length; i++) {
        repetitionselectedRep.push(repetitionRep[i].value);
      }
      for (let i = 0; i < repetitionTime.length; i++) {
        repetitionselectedTime.push(repetitionTime[i].value);
      }
      for (let i = 0; i < repetitionWeight.length; i++) {
        repetitionselectedWeight.push(repetitionWeight[i].value);
      }
      const Settingrep = JSON.stringify(repetitionselectedRep);
      const Settingtime = JSON.stringify(repetitionselectedTime);
      const Settingweight = JSON.stringify(repetitionselectedWeight);
      
      //ARRAY PUSH
      arrayExerciseitems.push({
        'id': ToSaveExercises[i].id,
        'type': Settingtype,        
        'settingrep': Settingrep,        
        'settingtime': Settingtime,        
        'settingweight': Settingweight,        
        'positionoid': Positionoid,        
        'positiontext': Positiontext,        
        'positionvalue': Positionvalue,        
      });

    } //for
    
    const Exerciseitems = encodeURIComponent(JSON.stringify(arrayExerciseitems));

    let ToSaveMembers;
    if(this.saveMembersPlan.length > 0){
      ToSaveMembers = this.saveMembersPlan;
    } else {
      ToSaveMembers = this.templateData.data.MembersPlanned;
    }
    let arrayMemberitems = [];
    for (let i = 0; i < ToSaveMembers.length; i++) {
      arrayMemberitems.push({
        'id': ToSaveMembers[i].id,
        'type': '',        
      });
    } //for
    const Memberitems = encodeURIComponent(JSON.stringify(arrayMemberitems));

    let objString = '?id=' + id;

    if(Subject_de){
      objString = objString + '&Subject_de=' + Subject_de;
    } else {
      objString = objString + '&Subject_de=';
    }
    if(Subject_en){
      objString = objString + '&Subject_en=' + Subject_en;
    } else {
      objString = objString + '&Subject_en=';
    }
    if(Subject_fr){
      objString = objString + '&Subject_fr=' + Subject_fr;
    } else {
      objString = objString + '&Subject_fr=';
    }
    if(Subject_es){
      objString = objString + '&Subject_es=' + Subject_es;
    } else {
      objString = objString + '&Subject_es=';
    }
    if(Subject_it){
      objString = objString + '&Subject_it=' + Subject_it;
    } else {
      objString = objString + '&Subject_it=';
    }
    if(Description){
      objString = objString + '&Description=' + Description;
    } else {
      objString = objString + '&Description=';
    }
    if(Exerciseitems){
      objString = objString + '&Exerciseitems=' + Exerciseitems;
    } else {
      objString = objString + '&Exerciseitems=';
    }
    if(Memberitems){
      objString = objString + '&Memberitems=' + Memberitems;
    } else {
      objString = objString + '&Memberitems=';
    }
    if(DateStart){
      objString = objString + '&DateStart=' + isoDateStart;
    } else {
      objString = objString + '&DateStart=';
    }
    if(DateStop){
      objString = objString + '&DateStop=' + isoDateStop;
    } else {
      objString = objString + '&DateStop=';
    }

    console.log(objString);

    fetch(apihost+'/de/vue/exerciseplan/getexerciseplan/'+id+'/save'+objString)
      .then(handleErrors)
      .then(response => response.text())
      .then(result => {
      const data = JSON.parse(result);
      //console.log(data);
      this.fetchUser = data;
    })
    .catch(() => { throw 'Network error' });

    notify({
      message: currentUser.Translation.vueappExerciseplanFormSubmitSuccess,
      position: {
        my: 'center top',
        at: 'center top',
      },
    }, 'success', 3000);

    //UPDATE LIST-TITLE
    var elemListTitle = document.getElementById('customerexercise_'+id);
    if(elemListTitle){
      elemListTitle.innerHTML = Subject_de;
    }
    

  },    

},

};

</script>

<style>

</style>
