<template>
    <svg class="kuoni-logo" version="1.1" id="logo-kuoni" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 137 47.9" style="enable-background:new 0 0 137 47.9;" xml:space="preserve">
    <path class="st0" d="M134.8,44.5c0,0.9-0.3,1.7-0.9,2.3c-1.3,1.2-3.3,1.2-4.6,0c-0.6-0.6-1-1.5-0.9-2.3V17.1c0-0.9,0.3-1.7,0.9-2.3
    c1.3-1.2,3.3-1.2,4.6,0c0.6,0.6,0.9,1.5,0.9,2.3V44.5z"/>
    <path class="st0" d="M128.3,5.7c0-1.8,1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3S133.4,9,131.6,9l0,0C129.7,9,128.3,7.5,128.3,5.7"/>
    <path class="st0" d="M120.5,20.2c-1.1-2.1-2.8-3.8-4.9-5c-2.2-1.1-4.6-1.7-7.1-1.7c-2.4,0-4.7,0.5-6.8,1.7c-0.8,0.5-1.6,1-2.3,1.6
    c0-0.8-0.3-1.5-0.9-2.1c-1.3-1.2-3.3-1.2-4.6,0C93.3,15.3,93,16.1,93,17v27.7c0,1.8,1.5,3.2,3.3,3.2c0.8,0,1.6-0.3,2.2-0.9
    c0.6-0.6,0.9-1.4,0.9-2.3V26.1c0-1.2,0.3-2.4,1.1-3.5c0.7-1,1.7-1.9,2.9-2.4c1.3-0.6,2.7-0.9,4.1-0.9c1.5,0,3,0.3,4.4,0.9
    c1.3,0.6,2.3,1.6,3,2.8c0.8,1.5,1.2,3.3,1.1,5v16.5c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2V28.1C122.4,25.4,121.8,22.6,120.5,20.2"
    />
    <g>
    <polygon class="st0" points="24.3,13.7 24.3,13.7 24.3,13.7 	"/>
    <path class="st0" d="M27.4,42.2L15.2,29l10.9-9.8c0.7-0.6,1.2-1.5,1.2-2.4c0-0.8-0.4-1.5-0.9-2.1c-0.5-0.6-1.3-1-2.1-1
    c-0.9,0-1.8,0.4-2.4,1L6.5,29.1V3.3c0-0.9-0.3-1.7-0.9-2.4C5,0.3,4.1,0,3.2,0C2.4,0,1.5,0.3,0.9,0.9C0.3,1.6,0,2.4,0,3.3v41.3
    c0,0.9,0.3,1.7,0.9,2.4c0.6,0.6,1.4,1,2.3,0.9h0c0.9,0,1.7-0.3,2.3-0.9c0.6-0.6,0.9-1.5,0.9-2.4v-7.8l3.7-3.3l12.6,13.2
    c0.6,0.7,1.4,1.1,2.3,1.1v0c0.8,0,1.6-0.3,2.2-0.9c0.7-0.5,1.2-1.4,1.1-2.3C28.4,43.7,28,42.8,27.4,42.2z"/>
    </g>
    <path class="st1" d="M85.7,21.8c-4.7-8-14.9-10.7-22.9-6.1c-2.5,1.5-4.6,3.6-6,6.1c-1.2,2.2-2,4.6-2.1,7c-0.1,0.4-0.2,0.8-0.2,1.2
    v3.9c0,2.7-0.6,4.7-1.9,6.1c-1.5,1.4-3.6,2.2-5.7,2c-2.1,0.1-4.1-0.6-5.6-2c-1.3-1.3-1.9-3.4-1.9-6.1V17c0-0.9-0.3-1.7-0.9-2.3
    c-1.2-1.2-3.2-1.2-4.5,0C33.3,15.3,33,16.1,33,17v17c0,4.3,1.2,7.7,3.6,10.2c2.4,2.4,5.8,3.7,10.2,3.7c4.4,0,7.9-1.2,10.3-3.7
    c0.6-0.6,1.1-1.2,1.5-1.9c1.2,1.4,2.6,2.6,4.2,3.5c5.3,2.9,11.7,2.9,16.9,0c2.5-1.4,4.5-3.5,6-6C88.6,34,88.6,27.4,85.7,21.8
    M80.2,36.6c-0.9,1.6-2.1,3-3.7,4c-1.6,1-3.4,1.5-5.2,1.4c-1.9,0-3.7-0.5-5.3-1.4c-1.6-1-2.9-2.4-3.8-4c-1.8-3.7-1.8-8.1,0-11.9
    c0.9-1.6,2.2-3,3.8-4c1.6-1,3.4-1.5,5.3-1.5c1.9,0,3.7,0.5,5.2,1.5c1.6,1,2.9,2.4,3.7,4C82.1,28.5,82.1,32.9,80.2,36.6"/>
    </svg>
</template>