<template>
<svg version="1.1" id="logo-selfgym" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
y="0px" viewBox="0 0 924 144" style="enable-background:new 0 0 924 144;" xml:space="preserve">
<path class="st0" d="M200.9,2.5C199,4,197,5.6,195.1,7.1c-15.6,11.9-31.2,23.8-46.8,35.7c-1.2,0.9-2.5,1.4-4.1,1.4
c-23.6,0-47.2,0-70.9,0c-0.5,0-0.9,0-1.5,0c0.9,1.7,2.1,3.1,3.2,4.6c8.1,11,16.3,22,24.4,33.1c0.2,0.2,0.5,0.4,0.3,0.7
c-0.2,0.3-0.6,0.3-0.9,0.3c-3.6,0-7.2,0-10.7,0c-13.6,0-27.2,0.1-40.7,0.1c-0.9,0-1.6-0.2-2.2-0.9C38.6,74.2,32.5,65.9,28,56.6
C25.8,52,25,47,24.7,42c-0.2-4.5,0.1-8.9,1.2-13.3c1.3-5.4,4.1-9.9,8.2-13.7c3.1-2.9,6.3-5.7,9.8-8.2c3.6-2.6,7.6-3.9,11.9-4.3
c2.6-0.2,5.2-0.3,7.9-0.3c44.8,0,89.7,0,134.5,0c0.9,0,1.8,0,2.7,0C200.8,2.3,200.9,2.4,200.9,2.5z"/>
<path class="st0" d="M75.4,142c-23.8,0-47.6,0-71.4,0c-0.3,0-0.8,0.3-1-0.2c-0.1-0.4,0.3-0.5,0.6-0.7c9.6-7.2,19.1-14.5,28.6-21.8
c7.9-6.1,15.9-12.2,23.8-18.3c0.9-0.7,1.8-1,3-1c24,0,48.1,0,72.1,0c0.2,0,0.5,0.2,0.7-0.1c0.2-0.3-0.1-0.5-0.3-0.8
c-1.4-1.9-2.9-3.9-4.3-5.8c-7.1-9.7-14.2-19.5-21.3-29.2c-0.4-0.6-0.9-1.2-1.4-1.7c-0.2-0.2-0.3-0.4-0.2-0.7
c0.1-0.3,0.4-0.2,0.6-0.2c1.3,0,2.7,0,4,0c9,0,17.9,0.1,26.9,0c6.9-0.1,13.7-0.3,20.6-0.3c0.8,0,1.4,0.1,1.9,0.7
c7.6,8.8,14.5,18.1,19,28.9c1.7,4,1.9,8.4,2,12.6c0.2,4.7-0.2,9.3-1.7,13.8c-1.8,5.3-4.8,9.8-9.2,13.4c-3.5,2.8-6.8,6-10.8,8.2
s-8.3,3.1-12.8,3.1C121.6,142.1,98.5,142.1,75.4,142L75.4,142z"/>
<path class="st1" d="M721.1,59.1c-0.4,0-0.6,0.3-0.7,0.6c-6.8,10.7-14,21.3-21,31.9c-1.5,2.2-1.5,2.2-2.9,0.1
c-6.5-9.8-12.9-19.6-19.4-29.4c-0.6-0.9-1.2-1.9-2.1-2.8c0,1.4,0,2.6,0,3.8c0,15.8,0,31.6-0.1,47.4c0,1.6,0,1.6-1.7,1.6
c-5,0-10-0.1-15,0c-1.2,0-1.3-0.5-1.3-1.4c0-2.2,0-4.4,0-6.6c0-24.5,0-49.1-0.1-73.6c0-0.9,0.2-1.2,1.2-1.2c5.8,0,11.7,0,17.5,0
c0.7,0,1.1,0.1,1.6,0.7c6.6,10.7,13.2,21.4,19.9,32c0.4,0.6,0.8,1.3,1.2,2c0.7-1.2,1.4-2.2,2-3.2c6.4-10.2,12.7-20.4,19.1-30.6
c0.4-0.7,0.9-1,1.7-1c5.7,0,11.4,0,17.1,0c1,0,1.3,0.2,1.3,1.2c0,26.7-0.1,53.5,0,80.2c0,1.2-0.3,1.5-1.5,1.5
c-5.1-0.1-10.2-0.1-15.2,0c-1,0-1.3-0.2-1.3-1.3c0-10.6,0-21.2,0-31.7c0-6.2,0-12.5,0.1-18.7C721.2,60,721.4,59.5,721.1,59.1z"/>
<path class="st1" d="M570.4,82.5c0,5.6-0.2,11.2,0.1,16.9c0,0.7-0.1,1.2-0.7,1.6c-5.6,4.5-11.7,8-18.5,10.1c-6.9,2.2-14,2.7-21.1,2
c-6.3-0.6-12.4-2.2-17.9-5.4c-11.1-6.5-17.8-16.1-19.8-28.9c-0.9-6-1-12,0.4-17.9c3.3-13.5,11.2-23.3,23.9-29.1
c5.3-2.5,11-3.5,16.8-3.6c5.5-0.1,11.1,0.1,16.5,1.6c6.3,1.9,12,5,17.1,9.1c0.6,0.5,0.3,0.7,0,1.1c-1.2,1.4-2.3,2.8-3.5,4.2
c-2.4,2.7-4.7,5.4-6.9,8.3c-0.5,0.7-0.8,0.7-1.5,0.2c-4-3.2-8.1-6-13.2-7c-6.2-1.2-12.3-1.5-18,1.9c-8.1,4.8-12.4,12.1-13.2,21.4
c-0.5,6.5,1.1,12.5,4.9,17.9c4.1,5.9,9.9,9,16.9,9.7c6.7,0.7,13.2-0.3,19.1-4c0.8-0.5,1.1-1,1.1-2c-0.1-3-0.1-5.9,0-8.9
c0-1.3-0.4-1.5-1.5-1.5c-5.2,0.1-10.4,0-15.6,0c-1,0-1.2-0.3-1.2-1.3c0.1-4.4,0.1-8.7,0-13.1c0-1.2,0.3-1.6,1.6-1.6
c10.5,0,21.1,0,31.6,0c3.1,0,2.7-0.4,2.7,2.7C570.3,72.1,570.4,77.3,570.4,82.5z"/>
<path class="st1" d="M288.9,70.7c0-13.3,0-26.7,0-40c0-1.1,0.4-1.4,1.4-1.4c12.2,0,24.5,0,36.7,0c7.7,0,15.5,0,23.2,0
c0.8,0,1,0.2,1,1c0,4.7,0,9.5,0,14.2c0,1-0.3,1.3-1.3,1.3c-6.4,0-12.7,0-19.1,0c-7.5,0-15,0-22.5,0c-0.9,0-1.2,0.2-1.2,1.2
c0.1,4.7,0.1,9.4,0,14.1c0,1,0.3,1.2,1.2,1.2c12,0,23.9,0,35.9,0c1.7,0,1.7,0,1.7,1.7c0,4.5,0,9.1,0,13.6c0,1-0.6,1-1.3,1
c-4.1,0-8.2,0-12.4,0c-8,0-15.9,0-23.9,0c-1.2,0-1.4,0.3-1.4,1.5c0.2,4.7,0.3,9.3-0.1,14c-0.1,1.6-0.1,1.6,1.4,1.6
c13.9,0,27.8,0,41.7,0c1.2,0,1.5,0.4,1.5,1.6c-0.1,4.5-0.1,9-0.1,13.5c0,1.2-0.4,1.5-1.5,1.5c-19.9,0-39.7,0-59.6,0
c-1.6,0-1.6,0-1.6-1.7C288.9,97.3,288.9,84,288.9,70.7L288.9,70.7z"/>
<path class="st1" d="M922.2,70.8c0,13.3,0,26.7,0,40c0,1.3-0.4,1.5-1.6,1.5c-5.1-0.1-10.2-0.1-15.2,0c-1.1,0-1.4-0.3-1.4-1.4
c0-9.2,0-18.4,0-27.6c0-1.1-0.1-2.3,0-3.4c0-0.7-0.3-0.9-1-0.9c-1.6,0-3.2,0-4.7,0c-8.7,0-17.5,0-26.2,0c-1.1,0-1.4,0.2-1.4,1.4
c0,9.3,0,18.6,0,27.9c0,1-0.1,2.1-0.1,3.1c0,0.7-0.3,0.9-1,0.9c-5.4,0-10.7,0-16.1,0c-0.7,0-0.9-0.3-0.9-0.9c0-1.1,0-2.2,0-3.4
c0-25.8,0-51.6,0-77.4c0-1,0.2-1.3,1.3-1.3c5.2,0.1,10.4,0.1,15.6,0c1,0,1.2,0.3,1.2,1.2c0,10,0,20-0.1,30c0,1.1,0.2,1.5,1.4,1.5
c10.4,0,20.7,0,31.1,0.1c0.9,0,0.9-0.5,0.9-1.1c0-4.7,0-9.3,0-14c0-5.5,0-10.9-0.1-16.4c0-1,0.3-1.3,1.3-1.3
c5.4,0,10.8-0.1,16.2-0.1c0.6,0,0.8,0.1,0.8,0.8c0,0.6,0,1.2,0,1.7C922.2,44.8,922.2,57.8,922.2,70.8z"/>
<path class="st1" d="M248,28.2c10.4-0.1,20.9,2.7,30,9.7c0.9,0.6,0.9,1.1,0.3,2c-2.6,3.7-5.2,7.4-7.8,11.2c-1,1.5-1,1.5-2.4,0.5
c-5.2-3.4-10.7-6.3-17-7c-3.1-0.3-6.2-0.3-9.1,0.9c-2.6,1.1-4.3,3-4.4,5.9c-0.1,2.8,1.2,4.9,3.7,6.4c2.1,1.2,4.3,2,6.6,2.7
c4.9,1.5,9.8,2.5,14.6,4.2c4.7,1.7,9.2,3.9,13,7.3c3.6,3.3,5.5,7.4,5.9,12.1c0.4,4.1,0.2,8.2-1.1,12.2c-2.1,6.5-6.6,10.8-12.6,13.6
c-3.8,1.8-7.8,2.6-11.9,3c-14.7,1.3-27.5-3.2-38.9-12.3c-0.6-0.5-0.7-0.9-0.2-1.5c3.2-3.8,6.4-7.5,9.6-11.3c0.6-0.7,1-0.6,1.6-0.1
c5.3,4.4,11.3,7.6,18.1,8.8c4.4,0.8,8.9,1,13.1-1c2.3-1.1,3.9-2.7,4.2-5.4c0.3-3.1-0.8-5.5-3.7-7c-2.9-1.6-6.1-2.4-9.2-3.3
c-6.1-1.8-12.4-3.3-18.2-6c-6.5-3-11-7.5-12.3-14.8c-2.4-13.4,4.2-24.5,17.1-29C240.2,28.8,243.6,28.5,248,28.2z"/>
<path class="st1" d="M815.7,28.2c6.9-0.2,13.3,0.6,19.5,3.5c4.6,2.1,8.6,5,12.4,8.4c0.6,0.5,0.7,0.9,0.1,1.5
c-3.5,3.9-6.9,7.8-10.3,11.7c-0.7,0.8-1,0.8-1.8,0.1c-4.6-4.2-9.8-7.4-16.2-8.3c-10.6-1.6-20.2,3.4-24.8,13.1
c-4.7,9.9-4.1,19.6,2.1,28.7c4.1,6,10,9.1,17.2,9.6s13.5-1.7,19-6.2c1.1-0.9,2.3-1.8,3.4-2.8c0.7-0.7,1.2-0.6,1.7,0.1
c0.9,1,1.9,2,2.8,3c2.3,2.5,4.7,4.9,7.2,7.1c0.8,0.7,0.7,1.1,0,1.8c-5.9,6.1-12.7,10.9-21.1,12.6c-16,3.3-30.6,0.6-42.5-11.5
c-6.1-6.2-10-13.6-11.2-22.3c-1.7-12,0.2-23.3,7.5-33.3c5-6.7,11.5-11.3,19.3-14.3C805.1,28.8,810.4,28,815.7,28.2z"/>
<path class="st1" d="M426.3,70.6c0-13.2,0-26.5,0-39.7c0-1.6,0-1.6,1.6-1.6c19.3,0,38.7,0,58,0c0.7,0,1.4,0.1,2.1,0
c1.2-0.2,1.5,0.2,1.5,1.4c-0.1,4.8-0.1,9.6-0.1,14.4c0,1-0.3,1.2-1.3,1.2c-13.9,0-27.8,0-41.7,0c-1.2,0-1.5,0.3-1.5,1.5
c0.1,4.8,0.1,9.7,0,14.5c0,1.1,0.3,1.3,1.3,1.3c11.9,0,23.7,0,35.6,0c2.5,0,2.5,0,2.5,2.5c0,4.2,0,8.5,0,12.7c0,1.1-0.3,1.5-1.5,1.5
c-11.8-0.1-23.7,0.1-35.5-0.1c-2.9-0.1-2.5-0.3-2.5,2.4c0,9.4,0,18.7,0,28.1c0,1.1-0.3,1.6-1.5,1.6c-5.1-0.1-10.2-0.1-15.4,0
c-1.3,0-1.7-0.4-1.7-1.7c0.1-4.6,0-9.2,0-13.9C426.3,88,426.3,79.3,426.3,70.6z"/>
<path class="st1" d="M601.6,95.8c0-4.8,0-9.6,0-14.4c0-1.2-0.3-2.2-0.9-3.2c-10-15.7-19.9-31.4-29.8-47.2c-0.3-0.5-1-0.9-0.8-1.5
c0.3-0.6,1.1-0.2,1.6-0.2c6.1-0.1,12.2-0.1,18.4-0.1c0.9,0,1.4,0.5,1.8,1.1c2.1,3.5,4.1,7.1,6.2,10.6c4,6.7,8,13.3,12,20
c0.2,0.4,0.4,0.9,0.9,1c0.6,0,0.6-0.6,0.8-1c1.8-2.9,3.5-5.9,5.3-8.8c4.5-7.2,8.8-14.5,13.1-21.8c0.4-0.7,0.8-1.1,1.7-1.1
c6.1,0.1,12.2,0,18.4,0.1c0.3,0,0.8-0.3,1,0.1s-0.2,0.8-0.4,1.1c-3.3,5.2-6.6,10.4-9.9,15.6c-6.8,10.7-13.7,21.5-20.6,32.2
c-0.4,0.7-0.5,1.4-0.5,2.1c0,9,0,18,0,27c0,1.2-0.1,2.3-0.1,3.5c0,0.9-0.4,1.2-1.3,1.2c-5.3,0-10.7-0.1-16,0c-1,0-1-0.6-1-1.2
C601.6,105.9,601.6,100.9,601.6,95.8L601.6,95.8z"/>
<path class="st1" d="M359.5,70.6c0-13.4,0-26.7,0-40.1c0-0.9,0.2-1.2,1.2-1.2c5.3,0.1,10.6,0.1,15.9,0c1.3,0,1.3,0.6,1.3,1.5
c0,12.9-0.1,25.8-0.1,38.7c0,8.2,0,16.4,0,24.6c0,0.7-0.1,1.3,1,1.2c11.2-0.3,22.3-0.1,33.5-0.1c1.8,0,3.7,0.1,5.5,0
c0.9,0,1.3,0.2,1.3,1.2c0,4.8,0,9.7,0,14.5c0,1-0.4,1.3-1.4,1.3c-18.9,0-37.8,0-56.7,0c-1.4,0-1.3-0.8-1.3-1.7c0-9.8,0-19.7,0-29.5
C359.5,77.6,359.5,74.1,359.5,70.6L359.5,70.6z"/>
<path class="st0" d="M759.8,112.2c-2.8,0-5.6,0-8.4,0c-0.8,0-1.2-0.2-1.1-1.1c0-5.7,0-11.3,0-17c0-0.9,0.4-1,1.1-1
c5.4,0,10.8-0.1,16.2-0.1c2.1,0,2.1,0,2.1,2.2c0,5.2-0.1,10.4-0.1,15.6c0,1-0.3,1.4-1.4,1.4C765.4,112.2,762.6,112.2,759.8,112.2z"
/>
</svg>
</template>

