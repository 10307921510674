<template>

  <div v-if="currentUser.Role == 'Superuser' || currentUser.Role == 'Administrator' || currentUser.Role == 'Moderator'" class="content-block">

  <h2>
    <i class="dx-icon nav-icon fa-light fa-dumbbell"></i> 
    {{ title }}
  </h2>


    <div class="dx-card _responsive-paddings no-paddings">

      <DxTabPanel
        :swipe-enabled="false"
      >
        
      <DxItem 
        :title="currentUser.Translation.vueappNavMemberExerciseitems" 
        icon="fa-light fa-circle-play"
        badge=""
      >
      <template #default>
        <ExerciseItem />
      </template>
      </DxItem>

      <!-- 
      <DxItem 
        :title="currentUser.Translation.vueappNavMemberExerciseoption1" 
        icon="fa-light fa-ballot-check"
        badge=""
      >
      <template #default>
      <ExerciseOption1 />
      </template>
      </DxItem>
      -->

      <DxItem 
        :title="currentUser.Translation.vueappNavMemberExerciseoption2" 
        icon="fa-light fa-ballot-check"
        badge=""
      >
      <template #default>
      <ExerciseOption2 />
      </template>
      </DxItem>

      <DxItem 
        :title="'Positionen'" 
        icon="fa-light fa-ballot-check"
        badge=""
      >
      <template #default>
      <ExerciseOption3 />
      </template>
      </DxItem>

    </DxTabPanel>

    </div>

  </div><!-- content-block -->
  
  <div v-else class="content-block">
    Oh no 😢
  </div><!-- content-block -->

</template>

<script>

import DxTabPanel, { DxItem } from "devextreme-vue/tab-panel";

import auth from "../auth";

//PARTS
import ExerciseItem from "../parts/exercise-item";
//import ExerciseOption1 from "../parts/exercise-option1";
import ExerciseOption2 from "../parts/exercise-option2";
import ExerciseOption3 from "../parts/exercise-option3";
//import ExerciseGroup from "../parts/exercise-group";


let currentUser;

auth.getUser().then((e) => {
  if(e.data){
    //console.log(e.data);
    currentUser = e.data;
  } // e.data
}); //auth

export default {
  mounted() {

    //redirect to Member App
    if(currentUser.Role == 'Customer'){
      const currentUrl = window.location.href;
      const redirecturl = currentUrl.replace("/#/sap-settingexercise", "/#/dashboard");
      window.location.href = redirecturl;
    }

  },

  components: {
    DxTabPanel,
    DxItem,

    //PARTS
    ExerciseItem,
    //ExerciseOption1,
    ExerciseOption2,
    ExerciseOption3,
    //ExerciseGroup,
  },
  
  data() {
    return {
      title: currentUser.Translation.vueappNavMemberExercises,
      currentUser,
    };
  },
  methods: {

  },
  unmounted() {

  },
};
</script>

<style lang="scss">
</style>
