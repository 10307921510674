<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 3900 1200" style="enable-background:new 0 0 3900 1200;" xml:space="preserve">
<path class="st0" d="M1952.6,97.7c611.3,0,1222.5,0,1833.8-0.1c23.4,0,43.9,6.6,60.9,22.8c13.5,12.8,21.2,28.6,24.4,46.9
c2.1,11.9,2,23.8,2,35.8c0,267.3,0,534.6-0.1,801.9c0,20.2-3.7,39.7-16.1,56.5c-15.8,21.3-36.8,33.1-63.5,34.2c-1.7,0.1-3.3,0-5,0
c-1222.9,0-2445.7,0-3668.6,0.1c-29.5,0-52.7-11.9-69.7-35.8c-9.1-12.8-13.1-27.4-14.3-42.9c-0.1-1.7,0-3.3,0-5
c0-276.6,0-553.3,0-829.9c0-31.9,13.7-56.4,40.6-73.2c12.4-7.7,26.2-11.1,40.8-11.2c3.3,0,6.7,0,10,0
C736.1,97.7,1344.3,97.7,1952.6,97.7z"/>
<path class="st1" d="M1728.9,731.7c-23.3,0-46.6,0-69.9,0c-8.3,0-16.7-0.6-25,0.2c-8.1,0.8-12.5-3.2-15.6-9.5
c-7.1-14.6-15.5-28.4-23.2-42.6c-10.2-18.6-21.2-36.7-31.1-55.5c-8.7-16.6-18.6-32.6-27.9-49c-5.6-9.8-11-19.7-16.2-29.7
c-3.6-6.8-8.4-10.1-16.6-10.1c-35,0.4-70,0.2-105,0.2c-2,0-4-0.2-6,0.2c-4.4,0.8-7.1,3-6.9,8.1c0.3,5.6,0,11.3,0,17
c0,51.6,0,103.3,0,154.9c0,2.3,0.1,4.7,0,7c-0.2,8.1-0.7,8.7-8.4,8.8c-27,0.1-54,0.1-81,0c-7.3,0-7.6-0.6-7.9-8.2c-0.1-2,0-4,0-6
c0-155.6,0-311.2,0-466.8c0-0.7,0-1.3,0-2c0.1-11.1-1.2-10.9,10.8-10.9c87,0,173.9,0.1,260.9-0.1c5.8,0,11,1.9,16.7,2.3
c14.5,0.9,29.1,2.4,43,6.4c33.1,9.5,60.7,27.2,76.8,59c4.5,8.8,6.3,18.9,9.7,28.2c5.3,14.1,4.1,28.8,5,43.1
c1,15.4,0.6,31.2-2,46.4c-6.5,38-25.4,68.4-59.9,87.4c-8.2,4.5-17.5,7.5-26.8,10c-7,1.9-8.9,6.6-5.1,12.7c11,17.7,21,36,31.2,54.2
c9.6,17,20,33.5,29,50.9c4.7,9.1,10.7,17.5,15.8,26.4c11.6,20.1,21.6,41.1,34.8,60.2C1729.4,726.4,1730,728.2,1728.9,731.7z
M1473.5,453.7c24.6,0,49.3,0,73.9,0c11,0,21.9-2.2,32-5.3c15.3-4.7,25.9-16,30.8-31.8c4.6-14.9,4.5-30.3,3-45.4
c-1.4-13.9-7.5-26.6-19.4-34.6c-13.6-9.1-29.8-14-45.6-14.4c-50.9-1.2-101.9-0.5-152.9-0.4c-8.2,0-8.9,0.8-9,9.4
c-0.1,37.3-0.1,74.6,0,111.9c0,9.7,1.1,10.6,11.2,10.6C1422.9,453.7,1448.2,453.7,1473.5,453.7z"/>
<path class="st1" d="M446.2,731.7c-63.3,0-126.6,0-190,0c-2.3,0-4.7,0.1-7,0c-7.3-0.2-7.5-0.2-7.5-7.1c-0.1-24.7,0-49.3-0.1-74
c0-4.1,1.7-6.8,4.2-9.6c11.3-12.5,22.4-25.2,33.5-37.9c13.2-15.2,26.3-30.5,39.5-45.7c19.2-22,38.4-43.9,57.7-65.7
c23.8-26.7,46.7-54.1,70.7-80.6c22-24.2,43.1-49.2,64.4-74c2.6-3,6.3-4.9,7.8-8.9c1.8-4.6,1.1-6.1-3.7-6.5c-2.7-0.2-5.3-0.1-8-0.1
c-83,0-166,0-248.9,0c-2,0-4,0-6,0c-11-0.1-11.2-0.2-11.2-11.2c0-21.7-0.1-43.3,0-65c0-7.6,0.2-7.8,7.7-7.8c28-0.1,56,0,84,0
c101.3,0,202.6,0,303.9,0c15.7,0,14.8-2,14.5,14.1c-0.4,20-0.3,40,0,60c0.1,7-1.5,12.6-6.4,18.1c-22.3,25.4-44.1,51.2-66,76.9
c-26.9,31.5-53.7,63-80.6,94.4c-19.4,22.6-39.4,44.7-58.4,67.7c-15.4,18.6-31.7,36.4-47,55c-4.2,5.1-8.6,10-13.1,14.9
c-1.5,1.7-2.7,3.8-3.7,5.8c-1.3,2.7-0.2,4.5,2.8,4.9c2,0.3,4,0.8,5.9,0.7c84.6-1.5,169.2-0.2,253.8-0.7c1.3,0,2.7-0.1,4,0
c7.2,0.5,8.5,1.7,8.6,9.1c0.1,16.3,0,32.7,0,49c0,5.7,0.1,11.3,0.2,17c0.1,6.4-0.1,6.7-6.7,6.8c-11.3,0.2-22.7,0.1-34,0.1
C556.2,731.7,501.2,731.7,446.2,731.7z"/>
<path class="st1" d="M1181.7,413.7c0,49.3-0.1,98.7,0,148c0,27.4-3.5,54.5-12.6,80.1c-4.9,13.9-12.8,27.3-23.4,38.7
c-14.7,15.9-32.2,27.7-51.8,36.2c-17.2,7.4-35.3,12.4-54,15.1c-10.4,1.5-21,1.9-31.1,5.1c-3,0.9-5.9,0.7-8.9,0.7
c-18.7,0-37.3,0-56,0c-6,0-11.8-0.3-17.7-1.7c-10.2-2.4-20.9-2.8-31.2-4.6c-17.8-3.1-35.2-8.1-52.1-15
c-14.2-5.8-27.2-13.6-38.9-23.1c-12.2-9.8-21.9-22.1-28.5-36.9c-7.5-16.9-10.8-34.5-13.5-52.6c-2.2-15.2-2-30.5-2.5-45.8
c-2.9-102.3-0.4-204.6-1.3-306.8c-0.1-15.4,0.2-13.4,13.7-13.4c24.7-0.1,49.3-0.1,74,0c9.4,0,9.6,0.1,9.6,9.5
c0,93.7,0.2,187.3-0.2,281c0,9.9-1.1,19.9-1.1,29.9c0,14.7,1.7,29.1,5.5,43.3c7.9,29.2,27.1,47.4,55.2,55.8
c20.2,6.1,41.2,8.7,62.4,8.7c5.8,0,11.1-1.7,16.7-2.3c13.6-1.7,27.1-3.9,40.1-9.4c20.4-8.6,33-23.6,40.1-43.7
c6.9-19.5,10.1-39.6,10.1-60.4c-0.1-100-0.1-200,0-300c0-13.5-1.6-12.3,12.3-12.3c25.3,0,50.7,0,76,0c7.2,0,6.9,0.2,8.3,7.2
c0.8,4,0.7,7.9,0.7,11.9C1181.7,309.1,1181.7,361.4,1181.7,413.7z"/>
<path class="st1" d="M2015.7,579.7c0-31.7-0.1-63.3,0-95c0.1-15.6,0.3-31.3,0.7-46.9c0.1-4.3,0.8-8.6,1.3-12.9
c2.7-22.7,3.4-45.7,10.3-67.8c8.2-26.3,20.5-50.1,41-69.1c13.5-12.5,29.2-21.3,46-28.4c13.7-5.8,28-10.4,42.4-13.1
c11.8-2.2,23.6-6.3,36.2-5.1c7.1,0.6,14.7-0.5,21.7-2.2c5.3-1.3,10.5-1.5,15.7-1.5c55.7,0,111.3,0,167,0c2.7,0,5.3,0.2,8,0
c4.3-0.3,6.1,1.8,6,5.9c-0.1,3.7-0.2,7.3-0.2,11c0,17.3,0,34.7,0,52c0,11.1-0.1,11.1-11,11.2c-55,0-110,0-165,0
c-7,0-13.8,0.5-20.7,1.9c-12.5,2.5-25,4.7-36.7,10.1c-16,7.4-30,17.1-40.7,31.5c-16.9,22.9-22.2,49.1-23.6,76.6
c-0.3,6.2,2.3,9.2,8.5,9.8c3,0.3,6,0.1,9,0.1c88.6,0,177.3,0,265.9,0c2.7,0,5.3,0.1,8,0c4.7-0.1,6.5,2.1,6.5,6.7
c0,21.3,0.2,42.6,0.2,64c0,5.4-1.5,6.9-7.1,7.3c-2.7,0.2-5.3,0-8,0c-89,0-178,0-266.9,0c-2.7,0-5.3-0.2-8,0.1
c-6.5,0.5-8,2.1-8.3,8.7c-0.1,2.3,0,4.7,0,7c0,59,0,118,0,177c0,2.3-0.2,4.7,0,7c0.4,4.8-1.7,6.7-6.4,6.4c-3.3-0.2-6.7,0-10,0
c-24,0-48,0-72,0c-8.4,0-9.2-0.9-9.6-9c-0.1-2.3,0-4.7,0-7C2015.7,670.3,2015.7,625,2015.7,579.7z"/>
<path class="st1" d="M2861.1,239.7c64.6,0,129.3,0,193.9,0c2.7,0,5.3,0.1,8,0c4.7-0.3,6.6,1.9,6.6,6.5c-0.1,22.3,0,44.7,0,67
c0,4.5-1.9,6.7-6.6,6.6c-5-0.2-10,0-15,0c-41.7,0-83.3,0-125,0c-12.4,0-12.7,0.3-12.7,13c0,127,0,253.9,0,380.9
c0,18.1,0,18.1-18.2,18.1c-24,0-48,0-72,0c-11.7,0-11.8,0-11.8-11.4c0-35,0-70,0-105c0-93.3,0-186.6,0-279.9c0-2.7-0.1-5.3,0-8
c0.1-5-2.9-7.2-7.4-7.6c-2.6-0.3-5.3-0.1-8-0.1c-43,0-86,0-129,0c-2.3,0-4.7,0.1-7,0c-6.8-0.3-7.2-0.5-7.3-7.6
c-0.1-16.7-0.1-33.3-0.1-50c0-5,0-10,0-15c0-4.9,2.5-7.4,7.4-7.3c2.7,0,5.3,0,8,0C2730.5,239.7,2795.8,239.7,2861.1,239.7z"/>
<path class="st1" d="M2488.3,483.5c0-78.3,0-156.6,0-234.9c0-11.2-1.3-11,10.7-11c26.7,0,53.3,0,80,0c10.6,0,10.7,0.1,10.7,10.4
c0,42.3,0,84.6,0,126.9c0,114.3,0,228.6,0,342.8c0,16.1-0.9,13.7-13.4,13.8c-25.7,0.1-51.3,0-77,0c-10.9,0-10.9-0.1-10.9-11.2
C2488.3,641.5,2488.3,562.5,2488.3,483.5z"/>
<path class="st1" d="M1908.3,484c0,77.6,0,155.2,0,232.8c0,2,0,4,0,6c-0.2,8.7-0.2,8.8-9.1,8.9c-27.6,0.1-55.3,0.1-82.9,0
c-8.8,0-9.2-0.3-8.6-9c0.6-8.4-1.4-16.5-1.4-24.8c0.1-148.6,0.1-297.1,0.1-445.7c0-14.5,0-14.5,14-14.5c26,0,52,0,77.9,0
c11,0,10.1-0.7,10.1,10.4C1908.3,326.7,1908.3,405.3,1908.3,484z"/>
<path class="st1" d="M969.5,313.6c-12.9,0.4-24.8-2.7-36.9-3.6c-14.4-1-26.6-7.3-39-13c-6.3-2.9-12-7.3-13.7-14.8
c-2-8.8,1.9-15.4,8.7-20.5c3.4-2.6,7-4.8,11.2-6.2c12.1-4.2,24-8.4,36.8-10.7c10.9-2,21.6-2.3,32.4-3.3
c14.8-1.3,29.1,1.9,43.2,4.8c10.7,2.2,21.6,5.6,31.5,11.3c5.4,3.1,9.4,7.4,13.1,11.9c4.7,5.7,3.8,12.1-1.1,17.9
c-5.7,6.7-12.7,11.2-20.9,14.6c-13.5,5.7-27.5,8.5-42.1,9.9C984.7,312.7,976.9,313.7,969.5,313.6z"/>
<path class="st2" d="M3357.7,1149.1c-50.2,1.2-99.6,0.3-149.1,0.7c-5.5,0.1-10.7-2.2-16.5-0.8c-4,1-6.1-2.8-6.1-7.8
c0.1-16.3,0.1-32.6,0-49c0-7.2-0.1-6.7,7.4-7.5c21.3-2.3,42.5-0.5,63.8-1c13-0.3,13.9-1.8,8.9-13.6c-3.8-8.8-6.6-18.1-9.8-27.2
c-8-22.7-16.4-45.3-23.7-68.3c-5.1-16.1-11.4-31.8-16.5-47.9c-4.2-13.2-9-26.3-14-39.2c-1.6-4-1.5-6.6,1.4-9.7
c14-15,27.9-30.2,41.7-45.3c15.8-17.3,31.5-34.7,47.3-52c22.3-24.4,44.8-48.8,67.1-73.2c22.5-24.6,18.2-14.1,18.5-46.9
c0.5-66.3-0.2-132.7,0.4-199c0.1-15.1-3.8-25.9-16-35.8c-20.6-16.7-39.3-35.6-59-53.3c-11.8-10.6-24.6-20.3-35.9-31.4
c-7.8-7.7-16.1-14.8-24.1-22.3c-13-12.2-26.2-24.4-39.7-36.1c-4.3-3.7-5.1-7.3-3.1-12.2c11.7-29.1,22.9-58.5,33.5-88
c6-16.7,13-33.2,19.3-49.8c3.4-8.9,2.4-10.6-7.2-10.7c-8.7-0.1-17.3,0.1-26-0.1c-7.1-0.1-7.9-0.6-8-7.1c-0.2-18.3,0-36.7-0.1-55
c0-4.3,1.6-6,5.8-5.9c8.3,0.2,16.7,0,25,0c34.7,0,69.3,0,104,0c2.6,0,5.3-0.6,8.2,1.4c-4.1,10.6-7.1,21.6-12.1,32.1
c-4.7,9.8-7.8,20.3-11.8,30.5c-11.9,30.5-23,61.3-34.9,91.8c-5.9,15.1-10.3,30.7-17.8,45.2c-3.3,6.3-2.6,12.5,3.5,17.8
c12.8,11,25.1,22.6,37.8,33.8c25.7,22.8,51.5,45.5,77.2,68.2c13.9,12.3,27.5,24.7,41.7,36.7c3.2,2.7,4.7,5.2,4.8,9.1
c0,2.3,0,4.7,0,7c0,90-0.1,180,0.1,270c0,8-1.9,13.9-7.4,20.4c-20.1,23.3-41.7,45.3-62,68.4c-23.5,26.8-48.2,52.6-72.1,79
c-8,8.8-15.2,18.4-24.2,26.3c-4.2,3.7-4.7,8.9-3,13.8c4.8,14.1,9.9,28.1,14.8,42.1c6.2,17.8,12.4,35.5,18.5,53.4
c6,17.5,11.3,35.3,17.9,52.6c6.5,17,11.7,34.5,18,51.5c3.9,10.5,7.6,21.1,11.7,31.6C3357.3,1140.1,3358.1,1143.9,3357.7,1149.1z"
/>
<path class="st2" d="M3579.8,55c4-1.8,7.1-1.3,10-1.3c40.3,0,80.7,0,121,0c2,0,4,0.1,6,0c3.3-0.1,4.9,1.4,4.8,4.8
c-0.4,14.6,0.9,29.3-0.8,43.9c-0.5,4.6-0.6,9.3-0.2,13.9c0.4,4.1-1.2,5.4-5,5.4c-8.7-0.1-17.3,0-26,0c-3,0-5.9-0.1-8.6,1.7
c-2,1.3-3.1,2.5-2.2,4.7c4.7,12.3,9.3,24.5,14.2,36.7c5.7,14.1,10.6,28.6,16.3,42.7c8.4,20.8,15.6,42.1,24.2,62.8
c2,4.8,1.5,8-2.2,11.3c-9.3,8.4-18.5,17.1-27.7,25.6c-23.6,21.8-47.5,43.2-71.2,64.8c-20.2,18.5-39.8,37.9-61.6,54.4
c-12.3,9.4-14.9,19.7-14.8,33.9c0.6,71.3,0.5,142.7-0.1,214c-0.1,9.8,3.2,16.9,9.5,22.8c9.6,9.1,17.8,19.3,26.9,28.8
c18.1,18.9,35.7,38.4,53,58c19.3,21.9,39.3,43.1,58.9,64.6c8,8.8,15.8,17.9,24.3,26.1c4.9,4.7,5.4,9,3.1,15
c-5.9,15.7-11.3,31.5-16.9,47.4c-9.2,26.5-17.6,53.3-28,79.3c-5.4,13.5-8.9,27.6-13.5,41.4c-1.9,5.6-5,10.8-6.1,16.8
c-1.2,6.6-0.4,8.6,6.3,8.8c8,0.3,16,0.1,24,0.1c14,0,28-0.1,42,0.1c7.1,0.1,8,1.1,8.1,8.2c0.1,16.3,0.1,32.7,0,49
c-0.1,7.5-1.2,8.6-8.8,8.7c-49,0.1-98,0-147,0.1c-5.1,0-9.4-3.3-15.2-2c1.8-12,6-22.1,9.9-32.1c4-10.5,7.4-21.2,11.3-31.6
c7.6-20.1,14.4-40.5,21.4-60.9c5-14.7,9.6-29.6,14.7-44.4c6.2-17.8,12.7-35.5,19-53.2c2.1-5.9,4-11.9,6.1-17.9
c2.6-7.1,0.3-12.9-4.5-18.2c-11.1-12.1-22.3-24.2-33.3-36.4c-15-16.6-29.9-33.4-44.9-50c-11.8-13-23.7-26-35.5-38.9
c-14.5-15.9-29-31.9-43.5-47.8c-4-4.4-5.7-9.2-5.7-15.3c0.2-90.7,0.3-181.3-0.1-272c-0.1-12.1,5.1-19.3,13.8-26.6
c23-19.3,45.3-39.5,67.8-59.4c19.7-17.5,39.1-35.3,59.1-52.5c6.8-5.8,13.5-11.7,20.1-17.7c7.7-7,7.7-8.5,3.5-18
c-7-15.8-12.3-32.3-18-48.6c-3.1-8.7-6.9-17.2-10.3-25.8c-6.9-17.9-13.6-35.8-20.7-53.6c-6.7-16.8-12.3-34.1-19.8-50.6
C3583.8,69.5,3582.2,62.3,3579.8,55z"/>
<path class="st2" d="M3467.1,355.7c-50.7,0.4-89-39.6-88.2-87.5c1-57.4,39.6-86.2,90.6-90.5c48.5,4.8,84.4,38.3,86.8,87.7
C3558.6,314.2,3515.7,357.4,3467.1,355.7z M3443.7,267.3c0,12.2,11.9,25.9,22.8,26.2c11.5,0.3,24.5-12.4,24.5-24.4
c0-18-10-25.4-24.8-25.5C3452.9,243.6,3443.7,253.3,3443.7,267.3z"/>
<path class="st3" d="M2481.8,901.2c-2.3-29.8,21-54.9,46.2-59.8c34.9-6.7,62.9,9.8,73,42.6c4.6,14.9,3,31.1-6.5,45
c-5.2,7.7-5.5,7.6,1.9,14.8c11.7,11.4,11.8,11.3-3,17.6c-7.8,3.3-8.1,3.6-14.1-3.1c-4.3-4.7-8.4-6-14.7-3.6
c-14,5.2-28.9,5.6-42.8,0.8c-14.5-5.1-26.6-14.2-33.8-28.8C2483.8,918.2,2480,909.8,2481.8,901.2z M2508.3,898.7
c-1.8,18.1,15.8,37,33.3,35.5c2.6-0.2,5.9,1,7.4-1.5c2.2-3.6-2.5-4.8-3.5-7.4c-0.6-1.5-2.2-2.5-3-3.9c-1.1-1.8-1.2-4.6,0.3-5.5
c4.4-2.8,9.2-5.2,14.1-7c3.8-1.4,4.4,3.1,6.6,4.8c5.9,4.4,8.6,3.1,11.1-4.3c2.8-8.1,2.1-15.9-1.1-23.2
c-4.9-11.1-13.1-19.8-25.5-21.6C2523.1,860.9,2507.3,881.9,2508.3,898.7z"/>
<path class="st3" d="M1298.3,898.8c2.6,30.8-26.1,59.6-59.3,59.7c-30.7,0.1-62.2-21.1-62.2-61.7c0-28.1,27-56.5,61.1-57
C1271.4,839.2,1301.4,871.2,1298.3,898.8z M1238.8,864.3c-18.9-2.2-35,17.5-34.9,35.3c0.1,17.2,14.7,35,34.4,35
c16.9,0,33.7-15.7,34-35.6C1272.6,881,1256.3,864.2,1238.8,864.3z"/>
<path class="st3" d="M1411.2,897.6c4-3.3,4.5-7.4,5.8-11.1c4.6-13.5,7.3-27.5,12.1-40.9c1.6-4.4,3.8-5.8,7.9-5.3
c3.3,0.4,6.6,1,9.8,1.5c8.1,1.4,9,2.4,6.1,10.6c-8.2,23.7-16.8,47.4-25.1,71.1c-3,8.4-5.6,17-8.5,25.4c-0.7,2.1-1.2,5.1-4.2,4.7
c-2.2-0.2-2.3-2.9-3.1-4.6c-8.1-16-15.4-32.4-22.6-48.7c-1.4-3.2-2.7-6.8-6.5-8.9c-8.4,13.3-14.1,27.8-21.2,41.6
c-3.1,5.9-6,11.9-9,17.8c-0.6,1.1-1.1,2.6-2.6,2.6c-2,0.1-2.9-1.4-3.5-3c-2.1-5.9-4.2-11.9-6.1-17.9
c-7.6-23.6-16.4-46.9-23.3-70.8c-1.1-4-3.6-7.7-4.4-12c-0.8-4.3,0.2-6.7,5.1-7.7c17.9-3.9,19.7-2.8,24,15.4
c2.7,11.3,5.7,22.5,8.8,33.6c0.6,2.2,1.6,4.4,4.9,5.6c8.9-17.5,17.8-35.1,27.8-54.7C1393.7,861.5,1400.7,880,1411.2,897.6z"/>
<path class="st3" d="M3065.7,897.1c0,16,0.1,31.9,0,47.9c-0.1,9.6-1.5,11-10.9,11.2c-15.9,0.3-15.8,0.3-15.1-14.8
c0.3-7.3,0.2-14.6,0-21.9c-0.3-9-1.8-10.1-10.6-10c-6.6,0.1-13.2,0.5-19.9-0.6c-7-1.2-9.2,1.2-9.4,8.9c-0.3,10,0,19.9-0.3,29.9
c-0.2,5.8-2.6,7.7-7.4,8.5c-16.9,2.7-19.5,0.6-19.6-16.8c-0.1-28.3-0.1-56.5,0-84.8c0-11,0.4-11.3,11.4-13.1
c2.6-0.4,5.2-1.1,7.8-1.1c6.3,0,7.9,1.2,7.9,7.4c0.1,6.9,0.9,14-0.7,20.8c-0.7,3-0.3,5.8,0.4,8.8c1.7,6.5,4.1,8.4,10.9,7.5
c5.6-0.8,11-1,16.7,0.5c7.2,1.9,11.9-2.2,11.9-9.6c0-7.3-0.5-14.6-0.4-21.9c0.2-9.6,1-10.3,10.3-12.2c1.3-0.3,2.6-0.8,3.9-1
c11.2-2,13.1-0.5,13.1,10.6C3065.7,866.5,3065.7,881.8,3065.7,897.1z"/>
<path class="st3" d="M547.5,904.6c0.8-12.1,1.7-21.9,2.1-31.7c0.3-8,0.2-16,0-23.9c-0.1-4.5,1.7-6.3,6.2-7.2
c19.2-3.8,19.8-3.5,19.8,15.6c0,28.6,0,57.2,0,85.8c0,2,0.1,4,0,6c-0.3,5-2.2,6.1-6,3.1c-6.6-5.1-12.8-10.6-19-16.1
c-14-12.2-27.8-24.5-41.9-36.6c-2.4-2.1-5-5.8-8.3-4.3c-3.4,1.6-2,6-2,9.2c-0.1,13.6,0,27.3-0.1,40.9c-0.1,9.9-0.8,10.6-11,10.6
c-17.1-0.1-14.9,1.8-14.9-14.8c-0.1-29.3-0.1-58.6,0-87.8c0-3.2-1.1-6.7,2.4-10.5C498.6,863.1,522.3,883.2,547.5,904.6z"/>
<path class="st3" d="M2720.3,884.3c0,10.6-0.5,21.3,0.1,31.9c1.2,23.3-20.1,42-41.3,41.9c-8.2,0-16.4-0.3-24.7-1.5
c-17.5-2.4-28.8-20.9-28.8-35.4c0-18.6,0-37.3,0-55.9c0-5.7,0.2-11.3,0-17c-0.2-4.7,1.8-6.4,6.3-6.8c25.2-2.6,20.7-4.6,21.6,18.6
c0.7,18.3,0.3,36.6,0.1,54.9c-0.1,8,2.2,14.5,9.6,18.2c6.1,3,20,0.7,24.3-4.3c5.2-6.1,6-13.9,6.1-21.5c0.2-17.3,0.1-34.6,0.1-51.9
c0-2.7,0-5.3,0.3-8c0.4-2.9,1.9-5,5.1-5.3c0.3,0,0.7-0.1,1-0.1c19-4.9,20.3-3.9,20.3,15.1C2720.3,866.3,2720.3,875.3,2720.3,884.3
z"/>
<path class="st3" d="M1589.7,905.7c0,14.6,0,27.1,0,39.7c0,9.7-0.9,10.7-11,10.7c-17,0-14.9,1.8-14.9-14.8
c-0.1-29.3-0.1-58.5,0-87.8c0-10.9,0.3-11.3,11.2-11.1c12.9,0.3,25.8-1.4,38.6,1.9c12.7,3.3,22.7,9.7,25.9,23.1
c3.2,13.6,0.6,25.7-11.1,34.8c-6.1,4.8-6.2,5.5-1.6,12.1c6.7,9.5,13.7,18.7,20.5,28.1c4.4,6,4,7.7-3.4,10.2c-22,7.4-15.4,9-28-9.8
c-6.2-9.3-11.9-19-17.8-28.6C1596.3,911.4,1594.5,908.7,1589.7,905.7z M1589.7,881.9C1589.7,881.9,1589.8,881.9,1589.7,881.9
c0.1,4.3,0,8.7,0.1,13c0.1,2.6,1.5,4.4,4.2,4.7c10.2,1.1,19.4-6.6,19.9-16.6c0.5-10.7-6.7-18.4-17.5-18.6
c-4.4-0.1-6.9,1.8-6.7,6.6C1589.8,874.6,1589.7,878.2,1589.7,881.9z"/>
<path class="st3" d="M1078.3,898.9c0-15.6-0.1-31.3,0-46.9c0.1-8.8,0.9-9.6,9.8-9.7c11-0.1,22-0.6,33,0.1
c14.3,0.9,27.6,4.5,34.6,19c9.1,18.8,1.6,43.9-24.1,50.7c-5.8,1.5-11.6,2.2-17.6,2.1c-7.2-0.1-8,0.8-8.2,8.1
c-0.3,8.6,0.1,17.3-0.1,26c-0.1,6.6-1.1,7.5-7.5,7.8c-4.3,0.2-8.7-0.1-13,0.1c-5.4,0.3-6.9-2.5-6.8-7.3
C1078.4,932.2,1078.4,915.6,1078.3,898.9z M1105.7,878.3c0,1,0,2,0,3c0.1,10.4,1.9,12,12.1,11c2.4-0.2,4.4-1.3,6.5-2.3
c6.2-3,8-6.5,7.4-13.6c-0.5-5.5-3.7-8.9-10-10.5C1107.2,862.1,1105.7,863.3,1105.7,878.3z"/>
<path class="st3" d="M2867.1,945.2c3.1-4.3,5.8-8.5,8.8-12.3c3.9-4.9,6.7-5.8,11.9-2.3c6.4,4.3,13.3,3.7,20.2,3.5
c5.3-0.1,10.6-4.4,11.4-9.2c0.8-5.1-2.3-8-6.1-10.4c-6.5-4.2-14.3-5-21.3-8.1c-6.3-2.8-12.2-6.3-16.8-11.3
c-11-12.1-9-33.9,3.7-44.8c12.4-10.7,27.1-11.3,42.2-9c6.4,1,12.9,2.8,18,7.6c4,3.9,4.3,5.7,1,10.1c-1.6,2.1-3.2,4.2-5.1,6.1
c-2.8,2.7-5.7,3.9-9.8,1.6c-4.6-2.6-9.4-4.8-15.1-4.3c-6.3,0.5-12.4,4.1-13.4,8.5c-1.1,4.8,2.5,8.6,9.4,12c6.3,3,13,4.9,19.5,7.1
c14.7,5,22.5,16.3,22.6,31.4c0.1,15.2-6.4,27-20.9,32.9c-17.9,7.3-35.6,5.4-53-2.2c-1.5-0.7-2.8-1.7-4-2.8
C2869.1,948,2868.2,946.6,2867.1,945.2z"/>
<path class="st3" d="M837.1,958.8c-12.7-0.1-23.8-1.9-33.7-7.8c-6.3-3.7-6.5-4.8-2.5-10.7c0.4-0.5,0.7-1.1,1.1-1.7
c7.9-11,7.9-10.9,20.4-6.3c7.1,2.6,14.2,3.1,21.1-0.4c8-4.1,8.6-12.1,1-17.1c-4.4-2.9-9.4-5.4-14.4-6.4c-6.4-1.2-12-4.1-17.3-7.1
c-20.6-11.5-18.6-41.3-0.8-53.5c16.8-11.4,40-10.1,55.5-0.8c7.1,4.3,7,4.5,2.5,11.2c-1.3,1.9-2.6,3.9-4,5.7
c-3.1,3.9-6.6,5.4-11.1,2.1c-5.1-3.7-10.9-4.5-17-3.9c-5.9,0.6-9.5,3.6-10.7,8.7c-0.9,4.2,1.3,8.4,6.7,10.7
c8.2,3.5,16.6,6.5,24.8,9.8c15.8,6.4,20.8,19.6,19.5,33.2c-1.5,15.4-8.8,24.9-24,31C848.3,958.2,841.9,958,837.1,958.8z"/>
<path class="st3" d="M729.6,958.2c-9,1.1-18.4-2-27.5-6.2c-7.1-3.3-7.8-6.7-3.1-13.1c0.4-0.5,0.9-1,1.3-1.5c7.6-10,7.5-9.8,18.9-5
c7.6,3.2,15.5,3.7,22.8-1c7.4-4.8,7.3-12.1-0.5-16.4c-3.4-1.9-6.8-4.1-10.8-4.9c-3.5-0.7-6.9-2.3-10.3-3.5
c-18.9-6.6-25.7-18.8-24.7-34c0.9-14.4,10.2-26.2,26.7-30.7c15-4,29.6-2.4,43.2,5.5c5,2.9,5.4,6.6,1.6,11.4
c-1.4,1.8-2.7,3.9-4.3,5.4c-3.3,3.1-6.3,4.8-11.3,1.8c-6.6-3.9-14.1-6.2-21.7-1.7c-7.3,4.3-7.7,12.7-0.2,16.5
c4.3,2.2,8.6,4.4,13.5,5.8c10.4,3,21.1,6.1,27.4,16.4c6.7,11.1,7.2,22.5,1.8,34.1c-6.9,15.1-19.8,20.4-35.4,21
C734.9,958.2,732.9,958.2,729.6,958.2z"/>
<path class="st3" d="M2413.3,958.5c-7.4,0.4-17.6-1.9-27.1-7.5c-5.5-3.3-5.9-5.3-2.1-10.9c0.9-1.4,1.6-2.9,2.6-4.2
c6.1-8.1,6.2-8.2,16-4c6.1,2.6,12.3,2.6,18.6,2.2c4.8-0.3,11.5-5.7,12-9.3c0.6-3.8-3.9-10.1-9.1-12.1c-7.1-2.7-14.5-4.7-21.5-7.6
c-15.6-6.4-21.5-17.8-21.3-30.4c0.2-16.2,9.8-27,23.7-32.1c15.3-5.5,30.7-3.2,45.2,3.9c6.9,3.4,7.2,6.2,2.4,12.4
c-0.4,0.5-0.9,1-1.3,1.5c-7,8.9-7.1,9-16.8,4c-6-3-11.9-3.3-18.1-0.6c-4,1.8-7,4.7-6.4,9.1c0.6,4.3,2.7,7.8,7.7,9.3
c8.2,2.5,16.3,5.7,24.1,9.1c12.7,5.5,19.7,15.1,19.6,29.3c-0.1,13.9-5.5,24.8-18,31.9C2435.2,957.4,2426.2,958.7,2413.3,958.5z"/>
<path class="st3" d="M1723.7,900.5c0-16-0.1-31.9,0-47.9c0.1-9,1.4-10.2,10.6-10.3c10-0.1,20-0.2,30,0c10.1,0.2,19.5,2.8,28.1,8.3
c6.3,4,9.6,10.8,10.8,17.4c3.9,21.9-5.3,39.4-28.5,44.4c-5.2,1.1-10.3,1.9-15.7,1.9c-7.4,0.1-8.4,1.2-8.6,8.9
c-0.2,8.3,0.1,16.6-0.1,25c-0.1,6.5-1,7.3-7.5,8c-18.2,2-19,1.3-19.1-16.6C1723.6,926.5,1723.7,913.5,1723.7,900.5z M1750.3,879.1
c0.8,3.4-2.1,8.9,2,11.5c5,3.2,10.9,1.8,16.4-0.1c2.3-0.8,3.9-2.4,5.7-3.8c1.6-1.3,2.2-2.8,2.5-5c1.3-9.6-3.9-16.3-14.1-17.4
c-9.6-1.1-12.4,1.4-12.5,11.1C1750.3,876.4,1750.3,877.4,1750.3,879.1z"/>
<path class="st3" d="M1997.1,957.9c-4,0.5-6-0.7-7.4-3.5c-2.3-4.7-5.2-9.2-7.3-14c-2-4.6-5.2-6.3-10.2-6.2
c-10.3,0.2-20.7,0.4-31,0c-5.2-0.2-8.1,2.1-10.2,6.3c-1.8,3.6-3.1,7.3-4.9,10.9c-2.9,6-4.8,6.8-11.6,5.4
c-3.9-0.8-7.8-1.2-11.6-2.2c-4.7-1.2-5.5-2.9-3.5-6.8c2.5-4.7,4.7-9.5,7.5-14c11.2-18,19.6-37.5,30.1-55.8
c5.6-9.7,10.9-19.7,16.4-29.4c3.1-5.4,4.5-5.5,7.7,0c5.4,9.1,10.4,18.4,15.4,27.7c12.5,23,24.8,46,37.3,69c0.6,1.2,1.6,2.2,2,3.4
c1.2,3.3,0.5,5.7-3.3,6.6C2007.2,956.4,2001.4,956.2,1997.1,957.9z M1955.5,914.2c4.2,0,7.5,0.2,10.8,0c5.3-0.3,5.4-0.8,4-5.9
c-1.7-6.2-5.9-11-8.7-16.6c-3.6-7.1-4.5-6.9-9,0.3c-1.7,2.8-4.1,5.3-4.8,8.6c-1,4.5-7.8,7.8-4.6,12.2
C1945.8,916.4,1951.9,913.6,1955.5,914.2z"/>
<path class="st3" d="M1472.3,898.2c0-12.3,0-24.6,0-37c0-2-0.1-4,0-6c0.7-12.9,0.7-13,13.7-13c14.7,0,29.3,0.1,44-0.1
c4.9-0.1,7.1,1.5,7.8,6.6c2,16.7,6,15.5-12.9,15.4c-5.7,0-11.3-0.1-17,0c-7.1,0.2-7.7,0.8-8.9,7.8c-0.2,1.3-0.6,2.6-0.7,3.9
c-0.7,9.2,1.1,11.3,10.3,11.5c5,0.1,10,0.1,15,0c2.6,0,5.2,0.9,5.3,3.4c0.3,4.8-0.2,9.7-0.6,14.5c-0.2,2-2,2.1-3.6,2.8
c-5.7,2.4-11.7,2-17.6,1.8c-6.2-0.2-7.8,0.9-8.7,6.5c-0.3,1.6-0.1,3.3-0.2,5c-0.1,11.5,0.8,12.5,12.4,12.6c8.3,0,16.7-0.1,25,0.1
c5.9,0.1,6.9,1.5,6.4,7.3c-0.4,4.4-2.7,8.1-4.4,12.1c-1.2,2.9-3.7,2.5-5.9,2.5c-17.7,0.1-35.3-0.1-53,0.1c-3.9,0-5-1.5-5.3-5.2
c-0.6-5.9-1.3-11.8-1.3-17.8C1472.5,921.5,1472.3,909.8,1472.3,898.2z"/>
<path class="st3" d="M2734.9,951.9c6.3-11.8,12.5-23.7,18.9-35.6c11.5-21.6,23-43.1,34.8-64.5c1.4-2.6,1.3-7.4,5.8-7.1
c3.9,0.3,4.5,4.6,6.1,7.3c7.3,12.6,14.4,25.3,21.3,38.1C2832,909,2842,928,2852,947c2.9,5.4,2.3,6.5-3.6,8.2
c-5.4,1.6-11.2,0.6-16.6,2.6c-2.7,1-4.8-1.5-5.9-4.2c-2.5-6.5-4.3-14.5-9.6-18.1c-5.8-3.9-14.2-1-21.5-1.3c-3.3-0.2-6.6,0-10,0
c-14.4,0-15.1,0.4-20.6,13.6c-0.8,1.8-1.2,3.8-2,5.6c-1.4,2.9-4.3,5.4-7.1,4.3C2748.8,955.4,2741.2,957.5,2734.9,951.9z
M2794.5,885.8c-7.4,6.3-9.1,14.5-13.5,21.1c-3.1,4.6-1.2,7.2,4.7,7.2c7.5,0,15.1,0.9,23.5-0.8
C2806.2,902.9,2801.1,894.3,2794.5,885.8z"/>
<path class="st3" d="M603.7,899.9c0-15.6-0.1-31.2,0-46.9c0.1-10.1,0.8-10.7,10.8-10.7c15-0.1,29.9,0.2,44.9-0.1
c5.6-0.1,8.2,1.6,9.5,7.4c3.4,15.3,5,14.7-11.2,14.6c-6,0-12-0.2-18,0.1c-7.7,0.4-9.2,2.1-9.4,9.7c-0.1,2.6,0.1,5.3,0.5,7.9
c0.6,3.7,2.8,5.7,6.9,5.7c5.7-0.1,11.3,0,17,0.1c3.5,0,5.7,1.5,5.5,5.4c0,1,0,2,0,3c0.4,13.8-2.5,16.6-16.3,13.8
c-5-1-9-1.6-12.2,2.7c-2.6,3.5-2,16.2,0.2,19.3c1.9,2.7,4.6,2.3,7.2,2.3c9,0.1,18-0.1,26.9,0.1c8.1,0.1,9.2,1.7,6.9,9.6
c-0.4,1.3-0.9,2.5-1.3,3.7c-2.8,8.5-2.8,8.6-11.8,8.6c-16.3,0-32.6,0.1-48.9,0c-7-0.1-7.3-0.4-7.4-7.4
C603.6,932.5,603.7,916.2,603.7,899.9z"/>
<path class="st3" d="M2113.7,899.6c0-16.3-0.1-32.6,0-48.9c0.1-8.2,0.3-8.3,8.5-8.4c16.3-0.1,32.6,0.2,48.9-0.2
c5.2-0.1,7.2,2,7.9,6.6c2.7,18.3,3,15.3-12.3,15.5c-6.3,0.1-12.7-0.2-19,0.2c-6,0.4-7.1,1.9-7.4,8.2c-0.7,14.6-0.3,15,14.5,15
c0.7,0,1.3,0,2,0c13.9,0,14.6,0.8,13.4,14.7c-0.5,6.1-1.8,7.6-7.8,7.9c-5,0.2-10,0.2-15,0c-5-0.2-7.3,1.5-7.2,6.8
c0.1,20.1-0.9,17,16,17.2c7,0.1,14-0.1,21,0.1c7,0.2,7.7,1.3,6,7.6c-0.5,1.9-1.1,3.8-1.6,5.7c-2.1,6.8-4.2,8.5-11.2,8.6
c-16.6,0.1-33.3-0.1-49.9,0.1c-5.1,0.1-7-1.6-6.9-6.8C2113.8,932.8,2113.7,916.2,2113.7,899.6z"/>
<path class="st3" d="M239.7,900.2c0-15.6-0.1-31.3,0-46.9c0.1-10.5,0.6-11,10.6-11c13.7-0.1,27.3-0.1,41,0c7.4,0,8.5,1,10.3,7.9
c0.7,2.6,0.8,5.2,1.4,7.8c1.4,7,0.8,7.9-6.6,8.1c-7.3,0.2-14.7,0.4-22-0.1c-5.5-0.4-6.9,2.6-7.6,6.9c-0.2,0.9-0.1,2,0.1,2.9
c1.8,5.6-3.2,12.7,2.4,16.9c4.6,3.4,11,1.1,16.6,1.4c8.4,0.4,9.6,1.6,9.7,11.2c0.1,9.5-0.8,10.6-9.9,10.8c-4,0.1-8,0.1-12,0
c-4-0.1-5.8,1.3-6.3,5.7c-0.8,8.6-1.3,17.2-1.3,25.8c0,7.7-0.8,8.3-8.7,8.5c-3.7,0.1-7.3-0.2-11,0.1c-5.3,0.4-7-2.1-6.9-7.1
C239.8,932.8,239.7,916.5,239.7,900.2z"/>
<path class="st3" d="M398.3,911.1c0-12.3,0.1-24.6,0-37c-0.1-7.5-0.4-7.7-8.1-7.9c-5.3-0.1-10.7,0-16-0.1c-3.6,0-4.6-2-3.9-5.2
c0.2-1,0.6-1.9,0.9-2.9c5.6-18,4.2-15.8,20.9-15.8c16.7,0,33.3-0.1,50,0c9.9,0,11.7,1.8,11.6,11.6c0,2.3-0.5,4.6-0.7,6.9
c-0.4,3.9-2.1,5.8-6.4,5.5c-4.6-0.3-9.3,0.2-14-0.1c-5.3-0.3-7.2,2.3-7.1,7.2c0.1,3.6,0.2,7.2,0.1,10.8
c-0.3,21.3,0.9,42.5-0.6,63.8c-0.4,5.9-2.9,7.4-7.6,8.1c-0.7,0.1-1.3,0-2,0c-19.2-0.1-16.8,1.9-17-16.1
C398.3,930.4,398.3,920.8,398.3,911.1z"/>
<path class="st3" d="M2010.9,865.3c3.4-23,3.4-23,25.3-23c15.6,0,31.2-0.1,46.9,0c9.7,0,10.4,0.7,10.5,9.9
c0.2,14,2.1,14.1-13.8,14c-14.1-0.1-14.1,0-14.1,14c0,22.3,0,44.5,0,66.8c0,8.9-0.1,9.1-8.8,9.1c-20-0.1-17,2.4-17.1-16.6
c-0.2-19.6,0-39.2,0-58.8c0-2.3,0.2-4.7-0.1-7c-0.6-6-2-7.3-8.1-7.5c-3.3-0.1-6.7,0.1-10-0.1
C2018.2,866.1,2014.9,866.8,2010.9,865.3z"/>
<path class="st3" d="M1853.7,887.2c0,12.3-0.1,24.6,0,36.9c0.1,7.6,0.5,7.9,7.9,8.1c7.7,0.2,15.3,0.2,23,0c4-0.1,5.3,1.8,5.1,5.5
c-0.3,5.8-2.7,10.9-5.3,15.9c-1.4,2.6-3.7,2.5-6,2.5c-15,0-30,0.2-44.9-0.1c-6.2-0.1-6.8-1.5-6.3-8.2c0.9-11.6,1.3-23.2-0.3-34.8
c-0.4-2.9-0.6-6-0.3-8.9c1.8-18.5,2.1-37.1,0.2-55.6c-0.4-3.6,1.5-5.9,4.8-6.5c4.9-0.7,9.7-2.2,14.8-1.9c7.2,0.3,8,0.7,8.1,8
c0.2,13,0.1,25.9,0.1,38.9C1854.1,887.2,1853.9,887.2,1853.7,887.2z"/>
<path class="st3" d="M350.3,898.3c0,15.9,0.1,31.9,0,47.8c-0.1,8.6-1.3,10-9.8,10c-18,0-16.1,1.9-16.2-15.8c0-28.9,0-57.8,0-86.7
c0-10.7,0.1-10.7,10.4-12.3c1.3-0.2,2.6-0.8,3.9-0.9c10.4-1.3,11.7-0.2,11.7,10C350.4,866.4,350.3,882.3,350.3,898.3z"/>
<path class="st3" d="M2280.9,924.2c-4.6,0-9.3,0-13.9,0c-4.9,0-7.3-2.6-7.2-7.4c0-2.6-0.1-5.4,0.5-7.9c0.9-3.7,3-7.4,7.4-6.3
c8.8,2.2,17.7,1.3,26.6,1.6c6.5,0.2,7.1,0.9,7.5,7.6c0.1,1,0,2,0,3c-0.1,8.4-0.8,9.1-8.9,9.4c-0.3,0-0.7,0-1,0
C2288.1,924.2,2284.5,924.2,2280.9,924.2C2280.9,924.2,2280.9,924.2,2280.9,924.2z"/>
<path class="st3" d="M977.1,904.2c4.7,0,9.3,0.1,14,0c4.8-0.2,6.6,2.2,6.5,6.6c0,2-0.1,4,0,6c0.2,4.9-1.9,7.4-7,7.4
c-9.3-0.1-18.6-0.1-28,0c-4.6,0.1-6.7-2.1-6.8-6.6c-0.1-2.7-0.1-5.3-0.1-8c-0.1-3.7,1.6-5.5,5.4-5.5
C966.5,904.3,971.8,904.3,977.1,904.2C977.1,904.3,977.1,904.3,977.1,904.2z"/>
</svg>
</template>
