<template>
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
viewBox="0 0 659.1 282.1" style="enable-background:new 0 0 659.1 282.1;" xml:space="preserve">
<path class="st0" d="M658.8,112c-1.2-1.2-2.2-2.4-4.3-2.4c-156.5,0.1-313.1,0.1-469.6,0.1c-0.9,0-1.7,0-2.6,0c-0.3,0-0.7,0-0.9-0.2
c-1.2-1.2-2.8-2.1-2.3-4.2c0.2-0.7,0-1.4-0.8-1.9c-0.9-0.5-1.1-1.4-1.2-2.5c-0.1-1.3,0.7-2.2,1.1-3.3c0.8-2.2,2.3-4,3.6-5.9
c0.9-1.3,1.7-2.7,2.4-4.1c1.3-2.3,2.1-4.8,4.1-6.7c1.1-1,1.4-2.7,2.7-3.7c0.1,0,0.1-0.1,0.1-0.2c0.3-1.7,1.8-2.7,2.6-4.2
c0.9-1.8,2.7-2.9,3.8-4.7c1.4-2.3,4.1-3.8,5.3-6.4c5.1-5.1,10.2-10.2,15.3-15.3c0.5-0.5,1.1-1.1,1.7-1.4c1.8-0.9,3.4-2,4.8-3.3
c1.3-1.1,2.6-2.1,4.1-2.7c1.4-0.5,2.2-1.4,3.1-2.4c0.6-0.7,0.6-1.7,0-2.5c-1.6-2.1-4-3.5-6.2-4.8c-2.1-1.3-3.6-3.1-5.8-4.2
c-0.7-0.3-1.2-0.9-2-1.2c-0.9-0.4-2-0.7-2.7-1.4c-1.3-1.3-3.2-1.7-4.4-3.2c0-0.1-0.1-0.1-0.2-0.1c-2.5-1.2-5-2.6-7.6-3.6
c-1.8-0.7-2.9-3.1-5.3-2.5c-0.1,0-0.2,0-0.2-0.1c-1.4-1.3-3.3-1.8-5-2.7c-1.1-0.6-2.2-1.6-3.8-1.2c-0.1,0-0.4-0.1-0.4-0.2
C188,7.5,187,8.1,186.3,8c-0.4-0.1-1.1,0.2-1.1-0.2c-0.2-1.1-1-0.7-1.7-0.8c-0.5-0.1-1.2,0.2-1.4-0.3c-0.2-1-0.9-0.6-1.4-0.7
c-0.5-0.1-1.4,0.2-1.5-0.2c-0.3-1.2-1.2-0.7-1.8-0.8c-0.4-0.1-1.2,0.2-1.2-0.2c-0.2-1.1-0.9-0.7-1.5-0.7c-0.9,0-1.8,0-2.7,0
c-0.3,0-0.8,0.1-0.8-0.3c0-0.8-0.6-0.6-1.1-0.7c-0.7,0-1.5,0-2.2,0c-0.2,0-0.6,0.1-0.6-0.2c-0.1-1.1-0.9-0.7-1.5-0.8
c-0.9,0-1.8,0-2.7,0c-0.3,0-0.8,0.1-0.8-0.3C162,0.9,161.5,1,161,1c-2.2,0-4.5,0-6.7,0c-0.5,0-1.2,0.2-1.3-0.7
c0-0.4-0.5-0.3-0.8-0.3C145,0,138,0,130.9,0c-0.3,0-0.8-0.2-0.8,0.3c0,0.8-0.6,0.6-1.1,0.6c-3,0-5.9,0-8.9,0c-0.4,0-1-0.2-1,0.2
c-0.1,1.2-1,0.7-1.6,0.8c-0.9,0.1-1.8,0-2.7,0c-0.3,0-0.8-0.1-0.8,0.4c0,0.8-0.5,0.6-1,0.6c-0.7,0-1.5,0-2.2,0
c-0.3,0-0.7-0.1-0.7,0.2c0,1.1-0.8,0.7-1.3,0.8c-0.7,0-1.5,0-2.2,0c-0.6,0.1-1.5-0.4-1.6,0.8c0,0.3-0.5,0.2-0.8,0.2
c-0.8,0-1.6,0-2.4,0c-0.2,0-0.7,0.1-0.7,0.1c0,1.2-0.9,0.7-1.5,0.9C99,6.1,98,5.5,98,6.8C98,6.9,97.6,7,97.4,7
c-1.6-0.5-2.7,0.8-3.9,1.3c-0.8,0.4-1.8,0.4-2.4,1.2c-0.3,0.5-0.7,0.6-1.1,0.5c-1.5-0.5-2.3,1.5-3.7,1.1c-0.4,1.4-1.5,0.8-2.3,0.9
c-0.3,0-0.6,0.1-0.8,0.3C82.9,13,82.4,13,81.9,13c-0.2,0-0.6,0-0.7,0.2c-0.2,1.1-1.1,0.7-1.8,0.8c-0.4,0-0.8-0.1-1.1,0.2
c-1,1-2.3,1.3-3.5,1.9c-1.6,0.8-3.4,1.3-4.6,2.5c-1.1,1-2.4,1.6-3.6,2.2c-2.1,1-4.1,2-5.5,3.8c-0.2,0.2-0.4,0.4-0.7,0.4
c-0.7-0.1-1.1,0.4-1.5,0.8c-0.7,0.7-1.2,1.8-2,2c-2.2,0.5-3.1,2.5-4.7,3.7c-0.2,0.1-0.4,0.5-0.6,0.4c-1.2-0.1-1.7,0.8-2.4,1.6
c-0.3,0.3-0.5,0.5-0.9,0.5c-0.6-0.1-1,0.4-1.4,0.7c-1.9,1.7-3.5,3.7-4.9,5.8c-1.3,1.9-3,3.3-4.4,5.2c-1.6,2.2-3.3,4.4-5.2,6.4
c-1,1-1.5,2-1.3,3.4c0.1,0.7-0.1,1.3-0.8,1.8c-0.7,0.5-1.4,1.1-1.2,2.2c0,0.4-0.3,0.5-0.6,0.4c-1.4,0-1.9,1-2.4,2
c-0.7,1.5-1.2,3.2-2.2,4.5c-1.3,1.6-2.3,3.3-3.1,5.2c-0.9,2.2-2.8,4.1-2.8,6.7c0,0.1-0.1,0.2-0.1,0.3c-0.8,1.4-2,2.8-1.9,4.6
c0,0.2-0.2,0.4-0.3,0.5c-0.7,1.4-1.9,2.6-1.7,4.3c0,0.2,0,0.3-0.1,0.5c-0.9,1.5-2.1,2.9-1.9,4.8c0,0.1-0.1,0.3-0.2,0.3
c-1.2,0.4-0.8,1.3-0.8,2.1c-0.1,0.9-0.1,1.6-0.6,2.4c-0.9,1.5-1.8,3.1-1.4,5c0.1,0.5,0.1,1-0.3,1.5c-1,1.6-2.1,3.2-1.7,5.3
c0.1,0.6,0,1.2,0,1.9c0,0.3,0.1,0.8-0.4,0.8c-0.8,0.1-0.6,0.6-0.6,1.1c0,1,0,1.9,0,2.9c0,0.3,0,0.8-0.1,0.8
c-1.2,0.5-0.8,1.5-0.9,2.3c0,0.3,0.1,0.7-0.2,0.8c-1,0.3-0.7,1.1-0.8,1.8c0,1.2,0.1,2.5,0,3.7c-0.1,1,0.7,2.3-0.9,2.8
c-0.1,0-0.1,0.5-0.1,0.7c0,0.7,0,1.5,0,2.2c0,0.3,0.1,0.7-0.3,0.7c-0.9,0.1-0.7,0.8-0.7,1.3c0,3.2,0,6.4,0,9.6c0,0.4,0.2,1-0.4,1.1
c-0.8,0.1-0.6,0.7-0.6,1.1c0,0.7,0,1.4,0,2.1c0,0.3,0.1,0.7-0.4,0.8c-0.7,0.1-0.6,0.6-0.6,1c0,10.9,0,21.8,0,32.6
c0,0.4-0.1,1,0.2,1c1.1,0.2,0.7,1,0.8,1.7c0,1.5,0,3,0,4.5c0,0.3,0.1,0.8-0.2,0.9c-1.1,0.2-0.8,1-0.8,1.7c0,1.3-0.1,2.6,0,3.9
c0.1,0.9-0.6,2.2,0.9,2.7c0.1,0,0.1,0.5,0.1,0.8c0,1.6,0,3.2,0,4.7c0,0.4-0.1,1,0.3,1.1c0.9,0.2,0.7,0.8,0.7,1.3
c0,4.4,0,8.8,0,13.2c0,0.5-0.2,1.3,0.3,1.4c0.9,0.3,0.7,0.9,0.7,1.4c0,0.8,0.1,1.7,0,2.5c-0.4,2.6,2.8,4.9,5.5,4.2
c0.1,0,0.4-0.1,0.4-0.2c0.1-1.1,0.9-0.7,1.5-0.7c1.6-0.1,3.6-2,3.6-3.6c0-3.7,0-7.5,0-11.2c0-0.4-0.2-1,0.3-1.1
c0.9-0.2,0.7-0.8,0.7-1.4c0-2.5,0-5.1,0-7.6c0-0.3-0.1-0.9,0.2-0.9c1.3-0.3,0.8-1.3,0.8-2c0.1-1.1,0-2.2,0-3.2
c0-0.3-0.1-0.8,0.3-0.8c0.7-0.1,0.6-0.7,0.6-1.1c0-1,0-1.9,0-2.9c0-0.2-0.1-0.7,0.1-0.7c1.6-0.8,0.7-2.2,0.9-3.4
c0.1-0.3-0.1-0.7,0.2-0.8c1.2-0.2,0.7-1.1,0.8-1.8c0.2-0.9-0.5-2.1,0.9-2.6c0.1,0,0.1-0.2,0.1-0.3c0.2-1-0.4-2.2,0.9-2.7
c0.1,0,0.1-0.2,0.1-0.4c0.1-1.4,0.1-2.7,0.8-4.1c0.8-1.5,2.4-2.4,2.6-4.2c2.8-2.7,5.5-5.6,8.4-8.2c1.2-1.1,2.7-2,4.6-1.8
c1.4,0.1,2.9,0,4.4,0c1.2,0,1.8-0.9,2.2-1.7c0.4-0.9-0.3-2.1,1-2.7c0.1-0.1,0.1-0.5,0.1-0.7c0-0.5,0.1-1,0-1.5
c-0.2-1.3,0.8-1.8,1.6-2.3c0.8-0.5,1.4-0.1,1.5,0.9c0,0.4,0,0.9,0.4,1c0.7,0.2,0.6,0.7,0.6,1.2c0,2.2,0,4.3,0,6.5
c0,1.6-1.9,3.4-3.5,3.4c-0.5,0-1.3-0.2-1.4,0.2c-0.2,1.3-1.1,0.7-1.7,0.8c-0.6,0-1.1,0.2-1.5,0.7c-0.5,0.6-1.1,1.1-1.7,1.7
c-1.4,1.6-2.4,3.3-2.3,5.5c0.1,0.7-0.1,1.4,0,2.1c0.4,3.3-0.9,6.7,1,9.9c0.6,1,0.9,2.3,1.7,3.2c1.1,1.2,2.2,2.3,3.4,3.3
c0.8,0.7,1.9,1.5,2.9,1.5c2.2,0,4.4,0.7,6.6-0.7c1.5-1,3.3-1.7,4.9-2.5c0.7-0.3,1.7-0.4,2.2-1c0.6-0.8,1.2-0.7,1.9-0.7
c1.1,0,2.1,0.2,3.1,0.8c1.7,1.1,3.3,2.8,3.3,4.8c0,27.6,0,55.2,0,82.9c0,2.5,1.8,4.2,4.2,4.2c97.1,0,194.2,0,291.2,0
c97.1,0,194.2,0,291.4,0c2.4,0,4.1-1.7,4.1-4.1c0-49.2,0-98.4,0-147.7c0-5.6,0-11.3,0-16.9C659,112.9,659.2,112.4,658.8,112z"/>
<path class="st1" d="M365.6,279c-72.3,0-144.7,0-217,0c-18.2,0-36.3,0-54.5,0c-5.8,0-11.6-0.4-17.4-0.1c-0.8,0.1-1.1-0.1-1.2-1.1
c-0.1-13-0.1-26,0-39c0-0.7-0.3-1.6,0.4-1.9c0.8-0.3,1.3,0.5,1.9,1c8.7,8.3,18.4,15.3,28.5,21.7c8.9,5.6,18.4,10.4,27.8,15.2
c1.7,0.9,3.4,1.5,5.1,2.2c0.9,0.4,1.4,0.1,1.2-0.9c-0.4-2.9-0.9-5.7-1.3-8.6c-0.8-5.3-2-10.6-2.6-16c-0.2-1.9-0.6-3.9-0.8-5.8
c-0.9-6.5-1-13-1.7-19.4c0-0.4-0.1-0.8-0.1-1.2c0-1.3,0.3-1.7,1.5-1.6c1.2,0.1,1.5,0.5,1.3,1.7c-0.1,0.9-0.8,1.8,0,2.7
c1.1,1.2,2.2,2.4,4,2.5c0.2,0,0.4,0,0.6,0.1c1.6,0.4,3.2,0.5,3.2,2.9c0,1.6,0.9,3.1,2.1,4.4c3.3,3.7,5.7,7.9,6.8,12.7
c1.6,6.7,2.5,13.5,2.4,20.3c0,3.2,0.6,3.8,3.7,4.1c0.5,0.1,0.9-0.2,1.1-0.6c0.8-1.1,1.4-2.2,1.7-3.5c0.9-3.2,1.9-6.5,2-9.8
c0.1-3.8,1-7.4,1.6-11.1c0.3-1.7,0.6-3.4,0.6-5.2c0.1-3.3,1-6.5,1.4-9.8c0.3-2.7,0.7-5.5,1.2-8.2c0.2-1,0.3-2,0.4-3.1
c0.2-3.1,1.6-5.9,2.3-8.9c0.7-3.2,1.3-6.4,1.8-9.7c0.3-2.1,0.5-4.3,1.2-6.2c1.1-3,1.3-6.1,1.2-9.2c0-0.3,0-0.6,0.1-0.9
c0.4-1,4.1-3.4,5.2-3.4c94.7,0,189.3,0,284,0c53.9,0,107.7,0,161.6,0c6.8,0,13.6,0,20.4,0c2.8,0,2.8,0,2.8-2.9c0-19,0-38,0-57.1
c0-2.7-0.1-2.8-2.9-2.8c-58.5,0-117,0-175.5,0c-72.3,0-144.7,0-217,0c-26.5,0-53,0-79.5,0c-2.3,0-2.3,0-1.9-2.4
c0.1-0.4,0.2-0.7,0.2-1.1c0.4-1.9,0.4-1.9,2.3-1.9c19.4,0,38.8-0.1,58.2-0.1c136.5,0,273,0,409.5,0c3.5,0,7.1,0,10.6,0
c0.9,0,1.2,0.3,1.2,1.1c0,3.3,0,6.6,0,10c0,48.5,0,96.9,0,145.4c0,1.3,0,2.7,0,4c0,0.7-0.3,1-1.1,1c-2.6,0-5.2,0-7.9,0
C553.1,279,459.4,279,365.6,279C365.6,279,365.6,279,365.6,279z M372.2,200.1C372.2,200.1,372.2,200.1,372.2,200.1
c-2.1-0.1-4.2,0-6.2-0.1c-0.9,0-1.5,0.3-1.8,1.3c-0.4,1.3-0.5,2.6-0.8,3.9c-1.7,8.4-4,16.7-5.8,25.1c-1.5,7.2-3.4,14.4-5.1,21.5
c-1.1,5-2.3,9.9-3.2,15c-0.2,1.1,0.2,1.4,1.3,1.3c3.2-0.1,6.3-0.1,9.5-0.2c3.3,0,3.3,0,4.2-3.1c0.9-3.5,1.9-7,2.7-10.6
c0.9-4,1.7-7.9,2.5-11.9c0.9-4,1.8-8.1,2.8-12.1c0.3-1.4,0.8-2.8,0.9-4.2c0.1-0.6,0.4-1,1-1.1c0.7-0.1,0.9,0.4,1.1,1
c0.1,0.4,0.3,0.9,0.5,1.3c3.8,8.5,7.5,17.1,11.3,25.6c2.1,4.6,3.9,9.3,6.2,13.8c0.5,0.9,1.1,1.5,2.2,1.5c3.9,0,7.7,0,11.6,0
c0.8,0,1.3-0.4,1.5-1.2c0.8-3.4,1.5-6.8,2.4-10.1c1.4-5.5,2.5-11,3.8-16.5c0.6-2.5,1.1-4.9,1.7-7.4c0.9-3.4,1.7-6.8,2.4-10.2
c0.7-3.1,1.2-6.3,2-9.3c1-4,1.7-8.1,3-12.1c0.3-0.8,0-1.1-0.8-1.2c-4.2-0.3-8.3-0.3-12.5-0.2c-1.1,0-1.6,0.4-1.8,1.6
c-0.9,5.4-2.2,10.7-3.6,16c-1.4,5.5-2.6,11-3.7,16.5c-0.5,2.8-1.3,5.6-2,8.5c-0.1,0.4-0.1,0.9-0.6,1c-0.5,0-0.6-0.5-0.7-0.9
c-6.2-13.9-12.3-27.7-18.5-41.6c-0.3-0.7-0.7-1-1.4-1C376.3,200.2,374.2,200.1,372.2,200.1z M453,268.2c3.7,0,7.4,0,11.1,0
c5.6,0,11.2,0.1,16.9-0.1c0.8,0,1.5-0.1,1.7-1.1c0.8-3,1.1-6.2,2-9.2c0.3-0.9-0.2-1.4-1.2-1.4c-1,0-1.9,0-2.9,0
c-12.2,0-24.4,0-36.6-0.1c-2.5,0-2.5-0.1-1.7-2.5c1.5-4.4,2.4-9,3.5-13.6c0.3-1.2,0.7-1.7,2-1.7c4.2,0,8.3,0,12.5-0.1
c7.7,0,15.4-0.1,23.1,0c1,0,1.4-0.3,1.7-1.3c0.8-2.7,1.1-5.4,1.7-8.1c0.6-2.3,0.5-2.1-1.8-2.3c-3.5-0.2-6.9,0.1-10.4,0.1
c-6.9,0.1-13.8,0-20.7,0c-1.2,0-2.4,0-3.6-0.1c-0.8,0-1.1-0.4-0.9-1.2c0.6-1.9,0.7-3.8,1.3-5.7c0.5-1.9,1-3.8,1.3-5.7
c0.3-1.8,0.5-2.1,2.3-2.2c1.2-0.1,2.5,0,3.7,0c10.8,0.1,21.6-0.2,32.4-0.2c1.2,0,2-0.3,2.3-1.7c0.6-3,1.2-6.1,2.2-9
c0.3-0.8-0.4-0.8-0.9-0.8c-2.2-0.1-4.5-0.2-6.7-0.2c-15.5,0-31.1,0-46.6,0c-0.7,0-1.1,0.2-1.4,0.9c-0.7,2-1,4-1.5,6
c-2.4,8.9-4,18-6.2,26.9c-2.7,10.8-4.8,21.7-7.6,32.5c-0.4,1.6-0.3,1.8,1.4,1.8C434.6,268,443.8,268,453,268.2z M598.2,269
c2.7-0.1,5.4-0.1,8.1-0.4c3.5-0.5,6.8-1.5,9.9-3.1c6.6-3.5,11.2-8.5,11.9-16.3c0.5-5.3-0.9-10.1-5-13.7c-2.2-1.9-4.8-3.1-7.4-4.4
c-5.9-2.8-12.1-5-18-7.8c-1.1-0.5-2.1-1.2-2.9-2.1c-3.1-3.3-1.4-8.5,2.9-9.8c3.2-1,6.5-1,9.8-0.8c4.1,0.3,7.4,2,9.6,5.7
c0.6,1,0.6,2.1,1.1,3.2c0.3,0.8,0.7,1.1,1.6,1c4-0.6,8.1-0.6,12.1-0.6c1,0,1.3-0.2,1.2-1.3c-0.2-5.3-2.8-9.5-6.6-13
c-4.2-3.8-9.4-5.5-14.9-6.2c-0.8-0.1-1.7-0.1-2.5-0.2c-6.9-0.4-13.7-0.2-20,2.9c-5.9,2.9-9.8,7.3-10.7,13.9
c-0.4,3-0.5,6.1,0.7,9.2c2.2,5.2,6.3,8.2,11.2,10.4c5.5,2.4,11,4.7,16.5,7c1.3,0.5,2.5,1.2,3.5,2.1c1.7,1.4,2.6,3.1,2.2,5.4
c-0.4,2.5-3.1,5.4-5.8,6.1c-1.6,0.4-3.2,0.7-4.9,1c-4,0.6-8,0.2-11.8-1.1c-2.1-0.7-3.9-1.9-4.7-4.2c-0.6-1.9-1.2-3.8-1.4-5.8
c-0.1-0.7-0.3-1-1-0.9c-0.4,0.1-0.7,0-1.1,0c-3.6,0.1-7.2,0.3-10.7,0.5c-2.1,0.1-2.2,0.3-2.3,2.4c-0.1,1.5,0.3,2.9,0.6,4.3
c0.7,3,1.8,5.8,3.9,8.1c3.1,3.4,7,5.8,11.4,7.2C589.2,268.8,593.7,269.2,598.2,269z M506.2,245.2c-2.5,0.1-5.1,0.2-7.6,0.5
c-0.8,0.1-1,0.4-1,1.1c0.1,1.4,0.2,2.7,0.4,4.1c0.6,5,2.5,9.3,6.9,12.3c3.9,2.6,8.1,4.5,12.9,5.1c7.4,1,14.9,1.4,22.1-1
c6.2-2,11.7-5.2,14.8-11.3c2.8-5.4,2.9-10.9,0.3-16.4c-1.5-3.2-4.2-5.1-7.1-6.8c-4.6-2.7-9.7-4.5-14.6-6.6c-3-1.3-6.2-2.3-8.8-4.3
c-3.5-2.6-3.5-6.9,0-9.6c1.4-1.1,3-1.4,4.6-1.8c2.3-0.5,4.7-0.4,7.1-0.2c5,0.6,8.9,2.8,10.3,8.1c0.4,1.6,0.8,1.9,2.5,1.8
c3.9-0.2,7.7-0.5,11.6-0.7c1,0,1.4-0.4,1.2-1.4c-0.5-2.7-1-5.3-2.4-7.6c-4.7-7.4-11.6-10.7-20.1-11.5c-0.7-0.1-1.3-0.1-2-0.1
c-5-0.3-10-0.2-14.9,1.1c-4.2,1.1-7.8,3.2-10.8,6.2c-7.7,7.9-6.5,20.4,2.1,26.1c1.7,1.1,3.4,2.2,5.3,3c4.7,2.1,9.6,4,14.3,6.1
c1.9,0.8,4,1.5,5.6,3c1.5,1.4,2.9,3,2.4,5.3c-0.4,1.8-1.3,3.4-2.8,4.4c-2,1.4-4.2,2.2-6.5,2.7c-2.6,0.5-5.2,0.3-7.9,0.2
c-2.5-0.1-4.8-0.9-7-2c-2.5-1.2-3.7-3.4-4.1-6c-0.1-0.8-0.2-1.7-0.2-2.5c0-1.2-0.5-1.5-1.6-1.5
C509.5,245.3,507.8,245.2,506.2,245.2z M226.5,200c-1.9,0-3.8,0-5.7,0c-6.6,0.1-13.2-0.1-19.9,0.1c-1,0-1.4,0.4-1.6,1.3
c-0.4,1.8-0.9,3.6-1.3,5.4c-2.7,11.9-5.5,23.8-8.2,35.8c-1.4,5.9-2.8,11.8-4.1,17.7c-0.5,2.2-1.1,4.4-1.2,6.7c0,0.6,0,1.1,0.8,1.1
c4.5,0,9,0,13.5,0c0.6,0,1-0.2,1.2-0.9c0.3-1.4,0.7-2.8,1.1-4.2c0.8-3.7,1.6-7.4,2.4-11c0.8-3.8,1.6-7.6,2.5-11.4
c0.2-0.9,0.4-1.2,1.4-1.2c11.1,0,22.2,0,33.4,0c1.1,0,1.7-0.3,2-1.4c0.7-3,1.5-5.9,2.2-8.9c0.2-0.9-0.1-1.5-1.1-1.5
c-1.2,0-2.5,0-3.7,0c-9.9,0-19.7,0-29.6,0c-0.8,0-1-0.2-0.9-1c0.5-4.6,2.2-8.9,2.9-13.4c0.1-0.8,0.6-1.5,1.7-1.5
c10.2,0.1,20.4-0.2,30.6,0.3c1.3,0.1,2.7-0.1,4-0.3c0.7-0.1,1-0.4,1.1-1.1c0.1-1,0.2-2,0.6-2.9c0.7-2,1-4.1,1.6-6.1
c0.3-1-0.1-1.4-1.1-1.4C242.7,199.9,234.6,200.1,226.5,200z M320.6,200c-1.6,0-3.2,0-4.7,0c-7.7,0-15.4-0.1-23.1,0.1
c-0.5,0-1.2-0.2-1.3,0.7c-0.6,2.6-1.2,5.2-1.8,7.9c-0.8,3.2-0.7,3.2,2.5,3.2c5.7,0,11.4,0,17.1,0c1.1,0,1.3,0.4,1.2,1.3
c0,0.2-0.1,0.5-0.1,0.7c-1.4,6.2-3,12.4-4.3,18.6c-1.9,9.4-4.6,18.6-6.4,28c-0.4,2.2-1.1,4.2-1.4,6.4c-0.1,0.9,0.2,1.3,1.2,1.2
c2.7-0.1,5.5,0,8.2-0.1c1.7,0,3.3-0.1,5-0.1c0.6,0,1.2-0.3,1.2-1c0.1-1.6,0.9-3.1,1.2-4.7c0.6-3.3,1.6-6.5,2.2-9.8
c0.7-3.9,1.8-7.6,2.7-11.5c0.5-2.3,0.8-4.7,1.5-6.9c1-3.3,1.8-6.7,2.5-10.1c0.7-3.6,1.7-7.1,2.2-10.7c0.1-1,0.8-1.5,1.8-1.4
c2,0.1,4.1,0,6.1,0c3.9,0,7.8,0,11.7,0c2.1,0,2.5-0.3,2.9-2.3c0.4-2.2,1.2-4.3,1.4-6.6c0-0.4,0.2-0.7,0.3-1.1
c0.5-1.3,0.4-1.5-1-1.7c-0.3,0-0.7,0-1-0.1C339,199.8,329.8,200.1,320.6,200z M257.2,268.2c2,0,4.2,0,6.4,0c0.8,0,1.1-0.3,1.4-1.1
c1.1-3.5,1.6-7.1,2.5-10.6c1.1-4.6,2.2-9.2,3.3-13.8c0.5-2.1,1.1-4.2,1.4-6.3c0.5-3,1.2-5.9,2-8.8c1-3.8,1.8-7.7,2.7-11.5
c1.2-4.9,2.3-9.8,3.3-14.8c0.2-0.9-0.1-1.1-0.9-1.1c-4.6-0.2-9.2-0.2-13.7,0c-0.7,0-1,0.3-1.1,0.9c-1,4.6-2.1,9.2-3.2,13.8
c-1.9,8.2-3.8,16.5-5.7,24.7c-1.3,5.8-2.6,11.6-4,17.3c-0.8,3.3-1.8,6.6-2.1,10.1c-0.1,1.1,0.3,1.3,1.2,1.2
C252.8,268,254.9,268,257.2,268.2z"/>
<path class="st1" d="M143.3,50.7c0.1,3.4,0.7,6.5,1,9.6c0.7,8.8,0.7,17.7-1.5,26.4c-0.5,2.1-1,4.2-1.8,6.2
c-0.6,1.4-0.8,1.5-2.2,0.9c-2.5-1.1-5-2.1-7.6-2.8c-3.5-1-7.1-2-10.8-2.4c-2.9-0.4-5.8-0.9-8.8-1c-3.3-0.1-6.7,0-10,0.2
c-1.3,0.1-2.6,0.2-3.8,0.8c-0.6,0.3-0.8,0.2-0.9-0.6c-1.3-8.2-2.2-16.4-4.9-24.3c-1.8-5.1-3.4-10.3-7-14.6c-0.5-0.6-1-1.8-1.9-1.3
c-0.4,0.2,0.4,1.3,0.7,2c2.7,6,5.2,12.1,6.8,18.5c0.9,3.7,2,7.3,2.7,11c0.5,2.8,0.7,5.7,0.7,8.6c0,0.6,0,1.1-0.8,1.1
c-4.7-0.3-9.2,0.8-13.7,1.8c-3.4,0.8-6.5,2.2-9.6,3.9c-0.8,0.5-1.4,0.3-1.9-0.4c-2.5-3.2-5.1-6.1-7.9-9c-2.6-2.7-5.2-5.5-8.3-7.7
c-0.4-0.3-0.8-0.7-1.5-0.5c0.7,1.2,1.6,2.1,2.5,3c3.8,4,7.1,8.4,10.4,12.7c0.7,0.9,1.3,1.8,1.8,2.9c0.4,0.9,0.2,1.6-0.7,2.2
c-4.5,3.1-8.7,6.6-12.3,10.9c-6.4,7.5-11.3,15.9-14.7,25.1c-2,5.4-4,10.9-4.9,16.6c-0.4,2.2-0.7,4.4-0.9,6.6
c-0.1,0.9-0.6,1.8-1.4,2.3c-4.7,2.8-8.4,6.7-11.4,11.1c-2.9,4.2-5.7,8.5-7,13.6c-1.6,6.5-3.7,12.9-4.8,19.6
c-0.2,1.1-0.3,2.3-0.4,3.5c-0.1,0.5-0.1,0.9-0.2,1.4c-0.1,0.6-0.6,0.6-1.1,0.6c-0.5,0-0.6-0.4-0.6-0.7c-0.4-1.6-0.6-3.3-0.6-4.9
c0-3.8-0.6-7.6,0.1-11.3c0.4-2.3,0.5-4.7,1.3-6.9c0.1-0.2,0.1-0.5,0.1-0.7c0.5-5.5,2.1-10.8,2.6-16.3c0.1-0.7,0.6-1.3,0.2-2.4
c-0.3,0.7-0.6,1.2-0.8,1.7c-1.2,2.9-2.2,6-3,9c-0.1,0.5-0.3,0.8-0.9,0.7C3,176.9,3,176.4,3,175.9c0-3.2-0.2-6.4,0.1-9.6
c0.4-5.2,0.3-10.4,0.9-15.5c0.5-4.4,0.7-8.8,1.3-13.2c0.6-4.1,1.2-8.3,1.8-12.4c0.4-2.2,1-4.4,1.5-6.7c0.4-1.7,0.7-3.5,1-5.2
c0.7-2.9,1.4-5.9,2.2-8.8c3.7-13.1,8.9-25.5,15.6-37.3c0.6-1.1,1.3-2.1,2-3.2c0.8-1.3,0.8-1.3,2.1-0.4c2.2,1.7,4.2,3.6,6.1,5.7
c0.7,0.8,1.4,1.7,2.1,2.5c0.3,0.4,0.7,1.1,1.2,0.8c0.5-0.3,0-0.9-0.1-1.3c-1.3-4-3.8-7.3-6.3-10.5c-2.1-2.7-1.9-1.8-0.1-4.4
c4.8-6.7,10.2-13,16.2-18.7c1.5-1.4,3.1-2.9,4.8-4.2c1.4-1,1.8-1,2.7,0.4c1.6,2.3,3.1,4.6,4.5,7c0.1,0.3,0.3,0.5,0.4,0.8
c0.2,0.2,0.3,0.6,0.7,0.4c0.4-0.1,0.2-0.5,0.1-0.8c-0.2-3-1-6-2.3-8.7c-1.3-2.9-1.4-3,1.2-4.9c4.8-3.4,9.9-6.4,15.2-9
c3.9-1.8,7.8-3.7,11.9-5c3-1,6-1.9,9.1-2.6c1.9-0.4,3.8-1,5.7-1.6c0.6-0.2,1.2-0.3,1.8-0.3c5.6-0.3,11.2,0,16.7,1.3
c5.8,1.4,11.5,3,17.2,4.7c2.6,0.8,5.1,2,7.6,3.2c1.7,0.8,2.8,2.6,3.6,4.3c2.3,4.6,3.8,9.5,4.7,14.6c1.5,8.1,2,16.3,1.9,24.5
c0,6.5-0.7,12.9-2.1,19.3c-1.3,5.8-2.7,11.5-4,17.3c-0.4,1.8-0.8,1.9-2.4,1.1c-1.4-0.8-2.9-1.5-4.3-2.3c-0.9-0.5-1.3-1.1-1.1-2.1
c0.6-3.9,1.5-7.8,1.8-11.7c0.3-3.9,0.5-7.9,0.3-11.8c-0.1-3.9-0.3-7.8-1-11.7C145.2,57,144.8,53.9,143.3,50.7z"/>
<path class="st1" d="M88.9,127.4c2.4,0,4.8,0,7.2,0.4c4,0.6,7.8,2.1,11.5,3.6c6.8,2.8,13.3,6.2,19,10.8c7,5.5,12.3,12.2,14.1,21.2
c0.7,3.5,0.4,3.8-3,2.8c-5.1-1.5-10.3-2-15.5-1.9c-3.9,0.1-7.3,1.7-10.4,4.1c-2,1.5-3.7,3.2-5.1,5.3c-0.6,0.8-1.3,1-2.2,1
c-4.9,0-9.7-1-14.1-3.2c-4.6-2.2-9.1-4.6-13.5-7.3c-6-3.7-11.6-7.9-16-13.5c-2.5-3.2-4-6.7-3.8-10.9c0.1-1.7,0.7-3,2.2-3.9
c4.4-2.7,9.2-4.5,14.1-6.1C78.4,128,83.6,127.6,88.9,127.4z"/>
<path class="st1" d="M202.5,58.8c0.6-0.2,0.7-0.6,0.9-1c2.7-5.3,6.5-9.7,11-13.5c4-3.3,8.5-5.8,13.1-8.2c0.4-0.2,0.9-0.5,1.4-0.2
c1.5,0.8,3,1.5,4.4,2.4c1,0.6,0.9,1-0.2,1.5c-7.2,3.6-13.5,8.5-19.2,14.1c-8.6,8.3-15.8,17.7-22.7,27.4c-4.2,6-7.7,12.4-11.7,18.6
c-0.8,1.3-1.4,2.6-2,4c0,0.1-0.1,0.2-0.1,0.3c-0.2,0.6-0.7,0.8-1.2,0.6c-0.7-0.2-0.2-0.8-0.1-1.1c1.4-3.4,2.8-6.8,4.2-10.3
c0.9-2.2,2.1-4.1,3.2-6.2c0.2-0.4,0.4-0.9,0.6-1.3c-0.1,0-0.2-0.1-0.3-0.1c-0.6,1-1.2,2-1.8,3c-2.6,4.4-5.4,8.7-7.7,13.2
c-1.1,2.2-2.4,2.9-4.7,2.6c-3.9-0.4-7.9-0.6-11.8-0.8c-1-0.1-1-0.4-0.8-1.3c1-3.5,2-7,2.8-10.6c1.2-5.3,2.4-10.7,3.3-16.1
c1.2-7.2,1.8-14.5,2.2-21.8c0.3-5.6,0.6-11.1,0.4-16.7c-0.1-3.3-0.5-6.5-0.8-9.8c-0.1-1.1,1.3-2.5,2.4-2.4
c0.7,0.1,0.5,0.6,0.6,1.1c0.9,4.2,1,8.6,1.1,12.9c0.3,8.4-0.2,16.8-1,25.1c-0.1,0.9-0.1,1.8-0.2,2.7c-0.4,4.4-1,8.8-1.5,13.2
c-0.3,2.6-0.5,5.1-0.9,7.7c-0.1,0.5-0.1,1,0.1,1.6c0.6-0.6,0.5-1.3,0.7-2c0.9-3.3,1.9-6.7,2.8-10c0.8-3,1.4-6,2.1-8.9
c1.3-5.4,2.1-11,3.3-16.4c0.8-3.5,0.9-7.1,1.7-10.7c0.1-0.5,0.1-1,0.8-1c0.6,0,0.7,0.5,0.7,1c0.1,0.8,0.1,1.5,0.2,2.3
c0.6,0,0.5-0.3,0.6-0.6c0.9-3.5,2.3-6.8,4-10c1.2-2.2,2.2-4.4,2.8-6.8c0.3-1,0.8-1.2,1.8-1.3c2.2-0.1,4.3,0.6,6.4,0.7
c2.8,0.2,5.6,0.6,8.4,1.2c0.6,0.1,0.8,0.3,0.8,1c-0.1,1.2-0.2,2.5-0.6,3.7c-0.3,0.8-0.6,1.6,0.4,2.2c0.5,0.3,0.2,0.9,0.1,1.3
c-0.5,1.9-1,3.8-1.6,5.7c-0.2,0.7-0.4,1.4-0.4,2.2c0,0.3-0.1,0.6,0.3,0.7c0.4,0.1,0.6-0.2,0.7-0.5c0.6-1.3,1.2-2.5,1.8-3.8
c1.4-3.4,2.9-6.7,4.2-10.1c0.3-0.9,0.9-1.1,1.6-0.8c2.8,1.2,5.8,1.9,8.8,2.8c0.4,0.1,0.8,0.1,1,0.5c0.3,0.5,0.5,0.8-0.2,1.3
c-6.3,5-11.8,10.8-14.8,18.4C202.9,53.8,202.1,56.1,202.5,58.8z"/>
<path class="st1" d="M79,215.1c0.1-4.3,0.2-8.6,0.4-12.9c0.3-4.9-0.8-9.4-4.1-13.2c-3-3.4-6.8-5.3-11.3-5.5
c-2.2-0.1-4.1,0.9-5.8,2.2c-1,0.7-1.9,0.9-3,0.6c-2.6-0.8-4.7-2.2-5.8-4.7c-0.5-1.2-1-2.4-1.2-3.6c-0.3-1.9-0.4-3.7,0.8-5.4
c3-4.2,3.1-9,2.9-13.8c0-0.5-0.4-1.2,0.3-1.4c0.5-0.2,0.9,0.5,1.1,1c4.7,9,11.9,15.4,20.6,20.3c6.7,3.8,13.6,6.9,20.9,9.3
c0.1,0,0.2,0.1,0.4,0.1c2.9,0.8,4.2,2.5,4.6,5.7c1.1,9,5.7,15.7,14.3,19.4c2.9,1.2,5.8,2,8.9,2.3c0.9,0.1,1.2,0.4,1.1,1.4
c-0.3,3.1,0.4,6.2,0.5,9.3c0.2,3.7-0.1,7.4,0.5,11.1c0.6,3.8,0.1,7.6,0.8,11.4c0.6,3.3,0.7,6.6,0.9,9.9c0.1,0.9-0.2,0.9-0.9,0.6
c-1.2-0.5-2.1-1.3-3.3-1.8c-6.2-3.2-11.9-7.2-17.9-10.9c-6.8-4.2-13.3-9-19.5-14c-2-1.6-3.8-3.5-5.7-5.2c-0.5-0.4-0.4-0.9-0.4-1.4
C79,222.2,79,218.7,79,215.1z"/>
<path class="st1" d="M35.6,158.8c0.8-5.8,1.9-11.2,3.7-16.4c1.9-5.4,3.6-10.9,6.5-16c6.7-11.9,16-21,28.4-26.9
c4.5-2.1,9-3.9,13.9-4.6c6.9-1.1,13.9-1.1,20.9-0.2c7,0.8,13.8,2.3,20.4,4.7c8.1,3,15.9,6.9,22.8,12.2c0.3,0.3,0.7,0.5,1,0.7
c0.5,0.3,0.6,0.7,0.4,1.1c-1.2,2.7-1.2,5.6-1.4,8.4c-0.1,1.2-0.6,1.1-1.3,0.7c-1.3-0.7-2.6-1.5-3.8-2.3c-7-4.4-14.5-7.9-22.3-10.7
c-8.4-3-17.2-4.3-26.1-3.9c-3,0.1-6,0.7-8.9,1.6c-2.5,0.7-5.1,1.3-7.5,2.2c-7.9,2.8-15,6.8-21,12.6c-5.8,5.7-10.1,12.3-13.3,19.7
c-2.7,6.3-4.4,12.9-6,19.6c-0.2,0.8-0.5,1-1.2,0.5c-1.4-0.9-2.8-1.9-4.5-2.3C35.6,159.3,35.6,158.9,35.6,158.8z"/>
<path class="st1" d="M368.5,129.7c2.5,0,7.2-0.1,11.9,0.2c8,0.4,13,6.3,12,14.3c-0.8,6.4-4.5,10.1-10.4,12.1
c-1.7,0.6-3.4,1.1-5.2,1.3c-0.8,0.1-1,0.5-0.4,1c3.1,3.1,5.2,6.9,7.1,10.7c1.1,2.3,2.3,4.6,3.4,6.9c0.2,0.4,0.3,0.8,0.5,1.1
c0.4,1.3,0.3,1.4-1,1.4c-2.5,0-5.1,0-7.6,0c-0.7,0-1.3-0.1-2-0.2c-0.7-0.1-1-0.4-1-1.1c0-1-0.6-1.8-1-2.7
c-1.4-3.4-3.1-6.8-4.7-10.2c-0.9-1.8-2-3.3-3.5-4.6c-1.1-0.9-2.4-1.5-3.8-1.7c-2.2-0.2-4.3-0.2-6.5-0.2c-0.7,0-1,0.3-1.2,0.9
c-1,3.8-2,7.7-2.8,11.6c-0.5,2.4-1.1,4.7-1.6,7c-0.2,0.7-0.5,1-1.3,1c-2.8,0-5.6-0.1-8.4,0c-1.2,0-1.5-0.6-1.1-1.5
c0.5-1.6,0.9-3.3,1.3-4.9c1.7-7.2,3.3-14.5,5-21.7c1-4.2,2-8.4,3-12.7c0.5-2.3,1.1-4.7,1.5-7c0.2-1,0.6-1.2,1.5-1.2
C356.9,129.7,361.6,129.7,368.5,129.7z M368.6,137.5c0-0.1,0-0.1,0-0.2c-2.3,0-4.7,0.1-7,0c-1.2,0-1.7,0.4-2,1.5
c-0.7,3.6-1.6,7.2-2.3,10.8c-0.3,1.2,0,1.4,1.1,1.4c4.2,0.1,8.3,0,12.5-0.2c1.9-0.1,3.7-0.5,5.4-1.3c2.6-1.3,4.6-3.1,5.2-6.1
c0.5-2.3-0.5-4.3-2.7-5.2c-1.1-0.4-2.2-0.7-3.4-0.7C373.2,137.5,370.9,137.5,368.6,137.5z"/>
<path class="st1" d="M541.9,130c-0.6,1-1.6,1.6-2.5,2.4c-6.7,5.3-13.4,10.6-20.2,15.9c-0.4,0.3-0.6,0.5-0.3,1.1
c2.9,4.8,5.6,9.7,8.6,14.4c2.6,4.2,4.9,8.7,7.8,12.8c0.2,0.3,0.4,0.7,0.5,1.1c0.2,0.6,0,1-0.7,1c-3.5,0-6.9,0.2-10.4-0.1
c-0.6-0.1-0.8-0.4-1.1-0.8c-4-7-8-14-12-21c-0.9-1.6-0.9-1.8-2.5-0.6c-3,2.4-6,4.7-9,7.1c-0.5,0.4-0.6,1-0.7,1.5
c-0.9,4.2-1.7,8.5-2.9,12.7c-0.2,0.8-0.5,1.1-1.4,1.1c-2.9,0-5.7,0-8.6,0c-0.9,0-1.1-0.4-0.9-1.2c0.7-2.6,1.2-5.2,1.9-7.7
c0.8-2.9,1.5-5.8,2.1-8.7c1-4.8,2.1-9.4,3.1-14.2c1.1-5.6,2.8-11,4-16.5c0.1-0.6,0.4-0.7,0.9-0.7c3,0,6,0.1,9,0.1
c1,0,0.7,0.5,0.6,1.1c-1.1,4.9-2.2,9.9-3.4,14.8c-0.3,1.3-0.4,2.6-0.6,3.9c0,0.2-0.1,0.6,0.1,0.7c0.3,0.2,0.6,0,0.8-0.2
c2.3-2,4.6-3.9,6.9-5.9c0.7-0.6,1.3-1.1,2-1.6c4.6-3.6,8.9-7.4,13.2-11.3c1.3-1.2,2.6-1.5,4.2-1.5c3.5,0.1,6.9,0,10.4,0
C541.2,129.7,541.6,129.6,541.9,130z"/>
<path class="st1" d="M554.2,129.7c1.5,0,2.9,0.1,4.4,0c0.8,0,1,0.2,0.9,1c-0.3,2-1,3.9-1.4,5.8c-1.3,5.8-2.5,11.6-3.9,17.4
c-0.8,3.3-1.6,6.6-2.4,9.9c-0.2,0.8-0.2,1.5,0.1,2.3c1.3,3.4,3.9,5.1,7.3,5.4c5.4,0.6,10-0.9,13.2-5.7c1.1-1.7,1.5-3.7,2-5.6
c1.3-5.1,2.5-10.2,3.7-15.3c1-4.2,1.9-8.5,2.8-12.7c0.1-0.6,0.5-1.2,0.6-1.9c0.1-0.5,0.4-0.5,0.8-0.5c2.9,0,5.7,0.1,8.6,0.1
c1,0,1.1,0.5,0.9,1.4c-1.7,7.3-3.4,14.5-5.1,21.8c-0.9,4.3-1.9,8.5-3.4,12.6c-2.3,6.1-6.3,10.4-12.5,12.5
c-4.3,1.5-8.8,1.8-13.3,1.4c-2-0.2-3.9-0.6-5.7-1.1c-5.8-1.5-11-7.2-10.3-14.9c0.3-3.7,1.3-7.2,2-10.7c1.3-6.4,3.3-12.7,4.5-19.2
c0.2-1.1,0.7-2.1,0.7-3.3c0-0.6,0.5-0.5,0.9-0.5C551.2,129.7,552.7,129.7,554.2,129.7C554.2,129.7,554.2,129.7,554.2,129.7z"/>
<path class="st1" d="M236.4,143.6c-1.4,0-2.8,0-4.2,0c-0.5,0-0.9-0.1-1-0.8c-0.6-3.4-2.7-5.4-6-6c-2.7-0.5-5.5-0.6-8.2,0.1
c-2.9,0.7-4.6,2.7-4.5,5.2c0,0.9,0.3,1.7,0.9,2.4c1.3,1.5,3,2.4,4.8,3.2c4.3,1.8,8.6,3.7,12.9,5.7c9.4,4.4,8.1,15.6,2.6,20.5
c-3.8,3.3-8.1,5.1-13.1,5.6c-5.4,0.5-10.7,0.2-15.7-1.9c-6.8-2.8-8.6-6.6-9.4-13.8c-0.1-0.5,0.2-0.7,0.6-0.7
c2.9-0.1,5.8-0.3,8.7-0.4c0.6,0,0.7,0.4,0.7,0.8c0,0.5,0.1,1.1,0.2,1.6c0.8,4.6,4.3,5.9,8.2,6.4c2.6,0.3,5.3,0.4,7.9-0.7
c1.4-0.6,2.8-1.2,3.9-2.4c2.3-2.6,1.8-6-1.3-7.6c-3.3-1.7-6.8-3-10.2-4.4c-2.3-1-4.5-1.9-6.6-3.1c-4-2.4-5.8-6.2-5.7-10.7
c0.1-3.7,1.8-6.9,4.8-9.3c3.3-2.7,7-3.9,11.1-4.2c4.9-0.4,9.8-0.1,14.3,1.8c4.9,2.1,8.2,5.7,9.2,11.1c0.2,1.1,0,1.6-1.3,1.6
C239,143.4,237.7,143.5,236.4,143.6z"/>
<path class="st1" d="M632,143.6c-1.3,0-2.7,0-4.2,0c-0.4,0-0.8,0.1-0.9-0.6c-0.4-3.6-2.7-5.4-5.9-6.2c-2.9-0.7-5.8-0.7-8.7,0.1
c-3.1,0.8-4.6,3.2-4,6.1c0.3,1.3,1.1,2.2,2.2,2.9c2.7,1.7,5.8,2.7,8.7,4c3,1.3,6,2.5,8.8,4.1c4.6,2.8,6.3,6.6,5.6,11.9
c-0.7,4.9-3.6,8.2-7.7,10.6c-5.2,3-10.9,3.4-16.7,2.9c-3.8-0.3-7.6-1-11-3.1c-3.2-1.9-5.4-4.5-6.3-8.1c-0.3-1.3-0.5-2.5-0.8-3.8
c-0.2-1,0.1-1.5,1.2-1.5c2.8-0.1,5.6-0.2,8.4-0.3c0.7,0,0.9,0.4,0.9,1c0,1,0.2,1.9,0.4,2.8c0.4,2.2,1.9,3.3,3.9,4
c4.3,1.5,8.6,1.5,12.8,0c2.6-0.9,4.2-3.1,4.3-5.5c0-1.9-0.9-3.2-2.5-4.1c-3.4-1.8-7.1-2.9-10.5-4.5c-1.7-0.8-3.5-1.5-5.2-2.4
c-8.6-4.4-8.5-14.1-3.3-19.7c3.1-3.4,7.3-4.7,11.7-5.2c4.3-0.5,8.6-0.1,12.9,0.9c5.3,1.4,10.8,7.1,11.4,12.2
c0.1,0.9,0,1.3-1.1,1.3C635.1,143.4,633.6,143.5,632,143.6z"/>
<path class="st1" d="M328.7,178.7c-1.2,0-2.4,0-3.6,0c-1,0-1.5-0.4-1.6-1.3c-0.5-2.8-1-5.5-1.4-8.3c-0.2-1.1-0.6-1.3-1.6-1.4
c-6.4-0.1-12.7,0.2-19.1-0.2c-0.9,0-1.5,0.3-2,1.1c-1.7,3-3.8,5.8-5.4,8.9c-0.4,0.7-0.8,1.1-1.6,1.1c-2.9,0-5.8,0-8.7,0.1
c-1.1,0-1.1-0.3-0.6-1.2c3.4-5.4,6.9-10.9,10.3-16.3c6-9.7,12.1-19.5,18.1-29.2c1.2-1.9,2.4-2.5,4.4-2.3c2.7,0.2,5.3,0.1,8,0
c1,0,1.4,0.4,1.5,1.3c1.1,8.2,2.7,16.3,4.2,24.4c1.2,6.3,2.3,12.6,3.3,19c0.2,1.1,0.7,2.1,0.7,3.3c0,0.8-0.2,1.1-1.1,1.1
C331.2,178.7,330,178.7,328.7,178.7C328.7,178.7,328.7,178.7,328.7,178.7z M312.1,159.8C312.1,159.8,312.1,159.8,312.1,159.8
c2.2,0,4.5,0,6.7,0c1,0,1.4-0.2,1.3-1.4c-0.2-1.8-0.5-3.5-0.8-5.3c-0.6-4.1-1.1-8.1-1.7-12.2c0-0.3,0.1-0.7-0.3-0.9
c-0.4-0.1-0.6,0.3-0.8,0.5c-0.5,0.6-1,1.1-1.4,1.7c-3.5,5.3-7,10.6-10.5,15.9c-0.7,1.1-0.5,1.5,0.8,1.5
C307.6,159.8,309.8,159.8,312.1,159.8z"/>
<path class="st1" d="M463.3,129.7c1.7,0,3.4,0,5.1,0c0.6,0,0.9,0.2,1.1,0.8c1.4,5.8,2.2,11.7,3.2,17.6c1.2,6.6,2.2,13.2,3.6,19.8
c0.7,3.1,1.1,6.3,1.6,9.4c0.2,0.9-0.2,1.3-1,1.3c-2.7,0-5.4,0-8.1,0c-0.8,0-0.9-0.5-1-1c-0.4-2.2-0.8-4.5-1.1-6.8
c-0.4-3.1-0.5-3.2-3.6-3.2c-5.7,0-11.3,0-17-0.1c-1.3,0-2.1,0.2-2.8,1.5c-1.5,2.9-3.3,5.6-5,8.5c-0.5,0.8-1.1,1.1-1.9,1.1
c-2.7,0-5.4,0-8.1,0c-1.1,0-1.3-0.3-0.8-1.3c0.6-1.1,1.3-2.2,2-3.3c6.5-10.2,12.8-20.5,19.2-30.7c2.7-4.4,5.3-8.8,8.2-13.1
c0.3-0.5,0.7-0.6,1.2-0.6C459.9,129.7,461.6,129.7,463.3,129.7C463.3,129.7,463.3,129.7,463.3,129.7z M456.2,159.8
C456.2,159.8,456.2,159.8,456.2,159.8c2.3,0,4.6,0.1,6.9,0c1.4,0,1.8-0.5,1.5-1.8c-0.3-1.4-0.3-2.9-0.5-4.3
c-0.6-4.1-1.4-8.2-2.1-12.3c-0.1-0.4,0.1-1-0.4-1.2c-0.6-0.2-0.8,0.5-1,0.9c-3.9,5.9-7.9,11.7-11.8,17.6c-0.6,0.9-0.3,1.1,0.6,1.1
C451.6,159.8,453.9,159.8,456.2,159.8z"/>
<path class="st1" d="M270,129.7c3.4,0,8-0.2,12.6,0.4c6.4,0.9,10.9,5.7,10.4,12.4c-0.5,7-3.8,12.2-10.2,15.2
c-2.6,1.2-5.4,1.6-8.2,1.8c-4.9,0.4-9.7,0.2-14.6,0.2c-1.2,0-1.8,0.4-2.1,1.5c-1,3.9-1.8,7.7-2.7,11.6c-0.3,1.5-0.6,3.1-0.9,4.6
c-0.1,0.6-0.4,0.9-1,1c-3,0.3-6.1,0.2-9.1,0.1c-0.7,0-0.9-0.4-0.8-1.1c0.7-3.1,1.3-6.3,2.2-9.4c0.5-1.7,0.7-3.4,1.1-5.1
c1-4.4,2-8.9,3-13.3c1.3-6.1,3.1-12.1,4.3-18.3c0.2-1.2,0.8-1.8,2.2-1.8C260.5,129.8,264.6,129.7,270,129.7z M268,151.9
c0.9,0,2.5-0.2,4.2-0.2c2.7,0,4.9-0.9,6.9-2.7c2.3-2,3.3-4.5,3-7.5c-0.2-1.5-1-2.4-2.1-3c-0.9-0.6-2-0.9-3.1-1
c-4.1-0.3-8.2-0.3-12.4-0.2c-0.9,0-1.4,0.4-1.6,1.3c-0.8,4.1-1.6,8.1-2.5,12.2c-0.2,0.8,0.1,1.1,1,1.1
C263.4,151.8,265.4,151.9,268,151.9z"/>
<path class="st1" d="M46.9,169.8c-0.3,0.4-0.5,0.6-0.7,0.9c-3.9,4.4-3.6,9.3-1.9,14.4c0.7,2.2,2.3,3.8,4.2,5c1.9,1.2,4,1,6,0.1
c2.1-1,3.9-2.5,5.9-3.8c3.1-2,8.4-1.1,10.7,1.8c0.5,0.6,0.9,1.3,1.3,2c1.8,3.5,2.5,7.2,2.5,11.1c0.1,6.1-0.3,12.2-0.3,18.3
c0,1.7,0.1,3.3,0,5c-0.1,2.4,0.8,4.5,2.4,6.2c2.5,2.7,5.3,5.2,8.2,7.5c5.7,4.6,11.7,8.7,17.9,12.5c3.7,2.3,7.5,4.7,11.4,6.7
c2.5,1.3,4.8,3,7.3,4.3c2.9,1.5,5.9,2.8,8.9,4.2c0.3,0.1,0.6,0.2,0.8,0.2c1.2,0.3,1.6-0.1,1.3-1.2c-0.9-3.5-1-7.1-1.5-10.6
c-0.9-5.5-0.8-11.1-1.4-16.6c-0.8-7.9-0.6-15.7-1-23.6c-0.1-1.3-0.5-1.8-1.8-1.7c-4.1,0.1-8.1-0.5-12-2c-2.9-1.1-5.3-3-7.3-5.3
c-3.3-3.9-5.6-8.2-6-13.4c0-0.5-0.2-1.2,0.5-1.4c0.6-0.1,1,0.4,1.3,1c1.9,4.5,4.2,8.7,7.9,11.9c3.4,2.9,7.1,4.7,11.6,5.5
c2.6,0.4,5.1,0,7.7-0.2c1.6-0.1,1.7-0.1,1.7,1.6c0,7.3,0.3,14.6,0.9,21.9c0.3,4.1,0.4,8.3,1,12.4c1,6.9,1.5,13.9,3,20.8
c0.6,2.9,1.8,5.7,1.7,8.8c0,1-0.1,1.3-1.2,0.7c-8.3-4.3-16.6-8.6-24.7-13.3c-6.6-3.8-13.1-8-19.2-12.5c-4.8-3.6-9.2-7.5-13.8-11.3
c-2.8-2.4-5.2-5.1-8-7.5c-1.4-1.2-2-2.8-2-4.6c0.1-5.7,0.4-11.5,0.6-17.2c0.1-4.2,0.7-8.5-0.5-12.8c-1.2-4.1-4.4-5.3-8.2-3.8
c-2.2,0.9-4.1,2.1-6.2,3.2c-2.3,1.2-4.7,2-7.4,1.3c-3-0.8-5-2.8-6.4-5.4c-3-5.5-3.6-11.3-1.5-17.2
C41.5,170.5,43.6,169.4,46.9,169.8z"/>
<path class="st1" d="M191.7,15.6c1.3,1.9,2.8,3.3,4.3,4.8c1.1,1.2,2.3,2.3,2.8,3.9c0.1,0.3,0.3,0.6,0,0.8c-0.2,0.2-0.5,0-0.7-0.1
c-0.6-0.3-1.2-0.2-1.8-0.3c-3.5-0.3-6.9-1.3-10.4-1c-0.4,0-0.6-0.1-0.9-0.3c-1.5-1.3-3.2-2.4-4.7-3.7c-2.3-2.1-5-1.4-7.6-1.3
c-0.8,0-0.3,0.5-0.1,0.7c0.9,0.7,1.8,1.5,2.7,2.2c0.3,0.2,0.5,0.5,0.7,0.8c0.2,0.3,0.2,0.7-0.1,0.9c-0.3,0.3-0.6,0.1-0.9-0.1
c-3.2-2.2-6.7-3.7-9.9-5.7c-1-0.6-2-0.7-3.1-0.7c-4,0.1-7.9,0.1-11.9,0.3c-1.9,0.1-3.3-0.9-5-1.4c-5-1.7-9.9-3.3-14.9-4.9
c-3.5-1.1-7.1-1.6-10.7-2.2c-1.7-0.3-3.4-0.2-5.1-0.3c-0.5,0-1.1,0.1-1.2-0.7c0-0.7,0.3-1.1,1-1.1c2.2,0.1,4.1-0.8,6.2-1.2
c3.2-0.6,6.4-0.8,9.6-1.4c3-0.6,6.2-0.4,9.3-0.4c5.1,0,10.2-0.2,15.2,0.4c4.4,0.5,8.7,1.2,13.1,1.8c0.7,0.1,0.7,0.5,0.8,1
c0.2,0.9,0.7,1,1.4,0.6c1.1-0.7,2.1-0.5,3.3-0.2c1.7,0.5,3.4,0.8,5.1,1.3c9.3,2.7,18.2,6.2,26.8,10.7c4,2.1,7.8,4.4,11.7,6.6
c1.7,1,2.4,2.5,2.8,4.2c0.2,0.7,0,0.9-0.8,0.6c-3.6-1.3-7.1-2.5-10.9-3.2c-0.6-0.1-1-0.5-1.4-0.8c-3.4-3.2-7.1-6-10.9-8.7
C194.6,16.4,193.4,15.5,191.7,15.6z"/>
<path class="st1" d="M144.5,127.8c-0.1,2.9,0.1,4.9,0.9,6.9c0.1,0.4,0.4,0.9,0,1.2c-0.4,0.3-0.8-0.1-1.1-0.2
c-2.9-1.5-5.7-3.1-8.6-4.6c-5.6-2.9-11.3-5.6-17.3-7.4c-8.5-2.5-17.3-4-26.2-3.5c-9,0.5-17.5,2.7-25.3,7.4
c-0.9,0.5-1.8,0.9-2.6,1.4c-1.4,0.8-1.1,0.7-1.8-0.6c-1-2.1,0-3.3,1.4-4.5c4.4-4.1,9.5-7,15-9.2c3.8-1.6,7.7-3,11.6-4.5
c2-0.7,4.1-0.6,6.2-0.9c4.6-0.7,9.2,0,13.6,0.9c6.3,1.4,12.4,3.8,18.3,6.5c4.8,2.2,9.2,4.9,13.9,7.3
C144.5,125.1,144.7,126.7,144.5,127.8z"/>
<path class="st1" d="M418.9,129.7c6.6,0,13.2,0,19.7,0c1.2,0,1.5,0.4,1.3,1.5c-0.3,1.7-0.5,3.4-0.8,5c-0.2,1.1-1,1.2-1.9,1.2
c-4.3,0-8.6,0.1-12.9,0.1c-1.2,0-1.7,0.3-1.9,1.6c-1.8,8-3.6,16.1-5.6,24.1c-1.2,4.7-1.9,9.6-3.1,14.3c-0.2,0.9-0.6,1.3-1.6,1.3
c-2.8-0.1-5.6-0.1-8.4,0c-1,0-1.2-0.3-1-1.2c1-3.9,1.9-7.9,2.8-11.8c0.5-2.1,0.9-4.3,1.4-6.4c0.7-3.3,1.4-6.6,2.2-9.9
c0.7-2.7,1.3-5.4,1.8-8.1c0.2-0.9,0.5-1.7,0.8-2.6c0.2-0.9,0-1.2-0.9-1.2c-3.3,0-6.7,0-10,0c-1.1,0-2.3-0.1-3.4,0
c-1,0.1-1.3-0.3-1.1-1.2c0.4-1.9,0.6-3.8,1.3-5.6c0.3-0.7,0.6-1,1.4-1C405.8,129.7,412.3,129.7,418.9,129.7
C418.9,129.7,418.9,129.7,418.9,129.7z"/>
<path class="st1" d="M96.1,121.7c1.7,0.1,4.4-0.2,7,0.2c4.1,0.6,8.3,1.3,12.3,2.7c5.1,1.7,10.1,3.4,15,5.6
c4.1,1.8,7.9,4.2,11.9,6.2c1.5,0.8,2.6,2.2,4.3,2.7c0.6,0.1,1.1,0.2,1.7,0.2c2.7-0.3,5.4-0.6,8,0.3c0.2,0.1,0.4,0.1,0.5,0.1
c1.6-0.6,2.1,0.5,2.6,1.6c3.5,7.5,4.7,15.4,4.1,23.6c0,0.7-0.1,1.4-0.1,2.1c0,1-0.5,1.1-1.3,0.7c-1.2-0.6-1.8-1.8-2.3-3
c-0.7-1.8-1.3-3.6-1.9-5.4c-0.2-0.5-0.4-1-0.9-1.3c-1.7-1.3-2.3-1.1-2.6,1c-0.4,2.8-1.2,5.5-2.2,8.1c-0.6,1.6-1.7,2.8-3.1,3.9
c-1.2,0.9-1.8,0.7-1.9-0.8c-0.1-1.6-0.2-3.1-0.3-4.7c-0.2-4.3-0.9-8.5-2.2-12.6c-1.1-3.3-2.8-6.2-5.3-8.6
c-5.4-5.5-11.3-10.5-18.2-14c-5.7-3-11.8-4.9-18.2-5.9c-4.9-0.8-9.8-0.6-14.7-0.6c-0.6,0-1.2,0.1-1.2-0.8c0-0.9,0.6-0.8,1.2-0.9
C90.7,121.7,92.9,121.7,96.1,121.7z"/>
<path class="st1" d="M159.6,180.2c0,2.4,0,4.7,0,7.1c0,0.9-0.2,1.1-1.2,0.9c-5.5-1-11.1-1.1-16.6-1.5c-3-0.2-5.8-1-8.7-1.4
c-5.4-0.7-10.7-2.4-15.9-3.8c-1.9-0.5-3.9-1-5.8-1.3c-1.2-0.2-1.2-0.8-0.9-1.6c0.1-0.3,0.3-0.7,0.6-1c3.3-3.9,7.2-6.9,12-8.5
c3.7-1.2,7.5-1,11.1,0c2.8,0.8,5.4,2.4,8,3.9c1.3,0.7,2.7,1.2,4.1,1.5c3.2,0.7,5.6-1,7.4-3.2c1.2-1.5,2.5-3.1,2.6-5.3
c0-0.8,0.8-0.6,1.4-0.7c0.7-0.1,0.8,0.4,0.9,0.9C159.1,170.9,160,175.5,159.6,180.2z"/>
<path class="st1" d="M158.5,197.9c0,2.7-0.2,2.9-2.9,2.2c-4.8-1.1-9.6-2.1-14.4-3c-4-0.8-7.9-1.9-11.9-2.7
c-2.3-0.5-4.6-0.8-6.8-1.4c-4.2-1-8.5-1.6-12.7-2.6c-3.4-0.8-6.8-1.5-10.2-2.2c-0.6-0.1-0.7-0.5-0.7-1c0-0.9,0-1.7,0-2.6
c0-0.8,0.4-1,1.1-0.8c4.6,1.2,9.4,1.8,14.1,2.8c7.4,1.6,14.7,3.1,22.1,4.5c5.3,1,10.7,1.9,16.1,2.8c1.5,0.3,2.9,0.7,4.4,0.9
c1.7,0.2,1.9,0.4,1.9,2.1C158.5,197.2,158.5,197.5,158.5,197.9z"/>
<path class="st1" d="M95.5,125.1c9.7,0.6,19,2.9,27.5,7.9c4.8,2.9,9.2,6.2,13.4,9.9c3.3,2.9,5.2,6.7,6.8,10.7
c1.9,4.9,2.7,10,2.5,15.2c0,0.3,0.1,0.6-0.2,0.8c-0.4,0.2-0.6-0.2-0.9-0.3c-1-0.7-1.5-1.7-1.6-3c-0.5-5.8-2.4-11.1-5.9-15.8
c-4.9-6.6-11.1-11.5-18.2-15.5c-5.3-3.1-10.9-5.7-16.9-7.3c-3.5-0.9-7-1.6-10.6-1.4c-0.4,0-0.9,0.2-1-0.5c0-0.6,0.4-0.7,0.9-0.7
C92.8,125.1,94.2,125.1,95.5,125.1z"/>
<path class="st1" d="M157.9,193.6c-2.3,0-4.6-0.9-7-1.3c-5.1-0.8-10.1-2.1-15.2-3.2c-5.5-1.1-11-2.1-16.5-3.1
c-4.3-0.8-8.6-1.6-12.9-2.6c-1.9-0.4-3.8-0.7-5.7-1.2c-0.5-0.1-1-0.2-1-0.8c0.1-1.7-0.9-2.9-2.2-3.8c-0.8-0.6-1.7-1-2.6-1.4
c-0.4-0.1-0.8-0.2-1.1-0.6c-0.2-0.3-0.4-0.6-0.3-0.9c0.2-0.4,0.6-0.2,0.9-0.2c0.8,0.2,1.5,0.3,2.3,0.5c1.9,0.5,3.8,0.8,5.8,0.7
c0.8,0,1.6,0,2.4,0.2c1,0.2,1.8,0.6,1.8,1.8c0,2.8,2.2,3.3,4.2,3.9c4.6,1.3,9.3,2.7,14,3.5c3.1,0.5,6.2,1.2,9.3,1.7
c2.8,0.5,5.7,0.6,8.5,1.3c1.9,0.5,3.9,0.5,5.8,0.5c3.7,0,7.3,0.8,10.9,1.4c0.7,0.1,1.3,0.1,1.8-0.5c2-2,4.3-3.7,7-4.9
c2.2-0.9,4-2.6,6-3.8c0.6-0.4,0.8-0.9,0.8-1.6c0-0.3,0-0.8,0.4-0.8c0.6-0.1,1.2,0.1,1.6,0.5c0.3,0.3,0.3,0.7,0.1,1
c-0.2,0.3-0.5,0.6-0.7,0.8c-5,3.6-9.7,7.5-14.5,11.2C160.5,192.8,159.5,193.8,157.9,193.6z"/>
<path class="st1" d="M52.3,146.2c0.6,0.2,0.8,0.6,1,1c2.8,5.8,7,10.4,11.9,14.5c5.6,4.6,11.7,8.3,18.2,11.4
c3.7,1.8,7.5,3.3,11.3,4.9c0.6,0.3,1.3,0.6,1.9,1c1.1,0.7,1.5,1.6,1,2.9c-0.3,0.6-0.3,1.3-0.3,2c0,0.8-0.4,1-1.1,0.8
c-1-0.2-1.9-0.5-2.8-0.9c-8-3.4-15.7-7.3-22.6-12.5c-5.5-4.2-10.2-9.1-14.2-14.8c-1.7-2.4-2.9-5-4-7.7
C52.3,148,52,147.2,52.3,146.2z"/>
<path class="st1" d="M153.6,119.5c0.2-1.9,0.1-3.8,1.1-5.6c0.5-1,1.2-1.3,2.2-1.2c3.4,0.2,6.9,0.3,10.3,0.8
c1.9,0.3,3.9,0.3,5.8,0.3c0.8,0,1.1,0.3,0.8,1.1c-0.3,1.4-1,2.6-1.5,4c-0.5,1.6-0.5,3.3-0.5,4.9c0.1,0.9-0.2,1.4-1.2,1.3
c-5.3-0.5-10.5-0.5-15.8-0.7c-0.7,0-1.1-0.3-1.1-1C153.7,122.2,153.4,120.9,153.6,119.5z"/>
<path class="st1" d="M154,211.6c-0.1,2.1-0.2,5-0.5,7.8c-0.3,2.3-0.7,4.5-0.7,6.8c0,0.6-0.4,0.8-0.9,0.6c-1.6-0.5-3.1-0.8-4.7-1.3
c-1-0.4-1.2-0.9-1-1.9c0.9-4.8,1.8-9.7,1.3-14.7c-0.2-2.2-0.6-4.4-0.9-6.7c-0.2-1.2,0-1.3,1.1-1.1c1.6,0.4,3.2,0.7,4.9,1.1
c1.1,0.2,1.5,0.8,1.5,1.9C154.2,206.3,153.9,208.6,154,211.6z"/>
<path class="st1" d="M170.5,46.1c0.1-6.9-0.8-13.8-0.7-20.8c0-0.7-0.3-1.2-0.9-1.7c-0.6-0.5-1.3-1.1-1.8-1.7
c-0.5-0.5-0.7-1.1-0.5-1.7c0.1-0.5,0.2-0.7,0.8-0.4c2.3,1.4,4.5,2.8,6.8,4.2c0.4,0.2,0.4,0.6,0.6,1c1.1,3.5,0.5,7.1,0.2,10.6
c-0.1,1-0.2,2-0.3,3c-0.3,4.9-1.5,9.6-2.1,14.4c0,0.2,0,0.3-0.1,0.5c-0.2,0.7-0.7,0.8-1.4,0.8c-0.8,0-0.6-0.6-0.6-1
C170.5,51,170.5,48.6,170.5,46.1z"/>
<path class="st1" d="M161,179.9c0.3-2.9-0.2-5.7-0.2-8.6c0-0.3-0.2-0.7,0.1-0.9c0.4-0.2,0.7,0.2,0.9,0.5c0.7,0.7,1.5,1.4,2.3,1.9
c1,0.6,2.1,0.8,3.2,0.2c0.6-0.3,1.2-0.3,1.8,0c2.2,1.1,3.6,2.8,3.8,5.3c0.1,0.9-0.2,1.5-1,2.1c-3.1,2.1-6.6,3.5-9.5,5.9
c-0.3,0.3-0.7,0.5-1.1,0.4c-0.5-0.2-0.2-0.7-0.2-1.1C161,183.7,161,181.8,161,179.9z"/>
<path class="st1" d="M152,137.4c-1.2,0-2.4,0-3.6,0c-0.5,0-1.1,0-1.2-0.7c-0.4-2.4-1.7-4.5-1.6-7c0.1-4.1-0.1-3.3,3.3-3.1
c2.8,0.1,5.6,0.3,8.3,1c0.8,0.2,0.9,0.4,0.7,1.1c-0.5,2.2-1.4,4.3-1.6,6.5c-0.1,0.7,0.3,1.7-0.3,2.1c-0.5,0.3-1.4,0-2.2,0
C153.2,137.3,152.6,137.3,152,137.4C152,137.4,152,137.4,152,137.4z"/>
<path class="st1" d="M163.7,55.3c-0.5,5.8-1.3,11.6-2.2,17.4c-1,6.7-2.2,13.3-3.8,19.9c-0.7,3.1-1.6,6.1-2.4,9.1
c-0.1,0.4-0.1,1-0.7,1c-0.6,0-0.7-0.4-0.9-0.9c-0.4-1.1-0.1-2.2,0.2-3.3c1.1-4.2,2.3-8.4,3.3-12.6c1.3-5.4,2.2-10.9,3.1-16.4
c0.7-4.4,1.3-8.9,1.6-13.3c0.1-1.6,0.2-3.1,0.1-4.7c0-0.7,0.2-1,1-1c0.9,0,0.8,0.5,0.8,1.1C163.7,52.7,163.7,54,163.7,55.3z"/>
<path class="st1" d="M157.7,268.1c0.6-6.8,0.9-13.1,1.4-19.3c0.5-5.7,0.6-11.4,1.2-17.1c0.1-1,0.3-2,0.8-2.8
c0.2-0.4,0.7-0.7,1.1-0.6c0.5,0.1,0.2,0.7,0.3,1.1c0.1,3.7-0.9,7.3-0.9,11c0,5.1-0.9,10.2-0.9,15.3c0,3.3-0.8,6.6-0.9,9.9
c-0.1,2.2-0.2,4.3-0.3,6.5c0,0.6,0.1,1.3-0.9,1.3c-1,0-1-0.6-1-1.3C157.7,270.4,157.7,269,157.7,268.1z"/>
<path class="st1" d="M153.5,108.2c0,0.5-0.3,0.8-1.1,0.5c-0.6-0.2-1.1-0.6-1.6-0.9c-7.4-5.3-15.4-9.5-24.1-12.2
c-3.9-1.2-7.8-2.5-11.9-3.1c-0.5-0.1-1.1-0.1-1.6-0.1c-0.7-0.1-0.9-0.6-0.8-1.2c0-0.7,0.6-0.5,1-0.5c9.6,1.3,18.7,4.1,27.4,8.4
c4.1,2,8,4.2,11.5,7.1C153.2,106.9,153.5,107.3,153.5,108.2z"/>
<path class="st1" d="M159.9,196.7c-0.3-1.1,0.2-1.8,1.2-2.5c5.4-4,10.8-8.2,16.2-12.2c1.2-0.9,1.2-0.8,1.5,0.7
c0.4,1.8-0.2,3-1.8,4.2c-4.5,3.3-8.8,6.8-13.2,10.2c-0.8,0.6-1.6,1.3-2.6,1.7c-1.1,0.5-1.4,0.3-1.3-1
C159.9,197.4,159.9,197.1,159.9,196.7z"/>
<path class="st1" d="M55.7,139.8c-0.2,5,1.3,9.3,4.8,13c1.7,1.7,3.2,3.5,5.1,5.1c6.4,5.5,13.7,9.4,21,13.4
c0.5,0.3,1.2,0.5,1.7,0.7c0.6,0.3,1,0.8,0.7,1.4c-0.4,0.6-0.8,0-1.1-0.1c-4.7-2-9.2-4.4-13.6-7.1c-6.4-3.9-12.1-8.7-16.5-14.8
c-1.9-2.6-3.7-5.4-4.1-8.8c-0.2-1.4,0-2.7,0.4-4.1c0.1-0.5,0.4-0.8,0.9-1c0.7-0.3,1.1-0.3,1,0.7C55.7,138.7,55.7,139.3,55.7,139.8
z"/>
<path class="st1" d="M50.8,114c-0.6-0.1-0.5-0.5-0.3-0.8c0.4-0.8,0.9-1.6,1.5-2.3c1.7-1.7,3.2-3.4,4.9-5.1
c5.3-5.1,11.2-9.3,18.2-11.9c3.5-1.3,6.9-2.5,10.6-3.2c0.5-0.1,1.2-0.3,1.3,0.4c0.2,0.7-0.5,0.9-1,1c-4,0.6-7.7,2.3-11.4,4
c-8.3,3.7-15.3,9.1-21.5,15.7c-0.5,0.5-1,1-1.4,1.5C51.5,113.7,51.2,113.9,50.8,114z"/>
<path class="st1" d="M157.9,18.3c1.8,0,3.7,0,5.5,0c0.6,0,1,0.1,1.2,0.8c0.1,0.7,0.2,1-0.8,1.1c-2.5,0.1-4.9-0.1-7.3-0.3
c-0.3,0-0.7,0-1,0c-0.4,0.1-0.9-0.1-1,0.3c-0.2,0.5,0.3,0.7,0.6,1c2.9,3.2,4.2,7.2,4.9,11.4c0.5,2.9,1,5.7,1.1,8.6
c0,0.9-0.1,1.4-1.1,1.3c-0.7-0.1-0.7-0.6-0.7-1.1c-0.2-2.6-0.6-5.2-1.2-7.7c-1.2-4.9-2.6-9.8-5.9-13.9c-0.3-0.3-0.7-0.7-0.4-1.2
c0.2-0.4,0.8-0.2,1.1-0.2C154.4,18.2,156.1,18.2,157.9,18.3C157.9,18.3,157.9,18.3,157.9,18.3z"/>
<path class="st1" d="M114.2,255c-0.2,0.8-0.6,0.6-1.1,0.3c-1.9-1.2-3.7-2.4-5.6-3.5c-5.4-3.1-10.5-6.6-15.5-10.2
c-4.9-3.5-9.8-7.1-13.9-11.7c-0.2-0.2-0.4-0.4-0.6-0.7c-0.2-0.3-0.3-0.6,0-0.9c0.3-0.3,0.6-0.3,0.9,0c0.3,0.2,0.6,0.5,0.9,0.7
c4.7,4.4,9.7,8.4,14.9,12.2c6,4.3,12.3,8.2,18.5,12.2C113.3,253.8,114,254.2,114.2,255z"/>
<path class="st1" d="M209.9,46.2c-0.5-0.1-0.5-0.4-0.4-0.7c0.2-0.7,0.6-1.3,1-1.8c2.6-3.2,5.7-6,8.7-8.9c0.7-0.6,1.2-1.5,2.1-1.8
c0.6-0.2,0.5-0.8,0.4-1.3c-0.1-0.4-0.1-0.8,0.3-1c0.4-0.3,0.8,0,1.1,0.3c1,0.9,2.1,1.8,3.1,2.7c0.8,0.8,0.2,1.1-0.5,1.4
c-5.1,2.8-10,6-14.3,9.8C211,45.3,210.6,45.9,209.9,46.2z"/>
<path class="st1" d="M123.5,165.6c0.4,0.1,1.4-0.6,1.5,0.5c0.1,1-0.7,1.1-1.5,1.3c-5.8,1.4-10.1,5-13.9,9.4
c-0.4,0.5-0.8,1.2-1.4,1c-0.7-0.2-0.6-1-0.8-1.6c-0.2-0.7,0.1-1.3,0.5-1.9c2.8-3.6,6-6.6,10.6-7.8
C120.2,166.1,121.8,165.6,123.5,165.6z"/>
<path class="st1" d="M176.3,111.4c-5.7-0.6-11.5-0.8-17.2-1.1c-1.2-0.1-2.4-0.4-3.6-0.5c-0.4,0-1,0-0.9-0.7c0.1-0.5,0.1-1.2,1-1.2
c1,0,2-0.2,3,0.1c2.8,0.7,5.6,0.5,8.4,0.6c4.2,0.1,8.4,0,12.6,0.8c0.4,0.1,1.1,0,1,0.6c-0.1,0.6-0.2,1.3-1,1.3
C178.5,111.4,177.4,111.4,176.3,111.4z"/>
<path class="st1" d="M164.4,44.5c0,0.9,0,1.8,0,2.7c0,0.4-0.2,0.7-0.6,0.8c-1.2,0.5-1.3,0.3-1.2-0.9c0.2-3-0.3-6-0.5-8.9
c-0.2-3.9-1.1-7.6-2.3-11.3c-0.2-0.7-0.8-1.7-0.1-2.2c0.7-0.5,1.3,0.5,2,0.9c1.1,0.6,1.7,1.7,1.7,2.9
C163.3,33.9,164.3,39.2,164.4,44.5z"/>
<path class="st1" d="M164.9,165.7c0.8-3.1,0.1-6.2,0.1-9.4c0-0.5-0.2-1.1,0.5-1.2c0.6-0.1,0.9,0.4,1.1,0.8
c1.7,3.9,3.5,7.9,4.3,12.2c0,0.2,0.2,0.5,0,0.7c-0.3,0.3-0.5-0.1-0.7-0.2c-1.3-0.8-2.7-1.4-4.3-1.3
C164.6,167.4,165.1,166.3,164.9,165.7z"/>
<path class="st1" d="M172.9,193.5c-0.1,5.5-3.4,8.8-7.3,11.7c-0.5,0.4-1,0.3-1.6,0.1c-0.4-0.1-1-0.1-1.1-0.7
c-0.1-0.5,0.2-0.9,0.5-1.2c1.2-1,2.2-2.2,3.3-3.4c1.1-1.2,2-2.6,2.4-4.3c0.3-1.3,1.1-2.3,2.3-3c0.4-0.2,0.7-0.7,1.2-0.4
C173.1,192.7,172.9,193.2,172.9,193.5z"/>
<path class="st1" d="M161.2,221.6c0.3-1.3-0.2-2.8-0.1-4.4c0.2-3.5,0.5-6.9,0.9-10.4c0.1-1.1,0.5-1.1,1.4-0.5
c1.1,0.8,1.3,1.9,0.9,3c-0.6,1.8-0.7,3.6-0.8,5.4c-0.2,3.3,0,6.7-0.7,10c-0.1,0.5-0.1,1.1-0.9,1.1c-0.9,0-0.7-0.7-0.7-1.2
C161.1,223.7,161.2,222.8,161.2,221.6z"/>
<path class="st1" d="M146.3,230.3c-1.8-0.1-3.4-0.7-5.1-1.1c-0.8-0.2-0.5-0.8-0.3-1.3c1.2-2.4,1.8-5,2.7-7.6
c0.5-1.4,0.4-2.9,0.5-4.3c0-0.8,0.3-1.1,1.1-1.1c0.9,0.1,0.6,0.7,0.5,1.2c-0.3,3.7-0.9,7.3-2,10.8c-0.2,0.8,0.1,1.2,0.8,1.4
c0.7,0.2,1.4,0.3,2,0.5c0.5,0.2,0.7,0.6,0.6,1.1C147,230.3,146.7,230.3,146.3,230.3z"/>
<path class="st1" d="M143.6,212.1c-0.6,4.1-1.3,8.2-2.9,12.1c-0.3,0.8-0.6,1.6-0.8,2.4c-0.2,0.8-0.7,1.1-1.4,0.9
c-0.8-0.2-0.4-0.9-0.2-1.3c1.9-3.5,2.4-7.4,3.4-11.2c0.5-1.9,0.3-3.9,0.3-5.8c0-0.4-0.2-1,0.3-1.1c0.5-0.1,0.9,0.3,1,0.9
C143.5,210,143.5,211.1,143.6,212.1z"/>
<path class="st1" d="M77.6,218.3c0,2.4,0,4.9,0,7.3c0,0.5,0,1.1-0.7,1.1c-0.7,0-0.9-0.4-1-1c-0.4-2.7-0.1-5.4-0.1-8.1
c0-2.2,0.1-4.4,0.1-6.6c0-0.7,0.1-1.1,0.9-1.1c0.9,0,0.8,0.6,0.8,1.2c0,2.4,0,4.8,0,7.2C77.7,218.3,77.6,218.3,77.6,218.3z"/>
<path class="st1" d="M167.7,220.1c0.4-2.6,0.2-5.1,0.3-7.6c0.1-1.9,1.3-3.1,2.7-4.2c0.3-0.2,0.6-0.3,0.9-0.1
c0.3,0.2,0.2,0.6,0.1,0.9c-0.4,3.6-1.9,7-2.7,10.6c-0.1,0.3-0.3,0.6-0.6,0.8c-0.2,0.1-0.5,0.3-0.7,0.1
C167.7,220.5,167.7,220.2,167.7,220.1z"/>
<path class="st1" d="M126.2,207.4c-1.5,0.1-3.1-0.1-4.6-0.3c-0.6-0.1-1.1-0.3-1.3-1c-0.2-0.6,0-1,0.7-1c0.2,0,0.3,0,0.5,0
c2.4,0.1,4.7,0,7-0.6c1.2-0.3,2.4,0.7,2.5,1.9c0.1,0.5-0.3,0.6-0.7,0.7C129,207.5,127.6,207.4,126.2,207.4z"/>
<path class="st1" d="M127.5,224.5c0.1,2.5,0.2,4.9,0.3,7.2c0,0.5,0.3,1-0.5,1.1c-0.7,0-1.2-0.1-1.3-1c-0.1-1.5-0.2-3.1-0.2-4.6
c0-1.4,0.1-2.9-0.1-4.3c-0.1-0.6,0.1-0.9,0.7-1c0.7-0.1,1,0.2,1,0.9C127.5,223.4,127.5,224,127.5,224.5z"/>
<path class="st1" d="M145.9,208.9c0,0.4,0.1,0.8-0.5,0.9c-0.5,0.1-0.9,0-1.2-0.5c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-2.9-1-5.6-1.8-8.3
c-0.1-0.4-0.1-0.8,0.3-0.9c0.7-0.2,1.2,0.2,1.4,0.8c0.4,1.1,0.8,2.2,1.1,3.3C145.7,205.3,145.7,207.2,145.9,208.9z"/>
<path class="st1" d="M161.5,21.2c1.7-0.3,3.1,0.3,4.2,1.6c0.7,0.7,0.4,1.2-0.2,1.6c-0.6,0.4-1.3,0.6-1.9,0.4
c-1.5-0.4-2.8-1.4-3.9-2.5c-0.3-0.2-0.4-0.7-0.2-1c0.2-0.4,0.6-0.3,0.9-0.2C160.7,21.1,161.1,21.2,161.5,21.2z"/>
<path class="st1" d="M130.5,262.3c-1.4-0.2-2.2-1-2.3-2.5c-0.2-2.4,0-4.8-0.6-7.2c-0.1-0.6,0.3-0.7,0.8-0.7c0.7-0.1,1,0.2,1,0.9
c0,1.5,0,3.1,0.3,4.6C130,259,130.2,260.6,130.5,262.3z"/>
<path class="st1" d="M178.9,26.9c-0.2,1.3-0.3,2.9-0.6,4.4c-0.1,0.6-0.2,1.2-1,1.1c-0.8-0.1-0.6-0.8-0.6-1.3
c-0.1-1.7-0.1-3.4-0.2-5.1c-0.1-1.1,0.5-1.3,1.4-1.3c1.1,0,1.1,0.6,1,1.4C178.9,26.3,178.9,26.4,178.9,26.9z"/>
<path class="st1" d="M165.5,207.9c0.1-0.6,0.3-1,0.8-1.3c1.7-1,3.1-2.4,4.5-3.9c0.2-0.3,0.5-0.7,0.9-0.5c0.4,0.3,0.1,0.7,0,1
c-0.3,1-1,1.8-1.7,2.5c-1,0.9-2.1,1.7-3.1,2.7c-0.3,0.3-0.7,0.5-1.1,0.3C165.5,208.5,165.6,208.2,165.5,207.9z"/>
<path class="st1" d="M169.3,107.2c-1.3,0-2.7,0-4,0c-0.7,0-1-0.3-1-1c0-0.8,0.6-0.5,1-0.5c2.5,0.1,5,0.3,7.6,0.4
c0.6,0,1.2,0,1.2,0.7c0,0.9-0.7,0.6-1.2,0.6c-1.2,0-2.4,0-3.6,0C169.3,107.4,169.3,107.3,169.3,107.2z"/>
<path class="st1" d="M166.3,168.8c1.6-0.1,2.4,0.9,3.2,2c0.2,0.3,0.4,0.9-0.2,0.7c-1.3-0.2-2.5,0.2-3.7,0.4
c-0.9,0.1-1.1-0.1-1.1-1C164.5,169.2,164.9,168.7,166.3,168.8z"/>
<path class="st1" d="M164.2,245.2c0,1.2,0,2.5,0,3.7c0,0.4,0,0.9-0.7,0.8c-0.5-0.1-0.8-0.3-0.7-0.8c0.1-2.3,0.2-4.6,0.3-6.9
c0-0.6,0.1-1.2,0.9-1.1c0.7,0.1,0.4,0.7,0.4,1.1c0,1.1,0,2.2,0,3.2C164.4,245.2,164.3,245.2,164.2,245.2z"/>
<path class="st1" d="M153.5,104.9c0-0.4,0.2-0.6,0.5-0.5c2,0.8,4.1,0.4,6.2,0.5c0.6,0,1.1,0.1,1,0.9c-0.1,0.7-0.5,0.8-1,0.7
c-1.6-0.1-3.2-0.2-4.7-0.2C154.4,106.4,153.9,105.8,153.5,104.9z"/>
<path class="st1" d="M128.6,246.6c0.1,0.8-0.1,1.1-0.7,1.1c-0.5,0-0.9-0.2-1-0.7c-0.1-1.8-0.2-3.6-0.2-5.4c0-0.5,0.2-0.7,0.7-0.7
c0.6,0,0.9,0.2,0.9,0.8C128.5,243.4,128.4,245.1,128.6,246.6z"/>
<path class="st1" d="M143.9,171.3c0,0.6-0.3,0.7-0.6,0.5c-1.8-1-3.7-2.1-5.5-3.1c-0.3-0.2-0.6-0.6-0.3-1c0.2-0.4,0.6-0.1,0.9-0.1
c2.1,0.3,3.8,1.2,5,2.9C143.6,170.8,143.7,171.1,143.9,171.3z"/>
<path class="st1" d="M163.2,257.2c0,1.2,0,2.4,0,3.6c0,0.4,0,0.9-0.6,0.9c-0.6,0-0.8-0.3-0.7-0.9c0.1-2.3,0.2-4.5,0.3-6.8
c0-0.6,0.1-1,0.8-1c0.8,0,0.5,0.6,0.5,1C163.4,255.1,163.4,256.2,163.2,257.2C163.3,257.2,163.3,257.2,163.2,257.2z"/>
<path class="st1" d="M124.2,260.9c-0.2,0.6-0.5,0.5-0.9,0.3c-1.1-0.7-2.2-1.3-3.3-2c-0.7-0.5-1.5-0.9-2.3-1.3
c-0.3-0.1-0.6-0.3-0.8-0.5c-0.3-0.3-0.7-0.7-0.4-1.2c0.4-0.5,0.8-0.2,1.2,0.1c1.5,0.9,3,1.9,4.5,2.8c0.5,0.3,0.9,0.5,1.4,0.9
C123.9,260.2,124.2,260.5,124.2,260.9z"/>
<path class="st1" d="M77.8,198.5c-0.1,0.5,0.6,1.3-0.6,1.4c-1.1,0.1-1.2-0.5-1.2-1.3c0-0.9-0.1-1.7-0.3-2.6
c-0.2-0.7,0.1-0.9,0.7-0.9c0.6-0.1,0.9,0,1,0.7C77.5,196.7,77.6,197.6,77.8,198.5z"/>
<path class="st1" d="M49.8,116c-0.1,0.1-0.1,0.3-0.3,0.4c-0.9,1-1.7,2-2.4,3.1c-0.2,0.4-0.7,0.8-1.1,0.5c-0.5-0.3,0-0.7,0.2-1.1
c0.8-1.2,1.6-2.3,2.4-3.4c0.2-0.2,0.3-0.5,0.7-0.3C49.7,115.3,49.8,115.5,49.8,116z"/>
<path class="st1" d="M178.7,107.6c-0.6-0.1-1.3,0.3-1.3-0.7c0.1-0.8,0.4-1.4,1.3-1.3c0.9,0.1,1,0.8,1.1,1.5
C179.9,108,179.2,107.6,178.7,107.6z"/>
<path class="st1" d="M78.1,125.4c-0.5,0-0.9,0-0.8-0.6c0.2-1.1,1.3-0.8,2-1c0.4-0.1,0.9,0.2,0.8,0.7
C79.9,125.6,78.8,125.1,78.1,125.4z"/>
<path class="st1" d="M155,224.9c0.5,0,1,0,0.9,0.8c0,0.8-0.5,1.2-1.2,1.3c-0.7,0.1-0.7-0.4-0.6-0.8
C154.2,225.7,153.9,224.8,155,224.9z"/>
<path class="st1" d="M33.2,153.5c0.2-0.4-0.5-1.4,0.7-1.3c0.9,0.1,0.4,0.9,0.5,1.4c0,0.5,0.2,1.2-0.6,1.3
C32.9,154.8,33.1,154.1,33.2,153.5z"/>
<path class="st1" d="M155.8,203.8c0.1-0.4-0.1-1.1,0.7-1c0.6,0,0.9,0.5,0.9,1.1c0,0.5-0.2,1-0.8,1
C155.9,204.8,155.7,204.4,155.8,203.8z"/>
<path class="st1" d="M34.2,149c0.1-0.6,0-1.4,0.8-1.6c0.4-0.1,0.5,0.4,0.3,0.6c-0.3,0.6,0.3,1.6-0.6,1.9
C34.2,150,34.1,149.4,34.2,149z"/>
</svg>
</template>

